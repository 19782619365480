import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import caster1 from '../Images/home_images/profile.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import lock from '../Images/home_images/lock.webp';
import WatchEpisodePlayer from "../Components/WatchEpisodePlayer";
import Movieplayer from "../Components/Movieplayer";
import { API_BASE_URL } from '../config';

const WatchEpisode = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const seasonId = searchParams.get('season_id');
    const [EpisodeBySeasons,setEpisodeBySeasons] = useState([]);
    // Use camelCase for the JavaScript variables

    // console.log('Season ID:', seasonId);

    const [watchepisode, setEpisodewatch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkplansuscription, setcheckplansuscription] = useState('');

    useEffect(() => {

        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/episodes_details`, {
            user_id: UserId || '',
            season_id: seasonId,
            episode_id: id,
        }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                setLoading(false);
                setEpisodewatch(response.data.VIDEO_STREAMING_APP[0] || []);
                // console.log(response.data.VIDEO_STREAMING_APP[0])
            })
            .catch(err => {
                setLoading(false);
                console.error("Something went wrong:", err);
            });

        if (UserId) {
            axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
                user_id: UserId
            })
                .then(response => {
                    let checkPlan = response.data.VIDEO_STREAMING_APP;
                    setcheckplansuscription(checkPlan.msg);
                    // console.log(checkPlan);
                }).catch(err => {
                    console.log(err);
                })
        }
        axios.post(`${API_BASE_URL}api/v1/episodes`,{
            user_id: UserId,
            season_id: seasonId
        }).then(response=>{
            setEpisodeBySeasons(response.data.VIDEO_STREAMING_APP)
            // console.log(response.data.VIDEO_STREAMING_APP);
        }).catch(err=>{
            console.log("fetching all episodes error" + err);
        })

    }, [UserId, seasonId, id]);

    return (
        <>
            <section className="watch__movie">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-12">
                                {(checkplansuscription === 'My Subscription' || watchepisode.video_access === 'Free') && (
                                    <div className="video__screen">
                                        {/* <WatchEpisodePlayer sourceUrl={watchepisode.video_url} poster={watchepisode.watchepisode} /> */}
                                        <Movieplayer data={watchepisode} sourceUrl={watchepisode.video_url} poster={watchepisode.watchepisode} episodesSeasons={EpisodeBySeasons} />
                                    </div>
                                )}
                                {checkplansuscription === 'Renew subscription plan' && watchepisode.video_access === 'Paid' && (
                                    <div className="lock_premium">
                                        <div className="locker_img">
                                            <img src={lock} alt="Locked Content" loading="lazy" />
                                        </div>
                                        <div className="lock__title">
                                            <Link to="/subscription">Renew your Subscription</Link>
                                        </div>
                                    </div>
                                )}
                                {checkplansuscription === 'Please select subscription plan' && watchepisode.video_access === 'Paid' && (
                                    <div className="lock_premium">
                                        <div className="locker_img">
                                            <img src={lock} alt="Locked Content" loading="lazy" />
                                        </div>
                                        <div className="lock__title">
                                            <Link to="/subscription">Please buy a subscription</Link>
                                        </div>
                                    </div>
                                )}
                                {!UserId && (
                                    <div className="lock_premium">
                                        <div className="locker_img">
                                            <img src={lock} alt="Locked Content" loading="lazy" />
                                        </div>
                                        <div className="lock__title">
                                            <Link to="/login">Please Login</Link>
                                        </div>
                                    </div>
                                )
                                }
                            </div>
                        </div>
                        <div className="watch__movie_details-main" data-aos="fade-up" data-aos-duration="2000">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="watch_movie-title">
                                        <p>{watchepisode.episode_title}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="movie__watch-info">
                                        <ul className="d-flex list-unstyled align-items-center m-0">
                                            <li className="imbd_rating_watch">
                                                IMBd
                                                <span>{watchepisode.imdb_rating ? watchepisode.imdb_rating : '0'}</span>
                                            </li>
                                            <li className="duration">
                                                <i className="fa-regular fa-clock fa-fw"></i>
                                                {watchepisode.duration}
                                            </li>
                                            <li className="release_date">
                                                <i className="fa-solid fa-calendar-days"></i>
                                                release_date : {watchepisode.release_date}
                                            </li>
                                            <li className="release_date">
                                                <i className="fa-solid fa-globe"></i>
                                                access : {watchepisode.video_access}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* More Info */}
            <section className="more_info-watch-movie">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="about_watch-movie" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>About of the Episode</h2>
                                    <p dangerouslySetInnerHTML={{ __html: watchepisode.description }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* othe Elements of Movie  */}
            <section className="film__cast-section">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="film_cast-title" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>Cast of the {watchepisode.episode_title}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="film_casts" data-aos="fade-up" data-aos-duration="2000">
                            <div className="casters__main">
                                {
                                    watchepisode.actor_list && watchepisode.actor_list.length > 0 ? (
                                        watchepisode.actor_list.map((actor, index) => (
                                            <div className="caster__child" key={index}>
                                                <div className="film_caster-img">
                                                    <img src={actor.ad_image} alt={actor.ad_name} loading="lazy" />
                                                </div>
                                                <div className="film_caster-title">
                                                    <p className="m-0">{actor.ad_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="caster__child">
                                            <div className="film_caster-img">
                                                <img src={caster1} />
                                            </div>
                                            <div className="film_caster-title">
                                                <p className="m-0">No Actor Found</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* othe Elements of Movie */}
            <section className="other__element-of-movie">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="o__element-title" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>Other Elements of the {watchepisode['episode_title']} Movie</h2>
                                </div>
                            </div>
                        </div>
                        <div className="other__element-names">
                            <div className="elements_main">
                                {
                                    watchepisode.director_list && watchepisode.director_list.length > 0 ? (
                                        watchepisode.director_list.map((director, index) => (
                                            <div className="elements__child" key={index}>
                                                <div className="role">
                                                    <p>Director Name</p>
                                                </div>
                                                <div className="name_element">
                                                    <p>{director.ad_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="elements__child">
                                            <div className="role">
                                                <p>Director Name</p>
                                            </div>
                                            <div className="name_element">
                                                <p>No director found</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* You may Also like */}
            {/* <section className="like_section">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="like_title">
                                    <h2>You may also like</h2>
                                </div>
                            </div>
                        </div>
                        <div className="slider_may_like" data-aos="fade-up" data-aos-duration="2000">
                            <Swiper
                                slidesPerView={5}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    // when window width is >= 320px
                                    100: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    // when window width is >= 480px
                                    480: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    // when window width is >= 640px
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 40,
                                    },
                                }}
                                autoplay={{
                                    delay: 500,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                {
                                    suggestionMovies.map((suggestions, index) => (
                                        <SwiperSlide>
                                            <div className="you_may_card" onClick={() => navigate(`/movie-page/${'suggestions.movie_id'}`)}>
                                                <div className="may__movie-img">
                                                    <img src={'suggestions.movie_poster'} alt="" loading="lazy" />
                                                    <div className="may__movie-overlay">
                                                        <div className="may__movie__info">
                                                            <p className='duration'>Duration: {'suggestions.movie_duration'}</p>
                                                            <p className='rating d-block text-center'>{'suggestions.movie_access'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="like_title_recommend">
                                                    <p>{'suggestions.movie_title'}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section> */}

            {
                loading &&
                <div className="full-screen_loader">
                    <div className="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }
        </>
    )
}
export default WatchEpisode;