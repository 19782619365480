import styled from "styled-components";
import Poster from '../Images/poster.png';
import Poster1 from '../Images/poster1.png';
import Poster2 from '../Images/poster2.png';
import Poster3 from '../Images/poster3.png';
import Poster4 from '../Images/poster4.png';
import { useParams ,React, useState, useEffect } from "react";
import Card from '../Images/card.png';
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
const MovieByGenreComponent = styled.div`
 flex-wrap: wrap;

.movies__div{
width: 100%;
 display: flex;
 direction: rtl;
 align-items: stretch;
 justify-content: start;
 gap: 20px;
 flex-wrap: wrap;

 }
 .category__name h3{
     text-align: right;
    margin-bottom: 40px;
    font-size: 26px;
    margin-top: 40px;
}
    .view-more-btn{
    border: 1px solid red !important;
    padding: 10px 20px;
    height: auto;
    width: auto;
    margin-top: 35px;
    border-radius: 5px;
    color: white;
    letter-spacing: 2px;
    text-decoration: none;
    }
`
const MovieByGenre = ({ genre_id, genre_name }) => {
    const navigate = useNavigate();
    const [movies, setMovies] = useState([]);
    const [visibleMovies, setVisibleMovies] = useState([]);
    const [showViewMore, setShowViewMore] = useState(false);
    useEffect(() => {
        axios.post('https://filimio.inth.pk/api/v1/movies_by_genre', {
            'filter': 'new',
            'genre_id':genre_id
        })
            .then(response => {
                let allMovies = response.data.VIDEO_STREAMING_APP;
                setMovies(allMovies);
            })
            .catch(error => {
                console.error('There was an error making the request:', error);
            });
    }, []);
    return (
        <MovieByGenreComponent>
            <div className="category__name">
                <h3>{genre_name}</h3>
            </div>
            <div className="row justify-content-center">
                {
                    // movies.length > 0 ? (
                    //     movies.map((AllMoviesBygenre, index) => (
                    //         <HomeMovies
                    //             key={AllMoviesBygenre.movie_id}
                    //             movieCover={AllMoviesBygenre.movie_poster}
                    //             movieTitle={AllMoviesBygenre.movie_title}
                    //             onClick={() => {
                    //                 navigate(`/movie/${AllMoviesBygenre.movie_id}`);
                    //                 // navigate('/movie/1');
                    //             }} />
                    //     ))
                    // ) :
                    // 'Not found any movie'
                }
            </div>
        </MovieByGenreComponent>
    )
}
export default MovieByGenre;