import styled from "styled-components";
import { useParams, React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const RandomMoviesByGenreComponent = styled.div`
 flex-wrap: wrap;

.movies__div {
  width: 100%;
  display: flex;
  direction: rtl;
  align-items: stretch;
  justify-content: start;
  gap: 20px;
  flex-wrap: wrap;
}
.category__name h3 {
  text-align: right;
  margin-bottom: 40px;
  font-size: 26px;
  margin-top: 40px;
}
.view-more-btn {
  border: 1px solid red !important;
  padding: 10px 20px;
  height: auto;
  width: auto;
  margin-top: 35px;
  border-radius: 5px;
  color: white;
  letter-spacing: 2px;
  text-decoration: none;
}
`;

const getRandomMovies = (movies, count) => {
  const shuffled = movies.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const RandomMoviesByGenre = ({ genre_id, genre_name }) => {
  const navigate = useNavigate();
  const [movies, setMovies] = useState([]);
  const [randomMovies, setRandomMovies] = useState([]);

  useEffect(() => {
    axios.post('https://filimio.inth.pk/api/v1/movies_by_genre', {
      filter: 'new',
      genre_id: genre_id
    })
    .then(response => {
      let allMovies = response.data.VIDEO_STREAMING_APP;
      setMovies(allMovies);
      setRandomMovies(getRandomMovies(allMovies, 6));
    })
    .catch(error => {
      console.error('There was an error making the request:', error);
    });
  }, []);

  return (
    <RandomMoviesByGenreComponent>
      <div className="category__name">
        <h3>Suggestion Related Movies</h3>
      </div>
      <div className="row justify-content-center">
        {
          // randomMovies.length > 0 ? (
          //   randomMovies.map(movie => (
          //     <HomeMovies
          //       key={movie.movie_id}
          //       movieCover={movie.movie_poster}
          //       movieTitle={movie.movie_title}
          //       onClick={() => {
          //         navigate(`/movie/1`);
          //       }}
          //     />
          //   ))
          // ) : (
          //   'Not found Related Movies'
          // )
        }
      </div>
    </RandomMoviesByGenreComponent>
  );
};

export default RandomMoviesByGenre;
