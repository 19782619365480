import styled from "styled-components";
import logo from '../Images/newflix-logo.png';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Link } from "react-router-dom";


const SignUpCss = styled.div`
  background:#1F1F1F;
  padding:60px 0px;
  .login_form{
    margin-top:40px;
    box-shadow: 0 0 3px 0px silver;
    padding: 40px;
    border-radius: 22px;
  }
  .form__custom-filed{
    display: block;
    width: 100%;
    padding: 6px 20px;
    margin-top: 20px;
    border-radius: 7px;
    border: none;
  }
  .form__custom-filed:focus{
    outline:none;
  }
  .login__button{
     padding: 13px 30px;
     position: relative;
     background-color: white;
     border: none;
     z-index: 0;
     color:#fff;
     border-radius:8px;
     width:100%;
     margin-top:30px;
  }
  .login__button::before {
    position: absolute;
    content: "";
    top: 2.9px;
    left: 2.4px;
    height: 88%;
    width: 99%;
    z-index: -1;
    background: linear-gradient(184deg, rgba(253, 158, 9, 1) 0%, rgba(255, 226, 106, 1) 100%);
    border-radius: 6px;
  }
  .login_logo{
    margin-top:40px;  
  }
  .login_form p {
    margin-top:20px;
    color:#fff;
  }
  .login_form a {
    color:#E83F3E;
  }
`;

const SignUp = () => {
  return (
    <SignUpCss>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-6">
            <div className="row justify-content-center">
              <div className="col-md-6 login_logo">
                <img src={logo} className="w-100" alt="" />
              </div>
            </div>
            <div className="login_form">
              <form id="user_login_form">
                 <h2 style={{color:'#fff',marginBottom:"30px"}}>Sign Up</h2>
                <input type="text" className="form__custom-filed" name="name" placeholder="Enter Name..." />
                <input type="text" className="form__custom-filed" name="email" placeholder="Enter Email..." />
                <input type="password" className="form__custom-filed" name="password" placeholder="Enter Password..." />
                <button type="submit" className="login__button">Login</button>
              </form>
              <p>
              Already Sign Up? <Link to={"/login"}>  Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </SignUpCss>
  )
}
export default SignUp;