import { React } from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { API_BASE_URL } from '../config';

const AllSerialsComponent = ({ genreId, genreName, moviesByGenre }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    const [showsBygenre, setShowbygenre] = useState([]);
    // Movie By Genre
    useEffect(() => {
        axios.post(`${API_BASE_URL}api/v1/shows_by_genre`, {
            genre_id: genreId,
            filter: "new"
        }).then((response) => {
            let childMode = parseInt(localStorage.getItem('child_mode'));
            // console.log(response.data.VIDEO_STREAMING_APP)
            setLoading(false);
            if (childMode === 1 && subUserSelectedAge) {
                let filteredShows = response.data.VIDEO_STREAMING_APP.filter(show => {
                    if (show.content_rating === '') {
                        return false;
                    }
                    return parseInt(show.content_rating) <= subUserSelectedAge;
                });
                setShowbygenre(filteredShows);
            }
            else {
                const latestSixShows = response.data.VIDEO_STREAMING_APP.slice(0, 6);
                setShowbygenre(latestSixShows);
            }
        }).catch((error) => {
            setShowbygenre([]);
            console.error('Error fetching shows:', error);
        });
    }, [])
    return (
        <>
            <div className="all__movies-main">
                <div className="videos__container">
                    {
                        showsBygenre.length > 0 ? (
                            showsBygenre.map((shows, key) => (
                                <div className="videos_item" key={key}>
                                    <div className="movie" onClick={() => navigate(`/show-detail/${shows.show_id}`)}>
                                        <div className="movie_img serial_image">
                                            <div className="serial__over" style={{ backgroundImage: `url(${shows.show_poster})` }}></div>
                                            <div className="serial__over_2" style={{ backgroundImage: `url(${shows.show_poster})` }}></div>

                                            <img src={shows.show_poster} alt={shows.show_title} className='w-100' loading='lazy' />
                                            <div className="overlay__movie serial_overlay">
                                                <div className="movie__info">
                                                    {/* <p className='duration'>Duration: {movie.movie_duration}</p>
                                                <p className='rating duration d-block text-center'>{movie.movie_access}</p>
                                                <p className='categ_related'>{genreName}</p> */}
                                                    <p>Animation - musical</p>
                                                    <p className="movie_sun">iran - 1202</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="movie__title">
                                            <p>
                                                {shows.show_title.split(' ').length > 2
                                                    ? shows.show_title.split(' ').slice(0, 2).join(' ') + '...'
                                                    : shows.show_title}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p style={{ color: "white", fontFamily: 'i-med', textAlign: 'center' }}>No movies available in this Category.</p>
                        )
                    }
                </div>
            </div>

        </>
    );
};

export default AllSerialsComponent;
