import { Link } from "react-router-dom";
import styled from "styled-components";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import '../Components/css/style.css';
import profile_img from '../Images/movies/caster.webp';
import axios from "axios";
import profile from '../Images/home_images/profile.jpg';
import { useEffect, useState } from "react";
import { API_BASE_URL } from '../config';

const Dashboard = () => {
  
  const [userDashboard, setUserDashboard] = useState([]);
  let userId = localStorage.getItem('user_id');
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${API_BASE_URL}api/v1/dashboard`, {
          user_id: userId,
        });
        console.log(response.data);
        setLoading(false);
        setUserDashboard(response.data.VIDEO_STREAMING_APP || []);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    fetchData();


  }, [userId]);

  useEffect(() => {

    if (userDashboard.length > 0 && userDashboard[0].expires_on) {
      const calculateRemainingDays = () => {
        const expiresOn = new Date(userDashboard[0].expires_on);
        const currentDate = new Date(); 
        const timeDifference = expiresOn - currentDate; 
        if (timeDifference > 0) {
          const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
          setDaysRemaining(daysLeft);
        } else {
          setDaysRemaining('Na/Na');
          // setDaysRemaining(0);
        }
      };

      calculateRemainingDays(); 
    }

  }, [userDashboard]); 



  const user = userDashboard[0] || {};

  return (
    <>
      <section className="profile_dashboard">
        <div className="container-fluid">
          <div className="custom-container">
            <div className="profile__main-card">
              <div className="row">
                <div className="col-md-12 text-center">
                  {loading &&
                    <div class="spinner-border spinner_color mb-3" role="status"></div>
                  }
                </div>
                <div className="col-md-4 profile_column1">
                  <div className="profile__image">
                    <img src={localStorage.getItem('user_image') ? localStorage.getItem('user_image') : profile} alt="" />
                  </div>
                  <div className="profile__content">
                    <p className="username">Name: {user.name || 'Na/Na'}</p>
                    <p className="useremail">Email: {user.email || 'Na/Na'}</p>
                    <div className="action_btns">
                      <div className="edit_btn">
                        <Link to={'/profile/edit'}>Edit</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 mt-3 mt-md-0">
                  <div className="row align-items-start">
                    <div className="col-md-6 mt-3 mt-md-0">
                      <div className="profile_info-cards">
                        <h2>My Subscription</h2>
                        <div className="current_plan">
                          <p><i className="fa-regular fa-bookmark"></i> {user.current_plan || 'Na/Na'}</p>
                          <p><i className="fa-regular fa-calendar-days"></i> Remaining Days: {daysRemaining || 'Na/Na'}</p>
                        </div>
                        <Link to="/subscription" className="select_plan-btn">Upgrade Plan</Link>
                      </div>
                    </div>
                    <div className="col-md-6 mt-3 mt-md-0">
                      <div className="profile_info-cards">
                        <h2>Last Invoice</h2>
                        <p className="last_date">Date: {user.last_invoice_date || 'Na/Na'}</p>
                        <p className="last_amount">Amount: {user.last_invoice_amount || 'Na/Na'}</p>
                        <p className="last_plan">Plan Name: {user.last_invoice_plan || 'Na/Na'}</p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="subprofile">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="sub__Acount-heading">
                          <h2>Sub Accounts</h2>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sub_profile_card">
                          <div className="row align-items-center">
                            <div className="col-md-4">
                              <div className="sub__profile-img">
                                <img src={user.user_image ? user.user_image : profile} alt="" />
                              </div>
                            </div>
                            <div className="col-md-8">
                              <div className="sub_account_info">
                                <p className="m-0">Name: Muhammad Ahsan</p>
                                <p className="m-0">Age: 16</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
            </div>

            {/* <div className="subprofile">
              <div className="profile__main-card">
                <div className="row">
                  <div className="col-md-4 profile_column1">
                    <div className="profile__image">
                      <img src={user.user_image ? user.user_image : profile} alt="" />
                    </div>
                    <div className="profile__content">
                      <p className="username">Name: {user.name || 'Na/Na'}</p>
                      <p className="useremail">Email: {user.email || 'Na/Na'}</p>
                      <div className="action_btns">
                        <div className="edit_btn">
                          <Link to={`/profile/edit/${userId}`}>Edit</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row align-items-start">
                      <div className="col-md-6">
                        <div className="profile_info-cards">
                          <h2>My Subscription</h2>
                          <div className="current_plan">
                            <p><i className="fa-regular fa-bookmark"></i> {user.current_plan || 'Na/Na'}</p>
                          </div>
                          <Link to="/subscription" className="select_plan-btn">Upgrade Plan</Link>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="profile_info-cards">
                          <h2>Last Invoice</h2>
                          <p className="last_date">Date: {user.last_invoice_date || 'Na/Na'}</p>
                          <p className="last_amount">Amount: {user.last_invoice_amount || 'Na/Na'}</p>
                          <p className="last_plan">Plan Name: {user.last_invoice_plan || 'Na/Na'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
