import styled from "styled-components";
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import { useNavigate } from "react-router-dom";

const ResponsiveNavComponent = styled.div`
  display: flex;
  background: #1F1F1F;
  padding: 10px;
  color: #E9E9E9;
  direction: rtl;
  align-items: center;
//   overflow-x: auto;
//   overflow: hidden; /* Hide overflow on the main container */
  position: relative; /* Position relative for absolute positioning of hover elements */
.hovered__content{

width: auto;
    gap: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 20px;
}
  .content {
    overflow-x: auto;
    min-width: auto;
    max-width: 70%;
    gap: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
    .content::-webkit-scrollbar {
    height: 8px; /* Width of the scrollbar */
  }

  .content::-webkit-scrollbar-track {
    background: #1F1F1F; /* Track color */
  }

  .content::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar color */
    border-radius: 10px; /* Rounded corners for the scrollbar */
  }

  .content::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
  }

  .film__divs, .serial__divs {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-right: 15px; /* Add margin to separate from other items */
  }

  .film__hovers, .serial__hovers {
    display: none;
    position: absolute;
    top: 100%;
    background: #1F1F1F;
    color: white;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    width: 250px;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    z-index: 100;
  }

  .film__divs:hover .film__hovers,
  .serial__divs:hover .serial__hovers {
    display: block;
  }

  .tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .tab {
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
  }

  .tab.active {
    background-color: #e6e6e6;
    color: black;
  }

  .categories {
    display: grid;
    text-align: right;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .categories > div {
    padding: 5px 0;
  }

  @media (max-width: 800px) {
    .film__divs, .serial__divs {
      display: none; /* Hide navigation items on smaller screens */
    }
  }
    @media (max-width: 500px) {
    // .hovered__content{
    //   width: 40%;
    // }
    //   .content{
    //   width: 60%;
    //   }
  }
`;


const ResponsiveNav = ({ isTallScreen }) => {
    const navigate = useNavigate();
    return (
      <ResponsiveNavComponent>
        <div className="hovered__content">
          <div className="film__divs" style={{ display: isTallScreen ? 'none' : 'inline-flex' }}>
            <KeyboardArrowDownIcon style={{ marginTop: '10px' }} /> فیلم
            <div className="film__hovers">
              <div className="tabs">
                <div className="tab">خارجی</div>
                <div className="tab">ایرانی</div>
                <div className="tab active">همه</div>
              </div>
              <div className="categories">
                <div>اکشن</div>
                <div>عاشقانه</div>
                <div>علمی تخیلی</div>
                <div>خانوادگی</div>
                <div>فانتزی</div>
                <div>اجتماعی</div>
                <div>فیلم کوتاه</div>
                <div>انیمه</div>
                <div>کلاسیک</div>
                <div>انیمیشن</div>
                <div>کمدی</div>
                <div>تاریخی</div>
                <div>کودک</div>
                <div>ترسناک</div>
                <div>لایو اکشن</div>
                <div>زندگی نامه</div>
                <div>ماجراجویی</div>
                <div>جنایی</div>
                <div>مستند</div>
              </div>
            </div>
          </div>
          <div className="serial__divs" style={{ display: isTallScreen ? 'none' : 'inline-flex' }}>
            <KeyboardArrowDownIcon style={{ marginTop: '10px' }} /> سریال
            <div className="serial__hovers">
              <div className="tabs">
                <div className="tab">خارجی</div>
                <div className="tab">ایرانی</div>
                <div className="tab active">همه</div>
              </div>
              <div className="categories">
                <div>اکشن</div>
                <div>عاشقانه</div>
                <div>علمی تخیلی</div>
                <div>خانوادگی</div>
                <div>فانتزی</div>
                <div>اجتماعی</div>
                <div>فیلم کوتاه</div>
                <div>انیمه</div>
                <div>کلاسیک</div>
                <div>انیمیشن</div>
                <div>کمدی</div>
                <div>تاریخی</div>
                <div>کودک</div>
                <div>ترسناک</div>
                <div>لایو اکشن</div>
                <div>زندگی نامه</div>
                <div>ماجراجویی</div>
                <div>جنایی</div>
                <div>مستند</div>
              </div>
            </div>
          </div>
          </div>
        <div className="content">

          <div style={{ display: isTallScreen ? 'none' : 'inline-flex' }}>کودکان</div>
          <div style={{ display: isTallScreen ? 'none' : 'inline-flex', cursor: 'pointer' }} onClick={() => navigate('/collection-list')}>
            مجموعه <VideoLibraryOutlinedIcon />
          </div>
          <div style={{ display: isTallScreen ? 'none' : 'inline-flex', cursor: 'pointer' }} onClick={() => navigate('/watch-list')}>
            لیست من <BookmarkAddedIcon />
          </div>
        </div>
      </ResponsiveNavComponent>
    );
  }
  
  export default ResponsiveNav;
  