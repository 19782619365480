import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../Components/css/style.css';
import { API_BASE_URL } from '../config';
import LiveTvComponent from '../Components/LiveTvComponent';

const ViewAllLiveTv = () => {
    const { id } = useParams();
    const [livetvvideos, setlivechannel] = useState([]);
    const [loading, setLoading] = useState(false);
    const UserId = localStorage.getItem('user_id');
    const navigate = useNavigate();
    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/livetv_by_category`, {
            filter: 'rand',
            category_id: id
        })
            .then(response => {
                setLoading(false);
                setlivechannel(response.data.VIDEO_STREAMING_APP)
            })
            .catch(err => {
                console.log("while fetching channels erros showing " + err);
            })
    }, []);
    const livetvDetail = (live_channel_id) => {
        navigate(`/live-tv-detail/${live_channel_id}`)
    }
    return (
        <>
            <section className="live__tv-section">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="live_tv__category_name">
                                    {/* <h2>{categoryName}</h2> */}
                                </div>
                            </div>
                        </div>
                        <div className="live__channels">
                            <div className="row">
                                {
                                    livetvvideos.length > 0 ? (
                                        livetvvideos.map((liveChannels, index) => (
                                            <div className="col-md-2">
                                                <div className="tv__card" key={index} onClick={() => livetvDetail(liveChannels.tv_id)}>
                                                    <div className="tv__logo">
                                                        <img src={liveChannels.tv_logo} alt={liveChannels.tv_title} loading='lazy' />
                                                        <div className="tv__layer">
                                                            <div className="more__info-tv">
                                                                <p className='access'>{liveChannels.tv_access}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tv__title">
                                                    <p>{liveChannels.tv_title}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="tv__title">
                                            <p>Tv channels Not found</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div className="full-screen_loader">
                    <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }
        </>
    );
};

export default ViewAllLiveTv;
