import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import imbd from '../Images/imdb.png';
import caster1 from '../Images/home_images/profile.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import lock from '../Images/home_images/lock.webp';
import { Pagination } from 'swiper/modules';
import Movieplayer from "../Components/Movieplayer";
import { API_BASE_URL } from '../config';

const WatchMovie = () => {
    let [userplan, setUserplan] = useState('');
    const { id } = useParams();;
    let UserId = localStorage.getItem('user_id');
    const [suggestionMovies, setMovieSuggestion] = useState([]);
    const [movie_detail, setMovieDetail] = useState([]);
    const [checkplansuscription, setcheckplansuscription] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));

    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/movies_details`, {
            'movie_id': id
        })
            .then(response => {
                setLoading(false);
                setMovieDetail(response.data.VIDEO_STREAMING_APP)
                // console.log(response.data.VIDEO_STREAMING_APP);
            })
            .catch(error => {
                console.log('There was an error making the request:', error);
            });
      
        axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
            user_id: UserId
        })
        .then(response => {
            let checkPlan = response.data.VIDEO_STREAMING_APP;
            setcheckplansuscription(checkPlan.msg);
            setUserplan(response.data.VIDEO_STREAMING_APP);
        }).catch(err => {
            console.log(err);
        })
        // Movies
        axios.post(`${API_BASE_URL}api/v1/movies`, {
            filter: subUserSelectedAge ? 'new' :'rand',
        })
            .then(response => {

                if(subUserSelectedAge){
                    let filteredMovies = response.data.VIDEO_STREAMING_APP.movies.filter(movie => {
                        if (movie.content_rating === '') {
                            return false;
                        }
                        return parseInt(movie.content_rating) <= subUserSelectedAge;
                    });
                    let random_movies = filteredMovies.slice(0, 8);
                    console.log(random_movies)
                    setMovieSuggestion(filteredMovies);
                }else{
                     let random_movies = response.data.VIDEO_STREAMING_APP.movies.slice(0, 8);
                     setMovieSuggestion(random_movies);
                }   
            })
            .catch(err => {
                console.log('There was an error making the request:', err);
            });

    }, [UserId]);

    console.log(checkplansuscription);
    return (
        <>
            <section className="watch__movie">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-12">

                                {(checkplansuscription === 'My Subscription' || movie_detail.movie_access === 'Free') && (
                                    <div className="video__screen">
                                        <Movieplayer data={movie_detail} sourceUrl={movie_detail.video_url}  poster={movie_detail.movie_image}/>
                                    </div>
                                )}

                                {checkplansuscription === 'Renew subscription plan' && movie_detail.movie_access === 'Paid' && (
                                    <div className="lock_premium">
                                        <div className="locker_img">
                                            <img src={lock} alt="Locked Content" loading="lazy" />
                                        </div>
                                        <div className="lock__title">
                                            <Link to="/subscription">Renew your Subscription</Link>
                                        </div>
                                    </div>
                                )}

                                {checkplansuscription === 'Please select subscription plan' && movie_detail.movie_access === 'Paid' && (
                                    <div className="lock_premium">
                                        <div className="locker_img">
                                            <img src={lock} alt="Locked Content" loading="lazy" />
                                        </div>
                                        <div className="lock__title">
                                            <Link to="/subscription">Please buy a subscription</Link>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                        <div className="watch__movie_details-main" data-aos="fade-up" data-aos-duration="2000">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="watch_movie-title">
                                        <p>{movie_detail.movie_title}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="movie__watch-info">
                                        <ul className="d-flex list-unstyled align-items-center m-0">
                                            <li className="imbd_rating_watch">
                                                IMBd
                                                <span>{movie_detail.imdb_rating}</span>
                                            </li>
                                            <li className="duration">
                                                <i className="fa-regular fa-clock fa-fw"></i>
                                                {movie_detail.movie_duration}
                                            </li>
                                            <li className="release_date">
                                                <i class="fa-solid fa-calendar-days"></i>
                                                release_date : {movie_detail.release_date}
                                            </li>
                                            <li className="release_date">
                                                <i class="fa-solid fa-calendar-days"></i>
                                                access : {movie_detail.movie_access}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* More Info */}
            <section className="more_info-watch-movie">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="about_watch-movie" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>About of the movie</h2>
                                    <p dangerouslySetInnerHTML={{ __html: movie_detail.description }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* othe Elements of Movie  */}
            <section className="film__cast-section">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="film_cast-title" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>Cast of the {movie_detail.movie_title}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="film_casts" data-aos="fade-up" data-aos-duration="2000">
                            <div className="casters__main">
                                {
                                    movie_detail.actor_list && movie_detail.actor_list.length > 0 ? (
                                        movie_detail.actor_list.map((actor, index) => (
                                            <div className="caster__child" key={index}>
                                                <div className="film_caster-img">
                                                    <img src={actor.ad_image} alt={actor.ad_name} loading="lazy" />
                                                </div>
                                                <div className="film_caster-title">
                                                    <p className="m-0">{actor.ad_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="caster__child">
                                            <div className="film_caster-img">
                                                <img src={caster1} />
                                            </div>
                                            <div className="film_caster-title">
                                                <p className="m-0">No Actor Found</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* othe Elements of Movie */}
            <section className="other__element-of-movie">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="o__element-title" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>Other Elements of the {movie_detail.movie_title} Movie</h2>
                                </div>
                            </div>
                        </div>
                        <div className="other__element-names">
                            <div className="elements_main">
                                {
                                    movie_detail.director_list && movie_detail.director_list.length > 0 ? (
                                        movie_detail.director_list.map((director, index) => (
                                            <div className="elements__child" key={index}>
                                                <div className="role">
                                                    <p>Director Name</p>
                                                </div>
                                                <div className="name_element">
                                                    <p>{director.ad_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="elements__child">
                                            <div className="role">
                                                <p>Director Name</p>
                                            </div>
                                            <div className="name_element">
                                                <p>No director found</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* You may Also like */}
            <section className="like_section">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="like_title">
                                    <h2>You may also like</h2>
                                </div>
                            </div>
                        </div>
                        <div className="slider_may_like" data-aos="fade-up" data-aos-duration="2000">
                            <Swiper
                                slidesPerView={5}
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                breakpoints={{
                                    // when window width is >= 320px
                                    100: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    // when window width is >= 480px
                                    480: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    // when window width is >= 640px
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 40,
                                    },
                                }}
                                autoplay={{
                                    delay: 500,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                {

                                    suggestionMovies.map((suggestions, index) => (
                                        <SwiperSlide>
                                            <div className="you_may_card" onClick={() => navigate(`/movie-page/${suggestions.movie_id}`)}>
                                                <div className="may__movie-img">
                                                    <img src={suggestions.movie_poster} alt="" loading="lazy" />
                                                    <div className="may__movie-overlay">
                                                        <div className="may__movie__info">
                                                            <p className='duration'>Duration: {suggestions.movie_duration}</p>
                                                            <p className='rating d-block text-center'>{suggestions.movie_access}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="like_title_recommend">
                                                    <p>{suggestions.movie_title}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))

                                }

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div className="full-screen_loader">
                    <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }
        </>
    );
};

export default WatchMovie;
