import React, { useEffect, useState, useRef } from 'react';
import '../Components/css/style.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import logoDark from '../Images/home_images/new-flex-dark.jpg';
import just_icon from '../Images/just_icon.png';

const ProcessLogin = () => {

    const [code, setCode] = useState(new Array(6).fill(''));
    const [countdown, setCountdown] = useState(60);
    const [isCountdownActive, setIsCountdownActive] = useState(false);
    const [alert_otp, setOtpalert] = useState('');
    const [loading, setLoading] = useState(false);
    const phoneNumber = localStorage.getItem("phoneNumberVerification");
    const [isInputInvalid, setIsInputInvalid] = useState(false);

    const inputRefs = useRef([]);
    let navigate = useNavigate();

    useEffect(() => {
        document.body.style.background = '#fff';
        return () => {
            document.body.style.boxShadow = '';
            document.body.style.margin = '';
            document.body.style.maxWidth = '';
            document.body.style.minHeight = '';
            document.body.style.background = '';
        };
    }, []);

    useEffect(() => {
        const storedEndTime = localStorage.getItem('countdownEndTime');
        if (storedEndTime) {
            const endTime = parseInt(storedEndTime, 10);
            const timeLeft = Math.ceil((endTime - Date.now()) / 1000);
            if (timeLeft > 0) {
                setCountdown(timeLeft);
                setIsCountdownActive(true);
            } else {
                localStorage.removeItem('countdownEndTime');
                startCountdown();
            }
        } else {
            startCountdown();
        }
    }, []);

    useEffect(() => {
        const storedEndTime = localStorage.getItem('countdownEndTime');
        if (storedEndTime) {
            const endTime = parseInt(storedEndTime, 10);
            const timeLeft = Math.ceil((endTime - Date.now()) / 1000);
            if (timeLeft > 0) {
                setCountdown(timeLeft);
                setIsCountdownActive(true);
            } else {
                localStorage.removeItem('countdownEndTime');
            }
        }
    }, []);

    useEffect(() => {
        let timer;
        if (isCountdownActive && countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setIsCountdownActive(false);
                        localStorage.removeItem('countdownEndTime');
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isCountdownActive, countdown]);

    const startCountdown = () => {
        const endTime = Date.now() + countdown * 1000;
        localStorage.setItem('countdownEndTime', endTime.toString());
        setCountdown(60);
        setIsCountdownActive(true);
    };



    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // Move to the next input automatically
            if (index < 5 && inputRefs.current[index + 1]) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    // Ref to store the timeout ID
    const debounceRef = useRef(null);

    useEffect(() => {
        // Clear previous timeout if it exists
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        // Set a new timeout for debouncing
        debounceRef.current = setTimeout(() => {
            if (code.every(digit => digit !== '')) {
                handleVerifyClick(); // Call the verification function
            }
        }, 100); // Adjust delay as needed (e.g., 300ms)
    }, [code]); // This effect runs when the `code` state changes

    // useEffect to automatically trigger verification when all digits are entered
    useEffect(() => {
        if (code.every(digit => digit !== '')) {
            handleVerifyClick();  // Call the verification function when all inputs are filled
        }
    }, [code]); // This effect runs when the `code` state changes

    const handleVerifyClick = () => {
        setLoading(true);
        setOtpalert(''); // Clear previous alerts
        setIsInputInvalid(false); // Reset input validation state


        // Ensure the array has all 6 digits
        if (code.some(digit => digit === '')) {
            setOtpalert('Please enter all numbers');
            setLoading(false);
            return;
        }

        const codeConverted = parseInt(code.join(''), 10);
        axios.post(`${API_BASE_URL}api/v1/verify_code`, {
            phone: `+${phoneNumber}`,
            code: codeConverted
        })
            .then(response => {
                setLoading(false);
                const result = response.data.VIDEO_STREAMING_APP[0];
                setOtpalert(result.msg);
                console.log(response);

                // Check if the response is successful
                if (result.success == 1) {
                    const user = response.data.VIDEO_STREAMING_APP[1];
                    localStorage.setItem('user_id', user['user_id']);
                    localStorage.setItem('user_image', user['user_image']);
                    localStorage.setItem('session_name', user['user_session_name']);
                    localStorage.setItem('user_name', 'Admin');
                    // localStorage.setItem('sub_account_id', user['user_id']);
                    localStorage.setItem('age', 1000);
                    const planName = localStorage.getItem('planName');
                    if (!planName) {
                        window.location.href = '/profile/dashboard'
                    } else {
                        window.location.href = '/payment-process'
                    }
                } else {
                    // Handle invalid code case here, if necessary
                     setIsInputInvalid(true); // Set input as invalid
                    setOtpalert('Invalid code. Please try again.');
                }
            })
            .catch(error => {
                console.error(error);
                setOtpalert('An error occurred. Please try again.');
                setIsInputInvalid(true); // Set input as invalid
                setLoading(false);
            });
    };

    // const handleVerifyClick = () => {
    //     setLoading(true);
    //     if (code.some(digit => digit === '')) {
    //         setOtpalert('Please enter all numbers');
    //         setLoading(false);
    //         return;
    //     }

    //     const codeConverted = parseInt(code.join(''), 10);
    //     axios.post(`${API_BASE_URL}api/v1/verify_code`, {
    //         phone: `+${phoneNumber}`,
    //         code: codeConverted
    //     })
    //         .then(response => {
    //             setLoading(false);
    //             const result = response.data.VIDEO_STREAMING_APP[0];
    //             setOtpalert(result.msg);
    //             console.log(response);
    //             console.log(typeof response.success, response.success);
    //             if (result.success == 1) {
    //                 const user = response.data.VIDEO_STREAMING_APP[1];
    //                 localStorage.setItem('user_id', user['user_id']);
    //                 localStorage.setItem('user_image', user['user_image']);
    //                 localStorage.setItem('session_name', user['user_session_name']);
    //                 localStorage.setItem('user_name', 'Admin');
    //                 // localStorage.setItem('sub_account_id', user['user_id']);
    //                 localStorage.setItem('age', 1000);
    //                 const planName = localStorage.getItem('planName');
    //                 if (!planName) {
    //                     window.location.href = '/profile/dashboard'
    //                 } else {
    //                     window.location.href = '/payment-process'
    //                 }
    //             }
    //         })
    //         .catch(error => {
    //             console.error(error);
    //             setLoading(false);
    //         });
    // };

    const handleSendCodeAgain = () => {
        setCountdown(60);
        setLoading(true);
        // startCountdown();
        axios.post(`${API_BASE_URL}api/v1/signup`, {
            phone: `+${phoneNumber}`
        })
            .then(response => {
                setLoading(false);
                if (response.data.VIDEO_STREAMING_APP[0].success == 0) {
                    setOtpalert(response.data.VIDEO_STREAMING_APP[0].msg)
                } else {
                    setOtpalert(response.data.VIDEO_STREAMING_APP[0].msg)
                }
            })
            .catch(error => {
                setLoading(false);
                console.error("There was an error submitting the phone number!", error);
            });
    };


    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);


    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            if (code[index] !== '') {
                const newCode = [...code];
                newCode[index] = '';  // Clear the current input
                setCode(newCode);
            } else if (index > 0) {
                inputRefs.current[index - 1].focus();  // Move to the previous input
            }
        }
    };


    const handleFocus = (e) => {
        e.target.select();
    };

    return (
        <section className="verify_otp-section">
            <div className="container-fluid">
                <div className="custom-container">
                    <div className="row justify-content-center">
                        <div className="logo_web_process_login">
                            <div className="logo_verification">
                                <img src={just_icon} alt="" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="verify_content_heading">
                                <p>Enter the code sent for ({localStorage.getItem('phoneNumberVerification')})</p>
                            </div>
                            <div className="back__button">
                                <button onClick={() => navigate(-1)}>Back <i class="fa-solid fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        {/* <div className="col-md-6"> */}
                        <div className="main_verification" data-aos="fade-up" data-aos-duration="2000">
                            <p className={`alert-otp ${alert_otp ? 'alert-otp-active' : ''}`}>
                                {alert_otp || ''}
                            </p>
                            <div id="form">
                                {code.map((digit, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        size="1"
                                        value={digit}
                                        onChange={(e) => handleInputChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        onFocus={handleFocus}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        className={`${isInputInvalid ? 'input-invalid' : ''}`}

                                    />
                                ))}
                                {/* <button className="text-white" onClick={handleVerifyClick} disabled={isCountdownActive}> Verify </button> */}
                                {/* <button className="text-white" onClick={handleVerifyClick}> Verify </button> */}
                            </div>

                            <div className='actions_btn_verify'>
                                {isCountdownActive ? (
                                    <p className='alert_verify'>Please wait {countdown}s before requesting a new code.</p>
                                ) : (
                                    <div>
                                        <a href="#" className='mt-4 d-inline-block' onClick={handleSendCodeAgain}>Didn't receive the code? Send code again</a><br />
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    {
                                        loading && <div class="spinner-border spinner_color mt-3" role="status"></div>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProcessLogin;
