// Footer.js
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import googlePlay from '../Images/googlePlay.png';
import sibApp from '../Images/sibApp.png';
import myket from '../Images/myket.png';
import bazar from '../Images/bazar.png';
import youtube from '../Images/youtube.png';
import instagram from '../Images/instagram.png';
import telegram from '../Images/telegram.png';
import twitter from '../Images/twitter.png';
import logo from '../Images/logo.png';
const Footer = () => {
    return (
        <>
            <section className="footer__bottom">
                <div className="social__link">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-6 col-md-6 text-center text-sm-left">
                                <ul className='m-0 list-unstyled d-inline-block'>
                                    <li className='open_social_links'>Social Network <i class="fa-solid fa-angle-down"></i>
                                        <div className="dropdown_social_links">
                                            <ul className='m-0 list-unstyled'>
                                                <li><Link to="#">Telegram <i class="fa-brands fa-telegram"></i></Link></li>
                                                <li><Link to="#">Twitter <i class="fa-brands fa-twitter"></i></Link></li>
                                                <li><Link to="#">Instagram <i class="fa-brands fa-instagram"></i></Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-12 col-sm-6 col-md-6">
                                <div className="more__footer_link">
                                    <ul className='m-0 list-unstyled'>
                                        <li>
                                            <Link to="/contact-us">Contact & Support</Link>
                                            <Link to="/app">Applications</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer;
