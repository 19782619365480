import styled from "styled-components";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Imdb from '../Images/imdb.png';
import lock2 from '../Images/lock.webp'
import caster1 from '../Images/home_images/profile.jpg';
import movieimg from '../Images/movies/movie-2.webp';
import rating from '../Images/rating.jpg';
import '../Components/css/style.css';
import { Link } from "react-router-dom";
import Allmovies from '../Components/Allmovies';
import MovieTrailerPlayer2 from "../Components/MovieTrailerPlayer2";
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation } from 'swiper/modules';
import ShowTrailerplayer from '../Components/ShowTrailerplayer'
import { API_BASE_URL } from '../config';
import default_user from '../Images/default-user.webp';


const Showsdetail = () => {
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(null);
    const genre_id = location.state?.params_id;
    const { id } = useParams();
    const [showDetail, setShowDetail] = useState({});
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [seasons, setseasons] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [allcomments, setallcomments] = useState([]);
    const [faq, setfaq] = useState([]);
    let UserId = localStorage.getItem('user_id');


    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/show_details`, {
            'show_id': id
        })
            .then(response => {
                setLoading(false);
                let setShowDetails = response.data.VIDEO_STREAMING_APP;
                setShowDetail(setShowDetails);
                console.log(setShowDetails)
            })
            .catch(error => {
                setLoading(false);
                console.error('There was an error making the request:', error);
            });
        // Seasons Api
        axios.post(`${API_BASE_URL}api/v1/seasons`, {
            'show_id': id
        })
            .then(response => {
                let setSeasons = response.data.VIDEO_STREAMING_APP;
                setseasons(setSeasons);
                // console.log(setSeasons)
            })
            .catch(error => {
                console.error('There was an error making the request:', error);
            });


        axios.post(`${API_BASE_URL}api/v1/shows`, {
            filter: 'new'
            // filter: 'rand'
        })
            .then(response => {
                let random_shows = response.data.VIDEO_STREAMING_APP.shows.slice(0, 8);
                setSuggestionShows(random_shows); // Use setSuggestionMovies to update the state
                console.log(random_shows)
            })
            .catch(err => {
                console.log('There was an error making the request:', err);
            });
        //  faqs Api

        axios.post(`${API_BASE_URL}api/v1/faqs`, {
            model: 'Series',
            model_id: id,
        })
            .then(response => {
                //  console.log(response.data.VIDEO_STREAMING_APP.faq);
                setfaq(response.data.VIDEO_STREAMING_APP.faq);
            })
            .catch(err => {
                console.log("Faqs fetching error " + err);
            });
        listComments();


    }, [id]);

    const [suggestionShows, setSuggestionShows] = useState([]);


    useEffect(() => {
        // JavaScript to add Font Awesome icons
        const prevButtons = document.querySelectorAll('.swiper-button-prev');
        const nextButtons = document.querySelectorAll('.swiper-button-next');
        prevButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-left"></i>'; // Font Awesome left chevron
        });
        nextButtons.forEach(button => {
            button.innerHTML = '<i class="fas fa-chevron-right"></i>'; // Font Awesome right chevron
        });
    }, []);


    const [openModal, setOpenModal] = useState(false);
    const [CommentMessage, setCommentMessage] = useState('');
    const openLoginModal = (e) => {
        e.preventDefault();

        if (UserId) {
            saveCommentToAPI();
        } else {
            setOpenModal(true);
        }
    };
    const saveCommentToAPI = () => {
        const comment = document.getElementById('comment_body').value;
        axios.post(`${API_BASE_URL}api/v1/add_comment`,
            {
                content: comment,
                commentable_id: id,
                commentable_type: 'Series',
                user_id: UserId,
            }
        ).then(response => {
            setCommentMessage(response.data.VIDEO_STREAMING_APP[0].msg);
            console.log('Comment saved successfully:', response.data);
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
        })
            .catch(error => {
                console.error('Error saving comment:', error);
            });
    };

    const closeLoginModal = () => {
        setOpenModal(false)
    }

    const listComments = () => {
        axios.post(`${API_BASE_URL}api/v1/list_comment`, {
            commentable_id: id,
            commentable_type: 'Series',
            user_id: UserId,
        })
            .then(response => {
                let all_comments = response.data.VIDEO_STREAMING_APP;
                setallcomments(all_comments);
            })
            .catch(err => {
                console.log('There was an error making the request:', err);
            });
    };

    // comments work
    // Like Comments
    const likeComment = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/like_comment`,
            {
                comment_id: comment_id,
                user_id: UserId || '',
            }
        ).then(response => {
            console.log(response.data.VIDEO_STREAMING_APP);
        })
            .catch(error => {
                console.error('Error saving comment:', error);
            });
    }
    // Dislike Comments
    const dislikeComment = (comment_id) => {
        axios.post(`${API_BASE_URL}api/v1/unlike_comment`,
            {
                comment_id: comment_id,
                user_id: UserId || '',
            }
        ).then(response => {
            console.log(response.data.VIDEO_STREAMING_APP);
        })
            .catch(error => {
                console.error('Error saving comment:', error);
            });
    }


    // Function to format the date
    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Options to format the date as "MonthName - Day - Year"
        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        return date.toLocaleDateString('en-US', options).replace(',', ' -');
    };
    return (
        <>
            <section className="single__movie-detail">
                <div className="js__movie_poster">
                    <div className="single__movie-banner" data-aos="fade-up" data-aos-duration="2000">
                        <div className="video_trailer__wrapper">
                            <ShowTrailerplayer data={showDetail} sourceUrl={showDetail.show_trailer_url} poster={showDetail.show_poster} />
                            {/* <video playsInline preload="none" loop muted className="trailervideo" width="100%" height="auto" controls>
                                <source src={showDetail.show_trailer_url} type="video/mp4" />
                                <source src={showDetail.show_trailer_url} type="video/ogg" />
                            </video> */}
                        </div>
                        <div className="details_overlay d-overlay"></div>
                        <div className="details_overlay-two d-overlay"></div>
                        <div className="details_wrapper" >
                            <div className="details_poster d-flex">
                                <div className="js__details_poster-thumb details_poster-thumb">
                                    <div className="ds-thumb on-hover">
                                        <div className="ds-thumb_inner">
                                            <div className="ds-media ds-thumb_media">
                                                <img class="ds-media_image lazyloaded" src={showDetail.show_poster} style={{ direction: 'ltr', textAlign: 'left' }} loading="lazy" />

                                                {/* <div className="ds-thumb_content_inner"> */}
                                                {/* <a href="https://www.filimo.com/signin?afterlogin=watch&amp;uid=zkjbr" className="overlay--transparent" ></a> */}
                                                <div className="ds-thumb_content_middle ta-center on-hover_show">
                                                    <button className="ui-btn-purchase ui-btn-circle ui-btn" tabindex="-1">
                                                        <i class="fa-solid fa-play"></i>
                                                    </button>
                                                </div>
                                                {/* </div> */}
                                            </div>

                                            {/* <div className="ds-thumb_content_inner">
                                                    <a href="https://www.filimo.com/signin?afterlogin=watch&amp;uid=zkjbr" className="overlay--transparent" ></a>
                                                    <div className="ds-thumb_content_middle ta-center on-hover_show">
                                                        <button className="ui-btn-purchase ui-btn-circle ui-btn" tabindex="-1">
                                                             <i class="fa-solid fa-play"></i>
                                                        </button>
                                                    </div>
                                                </div> */}
                                            <span className="overlay on-hover_show"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="details_poster-description d-flex ui-fd-c">

                                    <div className="" id="movieToggleDetails">
                                        <h1 className="details_poster-description-title">
                                            <div className="fa-title ui-fw-semibold">
                                                <span className="d-inline-flex movie_detail_title" style={{ direction: 'ltr', textAlign: 'left' }}>Cartoon Song Break My Hand (Dream Animation Music Album)</span>
                                                <span className="ui-icons ui-icon-arrow_down_ios ui-bg-white ui-br-24 ui-p-x d-none mobile_accordion-icon ui-icon-size-14">
                                                </span>
                                            </div>
                                            <p className="en-title ui-fw-normal ui-fs-medium force-text-en d-inline-bock"></p>
                                        </h1>
                                    </div>

                                    <div className="details_poster-description-rating"></div>

                                    <div className="details_poster-description-age ui-fc-primary ui-fi-primary d-inline-flex">
                                        <span className="age-badge ui-ml-x age-three">
                                        </span>
                                        <span className="sutable_age_text" style={{ direction: 'ltr', textAlign: 'left' }}> Suitable for over 1 year old </span>
                                    </div>

                                    <div className="details_poster-description-meta">
                                        <table className="details-v2_mobile-accordion w100">
                                            <tbody>
                                                <tr className="details_poster-description-director ui-mb-4x d-flex ui-ai-c md-ui-jc-c" >
                                                </tr>
                                                <tr className="details_poster-description-more ui-mb-6x d-flex">
                                                    <th className="ui-fw-normal" style={{ direction: 'ltr', textAlign: 'left' }}> 3 Minutes - Produced <a href="https://www.filimo.com/tag/iranian/%D۸%A۷%DB%۸C%D۸%B۱%D۸%A۷%D۹%۸۶%DB%۸C">in Iran</a> - 2024 - HD Quality
                                                    </th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="d-flex ui-ai-c details_poster-description-badge">
                                            <ul className=" d-flex ui-ai-c list-unstyled p-0">
                                                <li className="ui-ml-2x">
                                                    <a href="https://www.filimo.com/tag/animation/%D8%A7%D9%86%DB%8C%D9%85%DB%8C%D8%B4%D9%86" className="ui-btn ui-btn-force-dark ui-btn-small ui-btn ui-br-24 ui-pr-2x ui-pl-2x ui-pt-x ui-pb-x ui-bg-gray-20 details_poster-description-meta-link genre_detail_badge" style={{ direction: 'ltr', textAlign: 'left' }}><span>Animation</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Actions */}
                            <div className="details_actions ui-pt-6x d-flex sm-ui-pt-0">
                                <div className="details_actions-right d-flex ui-ai-c">
                                    <div id="bookmark-wrapper" className="movie-bookmark-zkjbr ui-ml-2x sm-ui-ml-0 d-inline-flex" data-anl="fallow">
                                        <button id="bookmark-zkjbr"
                                            className="request-link ui-btn ui-btn-force-dark ui-btn-small ui-btn details_actions-right-link ui-br-24 mobile-bg-transparent nar_bookmark js__bookmark lock-link set-query open-modal is-ajax-button" style={{ direction: 'ltr' }}>
                                            <i className="fa-regular fa-bookmark"></i>
                                            <span className="sm-ui-pt-2x label">I'll see later</span>
                                        </button>
                                        <span className="ui-icons ui-icon-bookmark ui-icon-size-18"></span>
                                        <span className="ui-icons ui-icon-bookmark_check_fill ui-icon-size-18"></span>
                                    </div>

                                    <a href="#" className="ui-btn ui-btn-force-dark ui-btn-small ui-btn ui-br-24 details_actions-right-link ui-ml-2x sm-ui-ml-0 nar_dl open-modal episodev2_dl-btn d-block" style={{ direction: 'ltr' }}>
                                        <i className="fa-solid fa-cloud-arrow-down"></i>
                                        <span className="sm-d-block sm-ui-pt-2x label">Download</span>
                                    </a>

                                    {/* <div class="rate-zkjbr" >
                                        <div class="js__movie-rate">
                                            <div class="details_rate-none rate--zkjbr">
                                                <button
                                                    class="request-link ui-btn ui-btn-force-dark ui-btn-circle ui-btn js__like-item set-query detail-v3-like open-modal is-ajax-button">
                                                    <span class="ui-icons ui-icon-like ui-icon-size-24">
                                                      +
                                                    </span>
                                                </button>

                                                <button
                                                    class="request-link ui-btn ui-btn-force-dark ui-btn-circle ui-btn js__like-item set-query detail-v3-dislike open-modal is-ajax-button">
                                                    <span class="ui-icons icon-dislike-new ui-icon-size-24">
                                                       +
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div id="rate-mobile-wrapper" className="d-inline-flex">
                                        <button id="mobileRateButton"
                                            className="request-link ui-btn ui-btn-force-dark ui-btn-small ui-btn mobile-bg-transparent details_actions-right-link nar_like open-modal js__movie-rate lock-link set-query d-none sm-d-flex is-ajax-button">
                                            <span className="ui-icons ui-icon-like_dislike ui-icon-size-18">
                                                +
                                            </span>
                                        </button>
                                    </div>
                                </div>

                                <div className="details_actions-left d-flex ui-ai-c" data-anl="watch-cta">
                                    <div className="ps-relative parent_watch-btn">
                                        <a id="login-to-watch"
                                            className="ui-btn-purchase ui-btn-small ui-btn ui-br-24 js_show_restricted ui-mr-2x sm-ui-mr-0 sm-ui-mb-2x mobile_btn nar_btn js__hover-show-tooltip ui-br-24" style={{ direction: 'ltr' }}>
                                            <i className="fa-solid fa-play"></i>
                                            <span>Log in and watch</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="container-fluid">
                                <div className="row justify-content-center justify-content-lg-end justify-content-md-end align-items-center">
                                    <div className="col-12 col-md-7">
                                        <div className="text-center text-md-center text-lg-right movie__poster-detail">
                                            <h2 className="movie_poster-name">{showDetail.show_name}</h2>
                                            <p className="rating__badge">{showDetail.imdb_rating ? showDetail.imdb_rating : '0'} <span>IMBd</span></p>
                                            <p className="content_verify text-center  text-lg-right">Suitable for those over 15 years old</p>
                                            <p className="movie__director">Type: {showDetail.series_access}</p>
                                            
                                        </div>
                                    </div>
                                    <div className="col-10 col-sm-4 col-md-2 order-first order-sm-first order-md-last order-lg-last">
                                        <div className="details__poster_img text-center">
                                            <img src={showDetail.show_poster} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mt-5">
                                    <div className="col-md-6">
                                        <div className="bottom__video" data-aos="fade-up" data-aos-duration="2000">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <p className="m-0 text-left pl-3">If you live abroad, you can watch this series on </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="d-flex trailer__options list-unstyled m-0" style={{ gap: "10px" }}>
                                     
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* Movie Detail Story */}
            <section className="movie__detail-story">
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-lg-5">
                                <div className="movie__detail-title" data-aos="fade-up" data-aos-duration="2000">
                                    <h2>The about of the {showDetail.show_name}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: showDetail.show_info }} />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-7 text-center text-lg-right order-first order-lg-last" data-aos="fade-up" data-aos-duration="2000">
                                <div className="show_poster">
                                    {/* <img src={showDetail.show_poster} alt="" className="w-100" /> */}
                                    <MovieTrailerPlayer2 poster={showDetail.show_poster} sourceUrl={showDetail.show_trailer_url} />

                                    {/* <div className="show_over_lay"></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Seasons */}
            <section className="show__seasons">
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="seasons__main-title">
                                    <h2>All Seasons of {showDetail.show_name}</h2>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {
                                    seasons.length > 0 ? (
                                        seasons.map((seasonsAll, index) => (
                                            <div className="season__main" key={index}
                                                onClick={() => navigate(`/episodes/${seasonsAll.season_id}`)}
                                            >
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-sm-6 col-lg-3">
                                                        <div className="season__image">
                                                            <img src={seasonsAll.season_poster} alt="" className="w-100" />
                                                            <div className="season_poster_icon">
                                                                <div className="season_poster_play">
                                                                    <i class="fa-solid fa-play"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-lg-9">
                                                        <div className="seasons_title">
                                                            <p className="m-0">{seasonsAll.season_name}</p>
                                                        </div>
                                                        <div className="seasons_paragraph">
                                                            {/* {suggestions.movie_title.split(' ').length > 2
                                                            ? suggestions.movie_title.split(' ').slice(0, 2).join(' ') + '...'
                                                            : suggestions.movie_title} */}
                                                            <p className="m-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, nemo debitis. Praesentium architecto quae excepturi. Aliquam aperiam voluptas in quod possimus culpa quas repellendus neque reiciendis? Delectus inventore iusto est.</p>
                                                        </div>
                                                        <div className="season__actions">
                                                            <Link to="#" className="show_watch-btn">
                                                                <i class="fa-solid fa-play"></i>
                                                                watch
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="seasons_title text-center">
                                            <p className="m-0">Shows Not Found</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Recommended Videos */}
            <section className="details__movie-recommend">
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="details__movie-recommend-title text-right" >
                                    <h2>Recommended Movies</h2>
                                </div>
                            </div>
                        </div>
                        <div className="slider_recommend" >
                            <div className="swiper-navigation">
                                <div className="swiper-button-prev"></div>
                                <div className="swiper-button-next"></div>
                            </div>
                            {/* Swiper Component */}
                            <Swiper
                                slidesPerView={5}
                                spaceBetween={10}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={{
                                    prevEl: '.swiper-button-prev',
                                    nextEl: '.swiper-button-next',
                                }}
                                breakpoints={{
                                    100: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    480: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    992: {
                                        slidesPerView: 5,
                                        spaceBetween: 10,
                                    },
                                }}
                                autoplay={{
                                    delay: 500,
                                }}
                                modules={[Navigation]}
                                className="recommendation_swiper"
                            >
                                {

                                    suggestionShows.map((suggestions, index) => (
                                        <SwiperSlide>
                                            <div className="recommendation_card" key={index} onClick={() => navigate(`/movie-page/${suggestions.movie_id}`)}>
                                                <div className="recommend__movie-img recommendation_card_shows hover_show">
                                                    <div className="serial__over" style={{ backgroundImage: `url(${suggestions.show_poster})` }}></div>
                                                    <div className="serial__over_2" style={{ backgroundImage: `url(${suggestions.show_poster})` }}></div>
                                                    <img src={suggestions.show_poster} alt="" loading="lazy" />
                                                    <div className="recommend__movie-overlay show_overlay">
                                                        <div className="recommend-movie__info">
                                                            {/* <p className='duration'>Duration: {suggestions.movie_duration}</p>
                                                            <p className='rating d-block text-center'>{suggestions.movie_access}</p> */}
                                                            <p>Animation - musical</p>
                                                            <p className="movie_sun">iran - 1202</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="movie_recommend">
                                                    <p>
                                                        {suggestions.show_title.split(' ').length > 2
                                                            ? suggestions.show_title.split(' ').slice(0, 2).join(' ') + '...'
                                                            : suggestions.show_title}
                                                    </p>
                                                </div>
                                            </div>

                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            {/* Recommended Videos */}
            {/* faqs */}
            {/* Faqs */}
            {
                faq.length > 0 && (
                    <section className="faqs__section faqs_for_videos">
                        <div className="container-fluid">
                            <div className="video-inner-wrapper">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="faqs__title text-right">
                                            <h2 className="text-right">Faq</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="faqs">
                                    <div className="row justify-content-center">
                                        <div className="col-md-12 videos_faqs_top">
                                            {faq.map((faq, index) => (
                                                <div className="videos_faqs_main" onClick={() => handleToggle(index)} key={index}>
                                                    <div className="videos_faq__question">
                                                        <div className="videos_faq_icon">
                                                            <p>
                                                                {activeIndex === index
                                                                    ? <i className="fa-solid fa-minus"></i>
                                                                    : <i className="fa-solid fa-plus"></i>}
                                                            </p>

                                                        </div>
                                                        <p className={activeIndex === index ? 'videos_question_active' : ''} dangerouslySetInnerHTML={{ __html: faq.faq_title }} />
                                                    </div>
                                                    <div className={`video_faq__answer ${activeIndex === index ? 'video_faqs_show' : ''}`}>
                                                        <p dangerouslySetInnerHTML={{ __html: faq.faq_description }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }

            {/*  */}
            <section className="film__cast-section">
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="film_cast-title text-right" >
                                    <h2>Cast of the {showDetail.show_name}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="film_casts">
                            <div className="casters__main">
                                {
                                    showDetail.actor_list && showDetail.actor_list.length > 0 ? (
                                        showDetail.actor_list.map((actor, index) => (
                                            <div className="caster__child" key={index}>
                                                <div className="film_caster-img">
                                                    <img src={actor.ad_image} alt={actor.ad_name} loading="lazy" />
                                                </div>
                                                <div className="film_caster-title">
                                                    <p className="m-0">{actor.ad_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="caster__child">
                                            <div className="film_caster-img">
                                                <img src={caster1} />
                                            </div>
                                            <div className="film_caster-title">
                                                <p className="m-0">No Actor Found</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Films Casts */}
            {/* othe Elements of Movie */}
            <section className="other__element-of-movie">
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="o__element-title text-right" >
                                    <h2>Other Elements of the {showDetail.show_name} Movie</h2>
                                </div>
                            </div>
                        </div>
                        <div className="other__element-names">
                            <div className="elements_main">
                                {
                                    showDetail.director_list && showDetail.director_list.length > 0 ? (
                                        showDetail.director_list.map((director, index) => (
                                            <div className="elements__child" key={index}>
                                                <div className="role">
                                                    <p>Director Name</p>
                                                </div>
                                                <div className="name_element">
                                                    <p>{director.ad_name}</p>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="elements__child">
                                            <div className="role">
                                                <p>Director Name</p>
                                            </div>
                                            <div className="name_element">
                                                <p>No director found</p>
                                            </div>
                                        </div>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* User Views Comments  */}
            <section>
                <div className="container-fluid">
                    <div className="video-inner-wrapper">
                        <div className="user__comment_wrapper">
                            <h2>Views of Song of Wish 2 (Music Album of Wish Animation)</h2>
                        </div>
                        <div className="comment__form ui-mb-6x ui-pr-2x">
                            <div className="user_avatar ui-br-circle">
                                <img src={default_user} alt="" />
                            </div>
                            <div className="comment__form_wrapper ps-relative ">
                                <form id="add-new-comment--form" onSubmit={(e) => {
                                    e.preventDefault();
                                    openLoginModal(e);
                                }} className="form ui-br-4 d-inline-block w100 ps-relative" >
                                    <div className="form-field ">
                                        <textarea name="comment_body" className="form-textarea" maxlength="800" id="comment_body" style={{ textAlign: 'start', direction: 'rtl', width: '100%' }} placeholder="How was the Film">
                                        </textarea>
                                    </div>
                                    <button type="submit" id="send-comment--button">
                                        <i className="fa-solid fa-paper-plane"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                        {/* All Comments */}
                        <section className="all__comments-sections">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <ul className="js__user_comment-list list-unstyled">
                                            {
                                                allcomments.length > 0 ? (
                                                    allcomments.map((comments, index) => (
                                                        <li className="comment__item">
                                                            <div className="comment__right-side">
                                                                <div className="user__comment_info">
                                                                    <div className="comment_pic">
                                                                        <img src={default_user} alt="" />
                                                                    </div>
                                                                    <div className="comment_info">
                                                                        <span className="contact_date">
                                                                            {/* {comments.created_at} */}
                                                                            {formatDate(comments.created_at)}
                                                                        </span>
                                                                        <span className="contact_name">
                                                                            {comments.user.name}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="rate_comment">
                                                                    <span>
                                                                        <i class="fa-solid fa-thumbs-up" onClick={() => likeComment(comments.id)}></i>
                                                                        <span className="like_count">
                                                                            {/* {comments.likes.like_dislike} */}
                                                                            {
                                                                                comments.likes.map((like, index) => (
                                                                                    <span key={index}>{like.like_dislike}</span>
                                                                                ))
                                                                            }

                                                                        </span>
                                                                    </span>
                                                                    <span>
                                                                        <i class="fa-solid fa-thumbs-down" onClick={() => dislikeComment(comments.id)}></i>
                                                                        <span className="dislike_count">
                                                                            {comments.likes.like_dislike}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="comment_body">
                                                                {/* <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Laborum, recusandae iure! Repudiandae!</p> */}
                                                                <p>{comments.content}</p>
                                                            </div>
                                                        </li>

                                                    ))
                                                ) : (
                                                    <span className="no-comments">No Comments here</span>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
            {
                loading &&
                <div className="full-screen_loader">
                    <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                </div>
            }

            {/* Login popup */}
            <section className={`login__popup-wrapper ${openModal ? 'active_modal_login' : ''}`}>
                <div className="container">
                    <div className="row justify-content-center px-3">
                        <div className="modal_login_popup">
                            <img src={lock2} className="lock-img" alt="" />
                            <p className="lock-text">To write your opinion and critique about This movie you must first enter in NewFlix</p>
                            <Link to="/login" id="login-button" href="#" class="ui-btn-primary ui-btn lock-link js_login-modal-btn">Login or Register</Link>
                            <div className="close_modal" onClick={closeLoginModal}>
                                <i class="fa-solid fa-xmark"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}
export default Showsdetail;