import '../Components/css/style.css';
import { React, useState, useEffect, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from 'axios';
import { API_BASE_URL } from '../config';

const Serials = () => {
    const prams_id = useParams();
    let childMode = parseInt(localStorage.getItem('child_mode'));
    const [serialsBygenre, setserialsBygenre] = useState([]);
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    const [genreList, genreListformovies] = useState([]);
    const [genre_id, genreListId] = useState([]);

    useEffect(() => {
        setLoading(true);
        // Movies By Genre 
        axios.post(`${API_BASE_URL}api/v1/shows_by_genre`, {
            filter: "new",
            genre_id: prams_id.id
        }).then((response) => {
            setLoading(false);

            if (childMode === 1 && subUserSelectedAge) {
                let filteredShow = response.data.VIDEO_STREAMING_APP.filter(show => {
                    if (show.content_rating === '') {
                        return false;
                    }
                    return parseInt(show.content_rating) <= subUserSelectedAge;
                });
                setserialsBygenre(filteredShow);
            }
            else {
                const latestSixShows = response.data.VIDEO_STREAMING_APP.slice(0, 6);
                setserialsBygenre(latestSixShows);
            }
        }).catch((error) => {
            console.error('Error fetching movies:', error);
        });

        // Genre List
        axios.post(`${API_BASE_URL}api/v1/genres`, {}).then((response) => {
            let p = response.data.VIDEO_STREAMING_APP;
            let genre_name = null;
            let genre_id = null;
            for (let x = 0; x < p.length; x++) {
                if (p[x].genre_id == prams_id.id) {
                    genre_name = p[x].genre_name;
                    genre_id = p[x].genre_id;
                }
            }
            genreListId(genre_id)
            genreListformovies(genre_name)
        }).catch(error => {
            console.error('Error fetching genres:', error);
        });

    }, [prams_id.id]);

    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef(null);

    const handleFilterClick = () => {
        setIsActive(!isActive); // Toggle the active state
    };
    const [isActiveAdvanceFilter, setIsActiveAdvanceFilter] = useState(false);
    const handleAdvanceFilter = () => {
        setIsActiveAdvanceFilter(!isActiveAdvanceFilter); // Toggle the active state
    }

    return (
        <section className="movies__main-section">
            <div className="container-fluid">
                <div className="inner_video_wrapper">
                    <div className="row">
                        <div className="col-6 col-md-6">
                            <div className="filter_videos_main">
                                <ul className='filter_main_box'>
                                    <li className={`filter_li ${isActiveAdvanceFilter ? 'active_li_color' : ''}`} onClick={handleAdvanceFilter}>Filter <i class="fa-solid fa-filter"></i></li>
                                    <li className={`filter_li ${isActive ? 'active_li_color' : ''}`} onClick={handleFilterClick}>
                                        <i className="fa-solid fa-angle-down"></i> sorting
                                        <div className={`filter_dropdown ${isActive ? 'active_filter' : ''}`}>
                                            <ul className='m-0 p-0'>
                                                <li><Link href="#">default</Link></li>
                                                <li><Link href="#">NewFlix news</Link></li>
                                                <li><Link href="#">new (year of manufacture)</Link></li>
                                                <li><Link href="#">old (year of manufacture)</Link></li>
                                                <li><Link href="#">Highest</Link></li>
                                                <li><Link href="#">IMDB rating</Link></li>
                                            </ul>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-6 col-md-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end' }}>
                            <div className="Movies__main-title text-right" data-aos="fade-up" data-aos-duration="2000">
                                <h2 className='allmovies_title'>Watch movies and series </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section className={`advance__filter ${isActiveAdvanceFilter ? 'filter-visible' : 'filter-hidden'}`}>
                    <div className="inner_video_wrapper">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-md-12 column_filter" style={{ display: 'flex', direction: 'rtl' }}>
                                    <ul className='advance__filter_ul'>
                                        <li className='advance__filter_li'>
                                            HD
                                            <i class="fa-solid fa-angle-down"></i>
                                        </li>
                                        <li className='advance__filter_li'>
                                            age
                                            <i class="fa-solid fa-angle-down"></i>
                                        </li>
                                        <li className='advance__filter_li'>
                                            language
                                            <i class="fa-solid fa-angle-down"></i>
                                        </li>
                                        <li className='advance__filter_li'>
                                            country
                                            <i class="fa-solid fa-angle-down"></i>
                                        </li>
                                        <li className='advance__filter_li'>
                                            genre
                                            <i class="fa-solid fa-angle-down"></i>
                                        </li>
                                        <li className='advance__filter_li'>
                                            moive
                                            <i class="fa-solid fa-angle-down"></i>
                                        </li>
                                    </ul>
                                    <div className="filter__row_btn">
                                        <button class="js__submit-filterv3" aria-label="Apply filter button" >
                                            Apply filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container-fluid">
                    <div className="inner_video_wrapper">
                        <div className="movies__main">
                            <div className="row justify-content-between">
                                <div className="col-6 col-sm-6 col-md-6 p-0">
                                    <div className="movies__view_all text-left" data-aos="fade-up" data-aos-duration="2000">
                                        <Link to={`/view-all/${genre_id}`}>view all <i class="fa-solid fa-angle-left"></i></Link>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 p-0">
                                    <div className="movies__subtitle text-right" data-aos="fade-up" data-aos-duration="2000">
                                        <h2>{genreList}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="all__movies-main">
                            <div className="videos__container">
                                {
                                    serialsBygenre.length > 0 ? (
                                        serialsBygenre.map((shows, key) => (
                                            <div className="videos_item" key={key}>
                                                <div className="movie" onClick={() => navigate(`/show-detail/${shows.show_id}`, { state: { params_id: prams_id.id } })}>
                                                    <div className="movie_img">
                                                        <img src={shows.show_poster} alt={shows.show_title} className='w-100' loading='lazy' />
                                                        {/* <div className="overlay__show">
                                                                <div className="show__info">
                                                                    <p className='rating'>Content Rating {shows.content_rating !== '' ? shows.content_rating : '0'}</p>
                                                                    <p className='rating d-block text-center'>{shows.show_access}</p>
                                                                    <p className='categ_related'>{genreName}</p>
                                                                </div>
                                                            </div> */}
                                                        <div className="overlay__movie">
                                                            <div className="movie__info">
                                                                {/* <p className='duration'>Duration: {movie.movie_duration}</p>
                                                                <p className='rating duration d-block text-center'>{movie.movie_access}</p>
                                                                <p className='categ_related'>{genreName}</p> */}
                                                                <p>Animation - musical</p>
                                                                <p className="movie_sun">iran - 1202</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="show__title">
                                                        <p className='m-0'>{shows.show_title}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p style={{ color: "white", fontFamily: 'i-med', textAlign: 'center' }}>No movies available in this Category.</p>
                                    )
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    {loading &&
                                        <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Serials;
