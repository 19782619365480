import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import kurdishflag from '../Images/home_images/kurdishflag.png';
import usaflag from '../Images/home_images/usaflag.jpg';
import logo from '../Images/home_images/newflix-logo.png';
import { API_BASE_URL } from '../config';

const Search = () => {
    const [searchOpen, setSearchOpen] = useState(false);
    const navigate = useNavigate();
    const { search_record } = useParams();
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState({
        movies: [],
        live_tv: [],
        shows: []
    });
    const [searchHistory, setSearchHistory] = useState([]);

    const searchMovie = () => {
        setSearchOpen(true);
    };

    useEffect(() => {
        if (search_record) {
            setSearchText(search_record);
        }
    }, [search_record]);

    const closeSearch = () => {
        setSearchOpen(false);
    };

    useEffect(() => {
        if (search_record) {
            axios.post(`${API_BASE_URL}api/v1/search`, {
                search_text: search_record
            })
                .then(response => {
                    setSearchResults({
                        movies: response.data.VIDEO_STREAMING_APP.movies || [],
                        live_tv: response.data.VIDEO_STREAMING_APP.live_tv || [],
                        shows: response.data.VIDEO_STREAMING_APP.shows || []
                    });
                    console.log(response.data.VIDEO_STREAMING_APP);
                })
                .catch(err => {
                    console.log("Error on search: " + err);
                });
        }
    }, [search_record]);

    const searchFunct = (event) => {
        if (event.key === 'Enter') {
            const searchText = event.target.value;
            setSearchText(searchText);

            // Save to local storage
            const savedHistories = JSON.parse(localStorage.getItem('search_histories')) || [];
            if (!savedHistories.includes(searchText) && searchText.trim() !== '') {
                savedHistories.push(searchText);
                localStorage.setItem('search_histories', JSON.stringify(savedHistories));
                setSearchHistory(savedHistories); // Update state
            }
        }

        // Fetch search results
        axios.post(`${API_BASE_URL}api/v1/search`, {
            search_text: searchText
        })
            .then(response => {
                setSearchResults({
                    movies: response.data.VIDEO_STREAMING_APP.movies || [],
                    live_tv: response.data.VIDEO_STREAMING_APP.live_tv || [],
                    shows: response.data.VIDEO_STREAMING_APP.shows || []
                });
                console.log(response.data.VIDEO_STREAMING_APP);
            })
            .catch(err => {
                console.log("Error on search: " + err);
            });
    };

    const deleteHistory = (itemToRemove) => {
        const updatedHistories = searchHistory.filter(item => item !== itemToRemove);
        localStorage.setItem('search_histories', JSON.stringify(updatedHistories));
        setSearchHistory(updatedHistories);
    };

    useEffect(() => {
        const histories = JSON.parse(localStorage.getItem('search_histories')) || [];
        setSearchHistory(histories);
    }, []);

    const playMovieSearch = (movie_id) => {
        window.location.href = `/movie-page/${movie_id}`
    }

    function extractAndLimitText(htmlString, wordLimit) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlString;
        let text = tempElement.textContent || tempElement.innerText || '';
        const words = text.split(/\s+/).slice(0, wordLimit);
        return words.join(' ');
    }

    return (
        <div className={`search__overlay search__top d-block`}>
            <div className="container">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="search__main">
                                    <div className="row align-items-start">
                                        <div className="col-md-12">
                                            <input
                                                type="text"
                                                placeholder="Search for movies, actors, directors and..."
                                                value={searchText}
                                                className="search_input"
                                                onKeyUp={searchFunct}
                                                onChange={(e) => setSearchText(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* Conditional rendering based on search input */}
                                    {searchText.trim() !== '' ? (
                                        <div className="search_results">
                                            {/* Movies */}
                                            {searchResults.movies.length > 0 && (
                                                <div className="filter_search">
                                                    <h3>Movies</h3>
                                                    {searchResults.movies.map((movie, index) => (
                                                        <div className="filter__search-main" key={index}>
                                                            <div className="row align-items-center">
                                                                <div className="col-md-10">
                                                                    <div className="filter_content">
                                                                        <h2>{movie.movie_title}</h2>
                                                                        <div className="filter_info">
                                                                            <span>{movie.movie_access}, </span>
                                                                            <span>{movie.movie_duration}</span>
                                                                        </div>
                                                                        <p className="m-0">
                                                                            {
                                                                                extractAndLimitText(movie.movie_description, 20)
                                                                            }
                                                                        </p>
                                                                        <div className="play__movie_btn">
                                                                            <Link to="#" onClick={() => playMovieSearch(movie.movie_id)}>
                                                                                Watch Movie
                                                                                <i class="fa-regular fa-circle-play"></i>

                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="search__filter_img">
                                                                        <img src={movie.movie_poster} alt={movie.movie_title} />
                                                                        <div className="over__lay_search">
                                                                            <div className="play_search__main">
                                                                                <i className="fa-solid fa-play"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            {/* Live TV */}
                                            {searchResults.live_tv.length > 0 && (
                                                <div className="filter_search">
                                                    <h3>Live TV</h3>
                                                    {searchResults.live_tv.map((live, index) => (
                                                        <div className="filter__search-main" key={index}>
                                                            <div className="row align-items-center">
                                                                <div className="col-md-10">
                                                                    <div className="filter_content">
                                                                        <h2>{live.tv_title}</h2>
                                                                        <div className="filter_info">
                                                                            <span>{live.tv_access}</span>
                                                                        </div>
                                                                        <p className="m-0">
                                                                            {
                                                                                extractAndLimitText(live.tv_description, 20)
                                                                            }
                                                                        </p>
                                                                        <div className="play__movie_btn">
                                                                            <Link to="#" onClick={() => window.location.href = `/live-tv-detail/${live.tv_id}`}>
                                                                                Watch Live Tv
                                                                                <i className="fa-solid fa-play"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="search__filter_img">
                                                                        <img src={live.tv_logo} alt={live.tv_logo} />
                                                                        <div className="over__lay_search">
                                                                            <div className="play_search__main">
                                                                                <i className="fa-solid fa-play"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            {/* Shows */}
                                            {searchResults.shows.length > 0 && (
                                                <div className="filter_search">
                                                    <h3>Shows</h3>
                                                    {searchResults.shows.map((show, index) => (
                                                        <div className="filter__search-main" key={index}>
                                                            <div className="row align-items-center">
                                                                <div className="col-md-10">
                                                                    <div className="filter_content">
                                                                        <h2>{show.show_title}</h2>
                                                                        <div className="filter_info">
                                                                            <span>{show.show_access}</span>
                                                                        </div>
                                                                        <p className="m-0">
                                                                            {
                                                                                extractAndLimitText(show.show_description, 20)
                                                                            }
                                                                        </p>
                                                                        <div className="play__movie_btn">
                                                                            <Link to="#" onClick={() => window.location.href = `/show-detail/${show.show_id}`}>
                                                                                Watch Show
                                                                                <i class="fa-regular fa-circle-play"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div className="search__filter_img">
                                                                        <img src={show.show_poster} alt={show.show_poster} />
                                                                        <div className="over__lay_search">
                                                                            <div className="play_search__main">
                                                                                <i className="fa-solid fa-play"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        // Show search history if the search bar is empty
                                        <div className="search_history_main">
                                            <div className="search_history_heading">
                                                <p className="m-0">Search History</p>
                                            </div>
                                            {searchHistory.length > 0 ? (
                                                searchHistory.map((history, index) => (
                                                    <div className="search_histories" key={index}>
                                                        <div className="history_icons">
                                                            <i
                                                                className="fa-solid fa-xmark"
                                                                style={{ color: '#fff' }}
                                                                onClick={() => deleteHistory(history)}
                                                            ></i>
                                                            {/* <i className="fa-solid fa-magnifying-glass" style={{ color: '#fff' }}></i> */}
                                                        </div>
                                                        <div className="history_data">
                                                            <p className="m-0">{history}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <p className="no__history">No search history available</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Search;
