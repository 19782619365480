import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useState, useEffect } from "react";
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';

const BioGraphyComponent = styled.div`
    background: #000;
    color: #E9E9E9;
    .bio__name__div{
     width: 70%;
    }
    .bio__info{
      display: flex;
      padding: 40px;
      align-items: center;
      direction: rtl;
      gap: 20px;
      text-align: right;
    }
      .bio__details{
       direction: rtl;
      }
       .bio__movie__types{
       display: flex;
       align-items: center;
       gap: 10px;
       p{
            border: 1px solid #E9E9E9;
            border-radius: 50%;
            padding: 6px;
         }
          button{
           background: #666;
           border: none;
           padding: 10px;
           border-radius: 10px;
           color: #E9E9E9;
          }
       }
          .bio__movie__section {
  direction: rtl;
  display: grid;
  place-items: center;
}

.bio__movie__div {
  width: 90%;
}

.bio__movies {
  display: flex;
  gap: 20px;
//   justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}

.bio__movie {
  width: calc(15% - 10px); /* Adjust width as needed, accounting for the gap */
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  img{
   width: 100%;
  }
}

`

const BioGraphy = ({BioData1}) =>{
    const [bioDetails, setBioDetails] = useState(null)
    const params = useParams();
    useEffect(() => {
        if (params.name === 'chris-Hemsworth') {  
            setBioDetails(BioData1);
        }
    }, [params.name, BioData1]);
    return(
        <BioGraphyComponent>
            <div className="bio__info">
              <img src={bioDetails?.profilePicture} alt={bioDetails?.englishName}/>
              <div className="bio__name__div">
                  <h1>{bioDetails?.name}</h1>
                  <h2>{bioDetails?.englishName}</h2>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'block', textAlign: 'center'}}>
                        <p>{bioDetails?.numberOfMovies}</p>
                    فیلم
                    </div>
                    <div style={{display: 'block', textAlign: 'center'}}>
                        <p>{bioDetails?.numberOfSerials}</p>
                        سریال
                    </div>
                  </div>
              </div>
              <div className="bio__details">
                  <div className="bio__movie__types">
                    <p><BookmarkAddedIcon/></p>
                    {bioDetails?.movieTypes.map((movie, index) =>(
                        <button key={index}>{movie}</button>
                    ))}
                  </div>
                  <p>{bioDetails?.bioDescription}</p>
              </div>
            </div>
            <div className="bio__movie__section">
                <div className="bio__movie__div">
                 <h2>فیلم ها</h2>
                 <div className="bio__movies">
                  {bioDetails?.movies.map((movie, index)=>(
                    <div key={index} className="bio__movie">
                       <img src={movie.movieImage} alt={movie.movieTitle}/>
                       <h3>{movie.movieTitle}</h3>
                    </div>
                  ))}
                 </div>
                 </div>
            </div>
            <div className="bio__movie__section">
                <div className="bio__movie__div">
                 <h2>سریال ها</h2>
                 <div className="bio__movies">
                  {bioDetails?.serials.map((movie, index)=>(
                    <div key={index} className="bio__movie">
                       <img src={movie.serialImage} alt={movie.serialTitle}/>
                       <h3>{movie.serialTitle}</h3>
                    </div>
                  ))}
                 </div>
                 </div>
            </div>
        </BioGraphyComponent>
    )
}

export default BioGraphy;