import styled from "styled-components";
import hero1 from "../Images/Hero1.png";
import Imdb from '../Images/imdb.png';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import axios from "axios";
import React, { useState, useEffect } from "react";
import MicIcon from '@mui/icons-material/Mic';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Avatar from "../Images/Avatar.jpg";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import SendBtn from '../Images/send.png';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import RandomMoviesByGenre from "../Components/RandomGenreMovie";
import CollectionListPage from "./CollectionList";
import MovieByGenre from "../Components/MoviebyGenre";
import ShowsBygenre from "../Components/GenreRelatedShow";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_BASE_URL } from '../config';

const MovieMenugenre = styled.div`
  .hero__section {
    // background-image: url(${hero1});
    background-image: url(${props => props.backgroundImage});
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: center;
    position: relative;
    color: #E9E9E9;
  }

  .movie__details {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 10%;
    right: 2%;
    padding: 10px;
    direction: rtl;
    text-align: right;
    .movie__rating {
      display: flex;
      gap: 10px;
      align-items: center;
      img {
        width: 48px;
        height: 24px;
      }
    }
  }

  .play__btns {
    display: flex;
    gap: 10px;
  }

  .play__btn {
    background: #F6B828;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100px;
    justify-content: center;
    border-radius: 12px;
    border: none;
  }

  .itl__btn {
    padding: 8px;
    width: 100px;
    border-radius: 12px;
    border: none;
    background: #373737;
    color: #E9E9E9;
  }

  button {
    border-radius: 360px;
    padding: 4px;
    border: 2px solid #E9E9E9;
    background: transparent;
    color: #E9E9E9;
    height: 40px;
    width: 40px;
  }

  .movies__poster__section {
    width: 100%;
    background: #1F1F1F;
    color: #E9E9E9;
    padding: 30px 0;
  }

  .movies__section {
    width: 90%;
    padding: 10px;
  }

  .custom-container {
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    .custom-container {
      width: 80%;
    }
  }

  .main__buttons {
    width: 100%;
    padding: 10px;
    position: absolute;
    bottom: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
`;

const MoviesMenuGenre = () => {
  const { id } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [genres, setGenres] = useState([]);
  const [slider, setSlider] = useState([]);
  const navigate = useNavigate();
  const [movies, setMovies] = useState([]);
  const [visibleMovies, setVisibleMovies] = useState([]);
  const [showViewMore, setShowViewMore] = useState(false);
  const [genreName, setGenreName] = useState("");

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slider.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slider.length) % slider.length);
  };

  const { title, imdb, year, rating, dubbing, duration } = slider[currentSlide] || {};
  const currentSlideData = slider[currentSlide] || {};

  useEffect(() => {
    axios.post(`${API_BASE_URL}api/v1/genres`, {})
    .then(response => {
      const genresData = response.data.VIDEO_STREAMING_APP;
      let currentGenreName = "";
      for (let i = 0; i < genresData.length; i++) {
          setGenres(genresData);
          if (genresData[i].genre_id == id) {
              currentGenreName = genresData[i].genre_name;
              break;
            }
        }
        if (currentGenreName) {
            setGenreName(currentGenreName);
        }
    })
    .catch(error => {
      console.error('There was an error making the request:', error);
    });
  
    // Genre API for Home Slider
    axios.post(`${API_BASE_URL}api/v1/home`, {})
      .then(response => {
        const allSlides = response.data.VIDEO_STREAMING_APP.slider;
        const latestSlides = allSlides.slice(0, 3); // Get the first 3 records
        setSlider(latestSlides);
      })
      .catch(error => {
        console.error('There was an error making the request:', error);
      });

    axios.post(`${API_BASE_URL}api/v1/movies_by_genre`, {
      'filter': 'new',
      'genre_id': id
    })
      .then(response => {
        let allMovies = response.data.VIDEO_STREAMING_APP;
        setMovies(allMovies);
        console.log(allMovies);
      })
      .catch(error => {
        console.error('There was an error making the request:', error);
      });
   
  }, [id]);

  return (
    <MovieMenugenre backgroundImage={currentSlideData.slider_image}>
      <div className="hero__section">
        <div className="movie__details">
          {/* <h1>{title}</h1> */}
          <div className="movie__rating">
            <img src={Imdb} alt="imdb" />
            <p>{imdb}</p>
            <h2>{currentSlideData.slider_title}</h2>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}><CalendarMonthOutlinedIcon style={{ color: '#E9E9E9' }} /> {year} </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}><ThumbUpIcon style={{ color: '#E9E9E9' }} /> {rating} </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}><MicIcon style={{ color: '#E9E9E9' }} /> {dubbing} </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}><WatchLaterIcon style={{ color: '#E9E9E9' }} /> {duration}</div>
          </div>
          <div className="play__btns">
            <button className="play__btn"><PlayArrowIcon />تماشا</button>
            <button className="itl__btn">اطلاعات بیشتر</button>
            <img src={SendBtn} alt="send" />
          </div>
        </div>
        <div className="main__buttons">
          <button style={{ transform: 'rotate(-180deg)' }} onClick={prevSlide}><ArrowForwardIosOutlinedIcon /></button>
          <button style={{ transform: 'rotate(180deg)' }} onClick={nextSlide}><ArrowBackIosOutlinedIcon /></button>
        </div>
      </div>
      <div className="movies__poster__section">
        <div className="custom-container">
             {
              [
                <MovieByGenre key={id+1} genre_id={id} genre_name={genreName} />,
                <RandomMoviesByGenre key={id} genre_id={id} genre_name={genreName} />
              ]
          }
        </div>
      </div>
    </MovieMenugenre>
  );
};

export default MoviesMenuGenre;
