import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const WatchList = () => {
  const [watchlist, setWatchlist] = useState([]);
  const [loading, setLoading] = useState({});
  const [preloader, setpreLoading] = useState(false);
  const UserId = localStorage.getItem('user_id');
  const navigate = useNavigate();

  useEffect(() => {
    setpreLoading(true)
    axios.post(`${API_BASE_URL}api/v1/my_watchlist`, {
      user_id: UserId
    })
      .then(response => {
        setpreLoading(false);
        setWatchlist(response.data.VIDEO_STREAMING_APP);
      }).catch(err => {
        console.log("Watchlist fetching error:" + err);
      });
  }, []);

  const removeWatchList = (post_id, type) => {
    setLoading(prevLoading => ({ ...prevLoading, [post_id]: true })); 
    axios.post(`${API_BASE_URL}api/v1/watchlist_remove`, {
      user_id: UserId,
      post_id: post_id,
      post_type: type
    }).then(response => {
      setWatchlist(prevWatchlist => prevWatchlist.filter(item => item.post_id !== post_id));
    }).catch(err => {
      console.log("Delete watchlist error " + err);
    }).finally(() => {
      setLoading(prevLoading => ({ ...prevLoading, [post_id]: false }));
    });
  };

  const playWatchlist = (post_id) => {
    navigate(`/movie-page/${post_id}`);
  };

  return (
    <section className='watch__list-section'>
      <div className="container-fluid">
        <div className="custom-container">
          <div className="row">
            <div className="col-md-12">
              <div className="watch__list-title">
                <h2>WatchList</h2>
              </div>
            </div>
            <div className="col-md-12">
              <p className='watch_list_alert'></p>
              <div className="main-watch_list">
                {
                  watchlist.length > 0 ? (
                    watchlist.map((userWatchList, index) => (
                      <div className="watch__list" key={index}>
                        <div className="row align-items-center">
                          <div className="col-md-2">
                            <div className="watch__list_image">
                              <img src={userWatchList.post_image} alt={userWatchList.post_title} loading='lazy' />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <div className="watch__list-info">
                              <p className='title_watch_list'>{userWatchList.post_title}</p>
                              <p className='watch__type m-0'>Type : {userWatchList.post_type}</p>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="watch__list-actions">
                              <button className='remove_watch_list' onClick={() => removeWatchList(userWatchList.post_id, userWatchList.post_type)}>
                                Remove
                              </button>
                              {loading[userWatchList.post_id] && (
                                <div className="spinner-border spinner_color mb-3" role="status"></div>
                              )}
                              <button className='play_watch-list' onClick={() => playWatchlist(userWatchList.post_id)}>Play</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className='watch_list-length'>No items found in your watchlist.</p>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        preloader &&
        <div className="full-screen_loader">
          <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
        </div>
      }
    </section>
  );
};

export default WatchList;
