import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import bestof from '../Images/home_images/best-off-2.webp';
import { useNavigate } from 'react-router-dom'; // Updated to useNavigate
import logo from '../Images/newflix-logo.png';
import chip1 from '../Images/home_images/chip1.svg';
import newflixPng from '../Images/newflix-logo.png';
import { API_BASE_URL } from '../config';

import just_icon from '../Images/just_icon.png';
const SubscriptionPage = () => {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [checkPlans, setPlans] = useState([]);
  let [userplan, setUserplan] = useState('');
  const [checkplansuscription, setcheckplansuscription] = useState('');
  let UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0;

  const handleSubscriptionClick = (subscriptionsplan) => {
    console.log(subscriptionsplan);

    localStorage.setItem("planePrice", subscriptionsplan.plan_price);
    localStorage.setItem("planDays", parseInt(subscriptionsplan.plan_days));
    localStorage.setItem("planName", subscriptionsplan.plan_name);
    localStorage.setItem("deviceLimit", subscriptionsplan.plan_device_limit);
    localStorage.setItem("plan_id", subscriptionsplan.plan_id);
    let userId = localStorage.getItem("user_id")
    if (!userId) {
      navigate('/login');
    } else {
      window.location.href = '/payment-process';
    }
  }
  // Modify body styles for this page

  useEffect(() => {
    document.body.style.background = '#f5f5f5';
    return () => {
      document.body.style.boxShadow = '';
      document.body.style.margin = '';
      document.body.style.maxWidth = '';
      document.body.style.minHeight = '';
      document.body.style.background = '';
    };
  }, []);

  useEffect(() => {
    setLoading(true);

    axios.post(`${API_BASE_URL}api/v1/subscription_plan`).then(response => {
      setLoading(false);
      setPlans(response.data.VIDEO_STREAMING_APP);
      console.log(response.data.VIDEO_STREAMING_APP)
    }).catch(err => {
      setLoading(false);
      console.log("something went wrong:" + err);
    })
  }, []);

  const [openCountFormCondition, setOpenCountFormCondition] = useState(false);

  const openCountForm = () => {
    setOpenCountFormCondition(!openCountFormCondition)

  }
  // Coupon Code Work
  const [couponCode, setCouponCode] = useState('');
  const [CouponCode, SetCouponCode] = useState(null);
  const [alertMsg, SetalertMsg] = useState('');

  const handleInputChange = (e) => {
    setCouponCode(e.target.value); // Set the coupon code from the input field

  };
  // Function to verify the coupon code

  const verifyCouponCode = () => {
    if (!couponCode) {
      SetalertMsg('Please enter a coupon code')
      return;
    }
    setLoading(true); // Start loading state

    axios.post(`${API_BASE_URL}api/v1/apply_coupon_code`, { coupon_code: couponCode })

      .then(response => {
        setLoading(false); // Stop loading state
        if (response.data.VIDEO_STREAMING_APP[0].success == 0) {
          SetalertMsg(response.data.VIDEO_STREAMING_APP[0].msg);
        } else {
          SetalertMsg(response.data.VIDEO_STREAMING_APP[0].msg + " " + "please select a plan");
          localStorage.setItem('coupon_code', couponCode)
          localStorage.setItem('coupon_percentage', response.data.VIDEO_STREAMING_APP[0].coupon_percentage)
        }
        SetCouponCode(response.data.VIDEO_STREAMING_APP); // Handle the API response
      })
      .catch(err => {
        setLoading(false); // Stop loading state
        console.log("something went wrong:" + err);
        alert('Invalid coupon code');
      });
  };

  useEffect(() => {

    axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
      user_id: UserId
    })
      .then(response => {
        let checkPlan = response.data.VIDEO_STREAMING_APP;
        setcheckplansuscription(checkPlan.msg);
        setUserplan(checkPlan.current_plan);
        console.log(checkPlan)
      }).catch(err => {
        console.log(err);
      })

  }, [UserId]);

  return (
    <body className='payment_page'>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-end subscription_column_logo">
            <div className="logo_subscription">
              <img src={just_icon} alt="" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
            </div>
          </div>
        </div>
      </div>
      <section className='subscription__main-section'>
        <div className="container-fluid">
          <div className="custom-container">
            <div className="row justify-content-center">
              <div className="col-md-10 col-lg-5">
                <div className="subscription__content">
                  <div className="head__subscription">
                    <div className="row justify-content-center">
                      <div className="col-10 col-sm-10 col-md-10">
                        <div className="head__subscription__content">
                          <h2>Buy NewFlix Subscription
                          </h2>
                          <p>The subscription you get is to watch <span>Flowers or Absurdities, Lullabies, Wound Care: Revenge, and over 95,000 other movies and series.</span></p>
                        </div>
                      </div>
                      <div className="col-2 col-sm-2 col-md-2">
                        <div className="rigth__subscription-image">
                          <img src={bestof} />
                        </div>
                      </div>
                    </div>

                    <div className="subscription__second__section">
                      <div className="row justify-content-around align-items-center">
                        <div className="col-md-3 order-last order-md-first mt-3 mt-md-0">
                          <div className="subscription__button">
                            <Link to="#">
                              Buy Newfix Subscription
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className='m-0 mt-3 mt-md-0'>If you live abroad, you can watch thousands of movies, series, and shows on</p>
                        </div>
                        <div className="col-12 col-md-2 text-center text-md-right order-first order-md-last">
                          <div className="subscription_logo_img">
                            <img src={logo} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-12 text-center">
                        {loading &&
                          <div class="spinner-border spinner_color mb-3" role="status"></div>
                        }
                      </div>
                    </div>

                    <div className="subscription__plan-main">
                      {
                        // checkPlans
                        //   .filter(subscriptionsplan => {
                        //     return !(checkplansuscription === "My Subscription" && subscriptionsplan.plan_name === userplan);
                        //   })
                        //   .map((subscriptionsplan, index) => (
                        //     <div key={index} className={`subscription__plan ${subscriptionsplan.selected ? 'bg-light border-success' : 'border-light'}`} onClick={() => handleSubscriptionClick(subscriptionsplan)}
                        //       style={{ cursor: 'pointer', borderRadius: '10px' }}>
                        //       <div className="row align-items-center">
                        //         <div className="col-6 col-sm-6 col-md-4">
                        //           <div className="pricing_plan">
                        //             <Link to="#" className='pricing_plan_real'>IQD{subscriptionsplan.plan_price}</Link>
                        //             <Link to="#" className='old_price'><del>{subscriptionsplan.old_price}</del></Link>
                           
                        //           </div>
                        //         </div>
                        //         <div className="col-6 col-sm-6 col-md-8 text-right">
                        //           <div className="duration__plan">
                        //             <p className='m-0'>{subscriptionsplan.plan_name}</p>
                        //             <div className="sticker">
                        //               <img src={subscriptionsplan.plan_logo} alt="" />
                        //             </div>
                        //           </div>
                        //         </div>
                        //       </div>
                        //     </div>
                        //   ))

                          checkPlans.map((subscriptionsplan, index) => {
                            // Check if the user has subscribed to this plan
                            const isCurrentPlan = checkplansuscription === "My Subscription" && subscriptionsplan.plan_name === userplan;
                            return (
                              <div
                                key={index} 
                                className={`subscription__plan ${subscriptionsplan.selected ? 'bg-light border-success' : 'border-light'}`}
                                // Disable click if this is the user's current plan
                                onClick={!isCurrentPlan ? () => handleSubscriptionClick(subscriptionsplan) : null}
                                style={{ cursor: isCurrentPlan ? 'not-allowed' : 'pointer', borderRadius: '10px' }}
                              >
                                <div className="row align-items-center">
                                  <div className="col-6 col-sm-6 col-md-4">
                                    <div className="pricing_plan">
                                      {/* If the user has subscribed to this plan, show a message instead of the price */}
                                      {isCurrentPlan ? (
                                        <Link to="#" className="pricing_plan_real" style={{padding:'7px 22px'}}>subscribed</Link>
                                      ) : (
                                        <>
                                          <Link to="#" className='pricing_plan_real'>IQD{subscriptionsplan.plan_price}</Link>
                                          <Link to="#" className='old_price'><del>{subscriptionsplan.old_price}</del></Link>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-8 text-right">
                                    <div className="duration__plan">
                                      <p className='m-0'>{subscriptionsplan.plan_name}</p>
                                      <div className="sticker">
                                        <img src={subscriptionsplan.plan_logo} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        }
                      <p className='notice__subscription'>10% VAT will be added to the price of all subscriptions.</p>
                    </div>
                    {/* Copoun Code  */}
                    <div className="discount_code">

                      <div className="discount_code_main" onClick={openCountForm}>
                        <div className="row">
                          <div className="col-md-12 p-0 discount_code_border">
                            <div className="discount_code_content">
                              <p>Enter the discount code for your subscription purchase.<i class="fa-solid fa-percent"></i></p>
                              <i class="fa-solid fa-angle-down discount_code_content_down_angle"></i>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`row discount_code_form_row ${openCountFormCondition ? 'active_discount_code' : ''}`}>
                        <div className="col-md-12">
                          <div className="discount_form">
                            <input type="text" placeholder="Enter Your code" value={couponCode}
                              onChange={handleInputChange} />
                            <button
                              className='verification_code_btn'
                              onClick={verifyCouponCode}
                              disabled={loading}>Code Verification</button>
                            <div className={`alert_msg_subscription ${alertMsg != null ? 'active_msg_sub' : ''} `}>
                              {loading && alertMsg &&
                                <div class="spinner-border spinner_color mb-3" role="status"></div>
                              }
                              <p className='m-0'>{alertMsg}</p>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                  {/* Copoun Code  */}
                  {/* Bottom */}
                  <div className="bottom__subscription">
                    <div className="row">
                      <div className="col-md-12">
                        <ul className='list-unstyled'>
                          <li><i className="fa-solid fa-check"></i> You can download and watch NewFlix movies and series without internet
                          </li>
                          <li><i className="fa-solid fa-check"></i> You can watch NewFlix on 3 different devices simultaneously with one subscription
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="bottom_notice">
                      <div className="row ">
                        <div className="col-md-12">
                          <div className="bottom_cotent">
                            <span className='notice__main'>
                              From now on, by purchasing or renewing the subscription, it will not be possible for users outside of Iraq to watch movies.
                            </span>
                            <span><i class="fa-solid fa-triangle-exclamation"></i></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </body>
  );
}

export default SubscriptionPage;