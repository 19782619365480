import { React } from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { API_BASE_URL } from '../config';

const LiveTvComponent = ({ genreId, genreName, moviesByGenre }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));
    const [livetvvideos, setlivechannel] = useState([]);
    // Movie By Genre
    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/livetv_by_category`, {
            filter: 'rand',
            category_id: genreId
        })
            .then(response => {
                setLoading(false);
                setlivechannel(response.data.VIDEO_STREAMING_APP.slice(0,6))
            })
            .catch(err => {
                console.log("while fetching channels erros showing " + err);
            })
    }, [])
        const livetvDetail = (live_channel_id) => {
           navigate(`/live-tv-detail/${live_channel_id}`)
        }
    return (
        <>
            {
                livetvvideos.length > 0 ? (
                    livetvvideos.map((liveChannels, index) => (
                        <div className="col-md-2">
                            <div className="tv__card" key={index} onClick={() => livetvDetail(liveChannels.tv_id)}>
                                <div className="tv__logo">
                                    <img src={liveChannels.tv_logo} alt={liveChannels.tv_title} loading='lazy' />
                                    <div className="tv__layer">
                                        <div className="more__info-tv">
                                            <p className='access'>{liveChannels.tv_access}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tv__title">
                                <p>{liveChannels.tv_title}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="tv__title">
                        <p>Tv channels Not found</p>
                    </div>
                )
            }
        </>
    );
};

export default LiveTvComponent;
