import { React, useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import bestof from '../Images/home_images/best-off.webp';
import change_img from '../Images/change_img.webp';
import sitelogo from '../Images/home_images/newflix-logo.png';
import bestof2 from '../Images/home_images/best-off-2.webp';
import conver_1 from '../Images/home_images/cover-1.webp';
import convertableimage from '../Images/home_images/convertable_image.webp';
import convertablelogo from '../Images/home_images/convertable_logo.webp';
import imagelock from '../Images/home_images/lock-ouside.webp';
import lock from '../Images/home_images/lock.webp';
import icon_testimonial from '../Images/quote.webp';
import user_image_testimonial from '../Images/testimonial_user__image.webp';
import laptop from '../Images/home_images/laptop_icon.webp';
import mobile from '../Images/home_images/mobile.webp';
import imdb_small from '../Images/icon-imdb.webp';
import ps from '../Images/home_images/ps5.webp';
import tv from '../Images/home_images/tv.webp';
import watchnewflix from '../Images/home_images/watch_newflix.webp';
import androidtv from '../Images/home_images/androidTV.webp';
import freecontent from '../Images/home_images/free_content.webp';
import bg_kids from '../Images/home_images/bg-kids.webp';
import drama_card from '../Images/home_images/drama_card.webp';
import zakham from '../Images/zakham.webp';
import profile from '../Images/home_images/profile-1.png';
import lock5 from '../Images/lock5.webp';
import { API_BASE_URL } from '../config';
import movie_1 from '../Images/movie_1.webp'

import '../Components/css/style.css';
// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
// Import Swiper styles
import axios from "axios";

const HomePage = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [herosSection, setSlider] = useState(null);
  const [homeSlider, setHomeSlider] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingsection, setLoadingSection] = useState(false)
  const [season, setSeason] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [freemovies, setFreeMovies] = useState([]);
  const [testimonials, settestimonial] = useState([]);
  const [faq, setfaq] = useState([]);
  const [season_id, set_season_id] = useState(null);
  const [saveShowId, setsaveShowId] = useState(null);
  const navigate = useNavigate();
  const [activeMovie, setActiveMovie] = useState(null); // Track active movie
  const [activeserial, setaAtiveserial] = useState(null); // Track active movie

  let [userplan, setUserplan] = useState('');
  const [checkplansuscription, setcheckplansuscription] = useState('');
  let UserId = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : 0;

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  // For Home Slider Api
  useEffect(() => {
    setLoading(true);
    axios.post(`${API_BASE_URL}api/v1/home`)
      .then(response => {
        setHomeSlider(response.data.VIDEO_STREAMING_APP.slider);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log("Fetching slider error " + err);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % homeSlider.length);
    }, 10000);
    return () => clearInterval(interval);

  }, [homeSlider]);
  // Shows Api Call
  // useEffect(() => {
  //   // Fetch the list of shows and select a default show_id
  //   axios.post(`${API_BASE_URL}api/v1/shows`)
  //     .then(response => {
  //       let random_shows = response.data.VIDEO_STREAMING_APP.shows.slice(0, 6);
  //       setShows(random_shows);
  //       // console.log(random_shows);
  //       if (random_shows.length > 0) {
  //         const defaultShowId = random_shows[0].show_id; // Select the first show_id as default
  //         showidchange(defaultShowId);
  //       }
  //     })
  //     .catch(err => {
  //       console.log("Error during fetching shows on Home page: " + err);
  //     });
  // }, []);

  // Show and seasons API and change section
  const showidchange = (show_id) => {
    setLoadingSection(true);
    setsaveShowId(show_id);
    setaAtiveserial(show_id)
    axios.post(`${API_BASE_URL}api/v1/seasons`, {
      show_id: show_id,
    })
      .then(response => {
        const seasonData = response.data.VIDEO_STREAMING_APP[0];
        set_season_id(seasonData.season_id);
        console.log(seasonData)
        setSeason(seasonData);
        axios.post(`${API_BASE_URL}api/v1/episodes`, {
          user_id: localStorage.getItem('user_id') || '',
          season_id: seasonData.season_id
        }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            setLoadingSection(false);
            setEpisodes(response.data.VIDEO_STREAMING_APP);
            // console.log(response.data.VIDEO_STREAMING_APP);
          })
          .catch(err => {
            console.log("Episodes fetching error: " + err);
          });
      })
      .catch(err => {
        console.log("Seasons fetching error: " + err);
      });
  };
  const episodeHandle = (episode) => {
    navigate(`/watch-episode/${episode.episode_id}?season_id=${season_id}`);
  };

  useEffect(() => {
    axios.post(`${API_BASE_URL}api/v1/movies`)
      .then(response => {
        // Filter movies with 'Free' access and limit to 8 movies
        let freeMoviesFilter = response.data.VIDEO_STREAMING_APP.movies.filter(movie => movie.movie_access == 'Free').slice(0, 15);
        setFreeMovies(freeMoviesFilter);
        console.log(freeMoviesFilter);
      })
      .catch(err => {
        console.log("Fetching Free Movies Error " + err);
      });

  }, []);

  useEffect(() => {
    // Testimonials
    axios.post(`${API_BASE_URL}api/v1/testimonials`)
      .then(response => {
        //  console.log(response.data.VIDEO_STREAMING_APP.testimonial);
        settestimonial(response.data.VIDEO_STREAMING_APP.testimonial);
      })
      .catch(err => {
        console.log("Testimonial fetching error " + err);
      })

    //  Testimonial Api
    // Faqs
    axios.post(`${API_BASE_URL}api/v1/faqs`)
      .then(response => {
        //  console.log(response.data.VIDEO_STREAMING_APP.faq);
        setfaq(response.data.VIDEO_STREAMING_APP.faq);
      })
      .catch(err => {
        console.log("Faqs fetching error " + err);
      });

  }, []);

  const [selectedSection, setSelectedSection] = useState('Serial'); // Default to 'Serials'
  const [showshomepage, setShows] = useState([]);
  const [MoviesHomePage, setMoviesHomePage] = useState([]);
  const [MovieDetail, SetMovieDetail] = useState([]);
  const changeSection = (section) => {
    setSelectedSection(section);
  };

  useEffect(() => {
    if (selectedSection == 'Serial') {
      axios.post(`${API_BASE_URL}api/v1/shows`).then(response => {
        let random_shows = response.data.VIDEO_STREAMING_APP.shows.slice(0, 6);
        setShows(random_shows);
        if (random_shows.length > 0) {
          const defaultShowId = random_shows[0].show_id;
          showidchange(defaultShowId);
        }
      }).catch(err => {
        console.log("Fetching Free Movies Error " + err);
      })
    } else if (selectedSection == 'Movies') {
      axios.post(`${API_BASE_URL}api/v1/movies`)
        .then(response => {
          // Filter movies with 'Free' access and limit to 8 movies
          let random_movies = response.data.VIDEO_STREAMING_APP.movies.slice(0, 6);
          if (random_movies.length > 0) {
            const defaultShowId = random_movies[0].movie_id;
            Movieidchange(defaultShowId);
          }
          setMoviesHomePage(random_movies);
          console.log(random_movies);
        })
        .catch(err => {
          console.log("Fetching Free Movies Error " + err);
        });
    }
  }, [selectedSection]);

  useEffect(() => {
    axios.post(`${API_BASE_URL}api/v1/check_user_plan`, {
      user_id: UserId
    })
      .then(response => {
        let checkPlan = response.data.VIDEO_STREAMING_APP;
        setcheckplansuscription(checkPlan.msg);
        setUserplan(response.data.VIDEO_STREAMING_APP);
      }).catch(err => {
        console.log(err);
      })
  }, [UserId]);
  // seltected movie Detail
  const Movieidchange = (movie_id) => {
    setLoadingSection(true);
    setActiveMovie(movie_id);
    axios.post(`${API_BASE_URL}api/v1/movies_details`, {
      user_id: localStorage.getItem('user_id') || '',
      movie_id: movie_id
    }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        setLoadingSection(false);
        SetMovieDetail(response.data.VIDEO_STREAMING_APP)
        console.log(response.data.VIDEO_STREAMING_APP);
      })
      .catch(err => {
        console.log("Episodes fetching error: " + err);
      });
  };

  useEffect(() => {
    // JavaScript to add Font Awesome icons
    const prevButtons = document.querySelectorAll('.swiper-button-prev');
    const nextButtons = document.querySelectorAll('.swiper-button-next');
    prevButtons.forEach(button => {
      button.innerHTML = '<i class="fas fa-chevron-left"></i>'; // Font Awesome left chevron
    });
    nextButtons.forEach(button => {
      button.innerHTML = '<i class="fas fa-chevron-right"></i>'; // Font Awesome right chevron
    });
  }, []);

  return (
    <>
      <section
        className="heros__section"
        style={{
          backgroundImage: homeSlider.length > 0 ? `url(${homeSlider[currentSlide].slider_image})` : 'none',
          transition: 'background-image 1s ease-in-out'
        }}
      >
        <div className="container-fluid">
          <div className="custom__container">
            <div className="row">
              <div className="col-md-12">
                <div className="heros__section-title">
                  <p>Watch movies non-stop with NewFlix</p>
                  <h2>Control is always in your hands!</h2>
                </div>
              </div>
            </div>
            <div className="heros__section-info">
              <div className="row justify-content-around px-md-0 px-lg-5">
                <div className="col-12 col-md-4">
                  <p>You can create a baby-watching organ
                    <i className="fa-solid fa-check"></i>
                  </p>
                </div>
                <div className="col-12 col-md-4">
                  <p>You can download and then watch without internet
                    <i className="fa-solid fa-check"></i>
                  </p>
                </div>
                <div className="col-12 col-md-4">
                  <p>95,000 episodes of movies and series to watch
                    <i className="fa-solid fa-check"></i>
                  </p>
                </div>
              </div>
              <div className="heros__section-btn text-center">


                {checkplansuscription === 'Renew subscription plan' && (
                  <Link to="/subscription" className="heros__suscription-btn">
                    <i className="fa-regular fa-credit-card"></i> Renew your Subscription
                  </Link>
                )}

                {checkplansuscription === 'Please select subscription plan' && (
                  <Link to="/subscription" className="heros__suscription-btn">
                    <i className="fa-regular fa-credit-card"></i> Buy Subscription & Watch
                  </Link>
                )}

                <p><span><i className="fa-solid fa-gift"></i> Special discount for new users </span></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="best__of-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="best__of-wrapper-title best__of-wrapper__main" >
                <div className="change_toggale_btn">
                  <Link to='#' onClick={() => changeSection('Movies')} className={selectedSection == 'Movies' ? 'active_section' : ''}>Movies</Link>
                  <Link to='#' onClick={() => changeSection('Serial')} className={selectedSection == 'Serial' ? 'active_section' : ''}>Serial</Link>
                </div>
                <h2>NewFlix Most popular</h2>
              </div>
            </div>
          </div>
          <div className="best__of-images-main">
            {selectedSection == 'Serial' && (
              <>
                {/* Original code for larger screens */}
                <ul className="shows-grid changable_ul multi_videos_responsive" style={{ display: 'flex', listStyle: 'none' }}>
                  {showshomepage.map((shows, index) => (
                    <li onClick={() => showidchange(shows.show_id)}
                      key={index}
                      style={{ margin: 0 }}
                      className={shows.show_id == activeserial ? 'active_movie' : ''} >
                      <div className="best__of-image">
                        <img src={shows.show_poster} alt={shows.show_title} loading="lazy" />
                        <div className="best__img-gradient"></div>
                      </div>
                    </li>
                  ))}
                </ul>


                {/* Horizontal scroll version for mobile */}
                {/* <ul className="best__of-list-mobile">
                    {showshomepage.map((shows, index) => (
                      <li
                        className={`best__of-item-mobile ${shows.show_id == activeserial ? 'active_movie' : ''}`}
                        onClick={() => showidchange(shows.show_id)}
                        key={index}
                      >
                        <div className="best__of-image">
                          <img src={shows.show_poster} alt={shows.show_title} loading="lazy" />
                          <div className="best__img-gradient"></div>
                        </div>
                      </li>
                    ))}
                  </ul> */}
              </>
            )}

            {
              selectedSection === 'Movies' && (
                <>
                  {/* For larger screens: Use grid layout */}


                  {/* Updated code for the second layout */}
                  <ul className="shows-grid changable_ul changable_ul multi_videos_responsive" style={{ display: 'flex', listStyle: 'none', }}>
                    {MoviesHomePage.map((Movie, index) => (
                      <li
                        onClick={() => Movieidchange(Movie.movie_id)}
                        key={index}
                        style={{ margin: 0 }} className={Movie.movie_id == activeMovie ? 'active_movie' : ''}>
                        <div className="best__of-image">
                          <img src={Movie.movie_poster} alt={Movie.movie_poster} loading="lazy" />
                          <div className="best__img-gradient"></div>
                        </div>
                      </li>
                    ))}
                  </ul>


                  {/* For mobile devices: Use horizontal scroll layout */}
                  {/* <ul className="best__of-list-mobile">
                      {MoviesHomePage.map((Movie, index) => (
                        <li className={`best__of-item-mobile ${Movie.movie_id == activeMovie ? 'active_movie' : ''}`} style={{ width: '150px' }} onClick={() => Movieidchange(Movie.movie_id)} key={index}>
                          <div className="best__of-image">
                            <img src={Movie.movie_poster} alt={Movie.movie_poster} loading="lazy" />
                            <div className="best__img-gradient"></div>
                          </div>
                        </li>
                      ))}
                    </ul> */}
                </>
              )
            }

          </div>
        </div>
      </section>
      {/*  */}
      {/* For Shows Section */}
      {
        selectedSection == "Serial" &&
        <section className="convertable__section" style={{
          backgroundImage: `url(${season.season_poster
            })`
        }}>
          <div className="container-fluid">
            <div className="custom-container">
              <div className="row">
                <div className="col-md-5">
                  <div className="convert__able-logo" style={{ cursor: 'pointer' }}>
                    {/* <img src={season.season_poster} onClick={() => navigate(`/show-detail/${saveShowId}`)} alt="" /> */}
                    <img src={zakham} />
                  </div>
                </div>
                <div className="col-md-7 main_section_season">
                  <div className="c__a-title">
                    <h2>{season.season_name}</h2>
                    <p className="season_director_name">Director: Muhammad Hussain</p>
                  </div>
                  <div className="c__a-detail-content">
                    <span>family</span>
                    <span>drama</span>
                  </div>
                  <div className="season_content_para">
                    <p>
                      {`of his assistants, Sima and Samira, executes the sinister plan he has drawn against Toloi girls. The moment of revenge is near`.split(' ').slice(0, 23).join(' ') + '...'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ca__gradient1 c__a-overlay"></div>
          <div className="ca__gradient2 c__a-overlay"></div>
          <div className="movie-detail__gradient"></div>
          <div className="container-fluid">
            <div className="custom-container">
              <div className="premium__movie-poster-main">

                {
                  loadingsection &&
                  <div className="loader_section" style={{ display: "flex", justifyContent: "center" }}>
                    <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                  </div>
                }
                <Swiper
                  slidesPerView={4}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    992: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                  }}
                  autoplay={{
                    delay: 500,
                  }}
                  navigation={true}
                  modules={[Navigation]}
                  className="itemEpisode"
                >
                  {episodes.length > 0 ? (
                    episodes.map((episode, index) => (
                      <SwiperSlide key={index} onClick={() => episodeHandle(episode)}>
                        <div className="item__episode-poster" style={{ cursor: 'pointer', position: 'relative' }}>
                          <img src={episode.episode_image} alt={episode.episode_title} />
                          {
                            /* {(checkplansuscription !== 'My Subscription' || episode.video_access == 'Free') && (
                            <div className="item__episode-poster_gradient c__a-poster-overlay">
                            <div className="image_lock">
                              <img src={lock} alt="Lock Icon" />
                            </div>
                            </div>
                            )} */
                          }
                          {!(episode.video_access === 'Free' || checkplansuscription === 'My Subscription') && (
                            <div className="item__episode-poster_gradient c__a-poster-overlay">
                              <div className="image_lock">
                                <img src={lock} alt="Lock Icon" />
                              </div>
                            </div>
                          )}
                          {episode.video_access === 'Free' && (
                            <div className="free_videos_data">
                              <div className="free_movie_title">
                                <i className="fa-solid fa-video"></i>
                                <span>free</span>
                              </div>
                              <div className="duration_free_video">
                                <span>{episode.duration}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="season_episode_name">
                          <p>Season 2 Episode 4</p>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    [...Array(6)].map((_, index) => (
                      <SwiperSlide key={index}>
                        <div className="item__episode-poster defalut_msg">
                          <p>Not Found any Episode Here</p>
                        </div>
                      </SwiperSlide>
                    ))
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      }
      {/* For movies Section */}
      {
        selectedSection == "Movies" &&
        <section className="convertable__section movie__convertable_Section" style={{
          backgroundImage: `url(${MovieDetail.movie_image
            })`
        }}>
          <div className="container-fluid">
            <div className="custom-container">
              <div className="row">
                <div className="col-md-5">
                  <div className="convert__able-logo" style={{ cursor: 'pointer' }}>
                    <img src={MovieDetail.movie_logo} alt="" />
                    {/* <img src={zakham} /> */}
                  </div>
                </div>
                <div className="col-md-7 main_section_season">
                  <div className="c__a-title">
                    <h2>{MovieDetail.movie_title}</h2>
                    <p className="season_director_name">
                      Director: {MovieDetail.director_list?.[0]?.ad_name || 'N/A'}
                    </p>
                  </div>
                  <div className="c__a-detail-content">

                    {
                      MovieDetail.genre_list && MovieDetail.genre_list.length > 0 ? (
                        MovieDetail.genre_list.map((genre, index) => (
                          <span key={index}>{genre.genre_name}</span>
                        ))
                      ) : (
                        <span>No genres available</span>
                      )
                    }


                    {/* <span>drama</span> */}
                    <span className="imbd_rating_movie">
                      {MovieDetail.imdb_rating != '' ? MovieDetail.imdb_rating : '0'}
                      <img src={imdb_small} alt="" />
                    </span>
                  </div>
                  <div className="season_content_para">
                    <p className="m-0">
                      {
                        MovieDetail.description?.split(' ').length > 23
                          ? MovieDetail.description.split(' ').slice(0, 23).join(' ') + '...'
                          : MovieDetail.description
                      }

                    </p>
                  </div>
                  {/* <div className="movie__content__info">
<ul className="m-0 d-flex list-unstyled list_detail">
<li>{MovieDetail.movie_access}</li>
<li>{MovieDetail.movie_duration}</li>
<li className="imbd_rating">{MovieDetail.imdb_rating} <span>IMDb</span></li>
</ul>
</div>
<div className="film__directors">
{MovieDetail.director_list && MovieDetail.director_list.length > 0 ? (
<p>Director: {MovieDetail.director_list.map(director => director.ad_name).join(', ')}</p>
) : (
<p>No director information available.</p>
)}
</div>
<div className="movie__content__discription">
<p>{MovieDetail.description}</p>
</div> */}
                  <div className="movie__watch__link">
                    {
                      MovieDetail.movie_access == 'Paid' ? (
                        <Link to={`/movie-page/${MovieDetail.movie_id}`}>
                          Buy, subscribe and watch
                          <i className="fa-regular fa-credit-card"></i>
                        </Link>

                      ) : (
                        <Link to={`/movie-page/${MovieDetail.movie_id}`}>
                          Watch Free
                          <i className="fa-regular fa-credit-card"></i>
                        </Link>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            loadingsection &&
            <div className="loader_section" style={{ display: "flex", justifyContent: "center" }}>
              <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
            </div>
          }
          <div className="ca__gradient1 c__a-overlay"></div>
          <div className="ca__gradient2 c__a-overlay"></div>
          <div className="movie-detail__gradient"></div>
        </section>
      }
      {/* Watch NewFlix All device */}
      <section className="info__new-flix">
        <div className="container-fluid">
          <div className="custom-container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 order-last order-sm-last order-md-first">
                <div className="info__new-flix-title">
                  <h2>Watch Newflix on all devices</h2>
                  <p>You can play Newflix simultaneously on 3 different devices such as mobile, tablet, laptop, TV, etc. Watch.</p>
                </div>
                <div className="more__info-new-flix">
                  <div className="option__avalable">
                    <div className="option__avalable_text">
                      <h3>Computers & Laptop</h3>
                      <p>windows PC - MacOS - Chorom OS</p>
                    </div>
                    <div className="option__avalable_img">
                      <img src={laptop} alt="" />
                    </div>
                  </div>
                  <div className="option__avalable">
                    <div className="option__avalable_text">
                      <h3>Mobiles & Tablet</h3>
                      <p>Android Phone & Tablets - Iphone and Ipad - Amazon Fire Tablets</p>
                    </div>
                    <div className="option__avalable_img">
                      <img src={mobile} alt="" />
                    </div>
                  </div>
                  <div className="option__avalable">
                    <div className="option__avalable_text">
                      <h3>Gaming Consoles (Browser)</h3>
                      <p>Xbox Series S - Xbox Seris X - PS5 - PS4</p>
                    </div>
                    <div className="option__avalable_img">
                      <img src={ps} alt="" />
                    </div>
                  </div>
                  {(checkplansuscription !== 'My Subscription') && (
                    <div className="info__btn">
                      <Link to="/subscription">
                        <i className="fa-regular fa-credit-card"></i>
                        Buy subscription and watch
                      </Link>
                    </div>
                  )}

                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-md-end justify-content-center order-md-last order-first">
                <div className="right__newflix-info-img">
                  <img src={tv} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Watch newflix on tv */}
      <section className="watch__tv-newflix">
        <div className="on_tv_mobile">
          <div className="container-fluid">
            <div className="custom-container">
              <div className="row watch_tv-newflix-row">
                <div className="col-md-12">
                  <div className="left__how-title">
                    <h2>How do I watch Newflix on TV?</h2>
                  </div>
                  <div className="how__right-content">
                    <p>For your convenience and watching Newflix on different TVs, we have provided 6 methods. Each of these 6 methods has a video tutorial that you can watch from the link below and choose the one that is compatible with your TV.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="left__how-img">
          <img src={watchnewflix} className="w-100 image_movie_1" alt="" />
          <img src={change_img} className="w-100 image_movie_2 d-sm-block d-md-none" alt="" />
        </div>
        <div className="container-fluid">
          <div className="custom-container">
            <div className="row watch_tv-newflix-row">
              <div className="col-md-12">
                <div className="left__how-title on_tv_desktop">
                  <h2>How do I watch Newflix on TV?</h2>
                </div>
                <div className="how__right-content on_tv_desktop">
                  <p>For your convenience and watching Newflix on different TVs, we have provided 6 methods. Each of these 6 methods has a video tutorial that you can watch from the link below and choose the one that is compatible with your TV.
                  </p>
                </div>
                <div className="how__right-info-main">
                  <div className="how__right-info">
                    <h3 className="m-0">television</h3>
                    <p>Amazon Fire TV - LG TVs - Chrome Cast - Apple TV - Android TV devices - Samsung</p>
                  </div>
                  <div className="how__right-info-icon">
                    <img src={androidtv} alt="" />
                  </div>
                </div>
                <div className="how__right-info-main">
                  <div className="how__right-info">
                    <h3 className="m-0">Android TV</h3>
                    <p>NVIDIA - amazon - xiaomi - minix - skystream - turewell - ematic - humax - matricom
                    </p>
                  </div>
                  <div className="how__right-info-icon">
                    <img src={androidtv} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Free Content section */}
      <section className="free__content-section">
        <div className="container-fluid">
          <div className="custom-container">
            <div className="row">
              <div className="col-md-12">
                <div className="free__content-title">
                  <h2>Free content</h2>
                </div>
              </div>
            </div>
            <div className="free__content-slider-main">
              <div className="free__slider-content">
                <div className="swiper-free-content-navigation">
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-button-next"></div>
                </div>
                <Swiper
                  slidesPerView={2}
                  spaceBetween={20}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    600: {
                      slidesPerView: 4,
                      spaceBetween: 25,
                    },
                    800: {
                      slidesPerView: 5,
                      spaceBetween: 20,
                    },
                    // when window width is >= 640px
                    992: {
                      slidesPerView: 6,
                      spaceBetween: 48,
                    },
                    1300: {
                      slidesPerView: 6,
                      spaceBetween: 55,
                    },
                  }}
                  autoplay={{
                    delay: 500,
                  }}
                  navigation={{
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next',
                  }}
                  modules={[Navigation]}
                  className="freeContentslider"
                >
                  {/* className={shows.show_id == activeserial ? 'active_movie' : ''}  */}
                  <ul className="shows-grid changable_ul" style={{ display: 'flex', flexWrap: 'wrap', listStyle: 'none', padding: 0 }}>
                    {
                      freemovies.map((free_movies, index) => (
                        <SwiperSlide>
                          <li onClick={() => navigate(`/movie-page/${free_movies.movie_id}`)}
                            key={index} style={{ margin: 0, cursor: 'pointer' }}>
                            <div className="free_movies_wrapper" >
                              <img src={free_movies.movie_poster} alt={free_movies.movie_title} loading="lazy" />
                            </div>
                            <div className="free_slider_icon">
                              <i className="fa-solid fa-video"></i>
                              <span>free</span>
                            </div>
                            <div className="free_movie__title">
                              <span>{free_movies.movie_title}</span>
                            </div>
                          </li>
                        </SwiperSlide>
                      ))
                    }
                  </ul>

                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Children Movies */}
      <section className="children__movies-section">
        <div className="container-fluid">
          <div className="custom-container">
            <div className="inner-wrapper">
              <div className="row align-items-center justify-content-around">
                <div className="col-md-5 p-0 text-right">
                  <div className="children__movies-title">
                    <h2> A diverse world of children's movies and cartoons</h2>
                    <p>Making Baby Newflix allows you to choose your child's age, Create a safe space for your child to watch. That is, he should watch age-specific content and not have access to the rest of the films and series.
                    </p>
                  </div>
                  <div className="children__movies-btn text-right">
                    <Link to="/kids" className="btn_child">Watching Baby Newflix</Link>
                    <Link to="/multiple-profiles" className="btn_child">Newflix Make a Child</Link>
                  </div>
                </div>
                <div className="col-md-5 p-0">
                  <div className="child__right-img">
                    <img src={bg_kids} alt="" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="kids__gradient"></div>
      </section>
      {/* Newflix Cinema */}
      <section className="newflix__cinema">
        <div className="container-fluid">
          <div className="custom-container">
            <div className="row">
              <div className="col-md-12">
                <div className="newflix__cinema-title text-center">
                  <h2>NewFlix Online Cinema</h2>
                </div>
                <div className="newflix__cinema-main">
                  <div className="row justify-content-center">
                    <div className="col-md-4">
                      <div className="newflix__cinema-info">
                        <p style={{ textAlign: 'right' }}>
                          Buy a ticket to watch with the whole family
                          <i className="fa-solid fa-check"></i>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="newflix__cinema-info">
                        <p style={{ textAlign: 'center' }}>
                          8 hours to watch movies anywhere
                          <i className="fa-solid fa-check"></i>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="newflix__cinema-info">
                        <p style={{ textAlign: 'left' }}>
                          Films of the day of Iranian cinema
                          <i className="fa-solid fa-check"></i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-5">
                <div className="online__cinema-card">
                  <div className="row">
                    <div className="col-6 col-sm-4 col-md-4 col-lg-4">
                      <div className="oc__card-image">
                        <img src={drama_card} alt="" />
                      </div>
                    </div>
                    <div className="col-6 col-sm-8 col-md-8 col-lg-8 p-0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <div className="oc__card-info">
                        <div className="title_of_online_cinema">
                          <h3>Blood crocodile</h3>
                          <div className="child_of_online_cinema">
                            <span>Online screening <i class="fa-solid fa-ticket"></i></span>
                          </div>
                        </div>
                        <p>Director: null</p>
                      </div>
                      <div className="oc__category">
                        <span>Drama</span>
                        <span>Criminal</span>
                      </div>
                      <div className="oc__btn">
                        <Link to="#">Buy Tickets</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Slider Testimonial  */}
      <section className="newflix__testimonial">
        <div className="container-fluid">
          <div className="custom-container">
            <div className="row">
              <div className="col-4 col-md-6">
                <div className="swiper-testimonial-navigation">
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-button-next"></div>
                </div>
              </div>
              <div className="col-8 col-md-6 text-right">
                <div className="newflix__testimonial-title text-right">
                  <h2>Users' comments after watching Newflix</h2>
                </div>
              </div>
              <div className="col-md-12">

                <Swiper
                  slidesPerView={1}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    // when window width is >= 992px
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                  }}
                  autoplay={{
                    delay: 500,
                  }}
                  navigation={{
                    prevEl: '.swiper-button-prev',
                    nextEl: '.swiper-button-next',
                  }}
                  modules={[Navigation]}
                  className="testimonial_slider"
                >
                  {
                    testimonials.map((testimonial_user, index) => (
                      <SwiperSlide key={index}>
                        <div className="testimonial__card-main">
                          <div className="testimonial__username">
                            <div className="profile__image">
                              {
                                testimonial_user.testimonial_image ?
                                  <img src={testimonial_user.testimonial_image} alt={testimonial_user.testimonial_title} loading="lazy" /> :
                                  <img src={user_image_testimonial} alt={testimonial_user.testimonial_title} loading="lazy" />
                              }

                            </div>
                            <div className="profile__username">
                              <p dangerouslySetInnerHTML={{ __html: testimonial_user.testimonial_title }} />
                            </div>
                          </div>
                          <div className="testimonial__review">
                            <p dangerouslySetInnerHTML={{ __html: testimonial_user.testimonial_description }} />
                          </div>
                          <div className="testimonial_icon">
                            <img src={icon_testimonial} alt="" />
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Faqs */}
      <section className="faqs__section">
        <div className="container-fluid">
          <div className="custom-container">
            <div className="row">
              <div className="col-md-12">
                <div className="faqs__title">
                  <h2>Frequently asked questions</h2>
                  <p>سوال شایع دیگر کاربران شاید برای شما پیش امده باشد</p>
                </div>
              </div>
            </div>
            <div className="faqs">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  {faq.map((faq, index) => (
                    <div className="faq__main" onClick={() => handleToggle(index)} key={index}>
                      <div className="faq__question">
                        <div className="faq_icon">
                          <p>{activeIndex === index ? '-' : '+'}</p>
                        </div>
                        <p className={activeIndex === index ? 'question_active' : ''} dangerouslySetInnerHTML={{ __html: faq.faq_title }} />
                      </div>
                      <div className={`faq__answer ${activeIndex === index ? 'show' : ''}`}>
                        <p dangerouslySetInnerHTML={{ __html: faq.faq_description }} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Bottom Area */}
      <section className="bottom__area-section">
        <div className="container-fluid">
          <div className="custom-container">
            <div className="row justify-content-center">
              <div className="col-md-3">
                <div className="bottom__logo text-center">
                  <Link to='/'>
                    <img src={sitelogo} alt="newflix" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="bottom__info-area-main">
              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="bottom__area-info">
                    <p>
                      24-hour support to guide you
                      <i className="fa-solid fa-check"></i>
                    </p>
                    <p>
                      Thousands of animations and cartoons for children
                      <i className="fa-solid fa-check"></i>
                    </p>
                    <p>
                      Thousands of foreign movies and series (dubbed and subtitled)
                      <i className="fa-solid fa-check"></i>
                    </p>
                  </div>
                </div>
                {(checkplansuscription !== 'My Subscription') && (
                  <div className="col-md-12 text-center">
                    <div className="bottom_btn">
                      <Link to="/subscription">
                        <i className="fa-regular fa-credit-card"></i>
                        Buy subscription and watch
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        loading &&
        <div className="full-screen_loader">
          <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
        </div>
      }
    </>
  )
}

export default HomePage;