import styled from 'styled-components';
import CollectionList from '../Images/CollectionList.png';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Spiderman7 from '../Images/Spiderman7.png';
import poster1 from '../Images/poster1.png';
import TIN from '../Images/TIN.png';
import Rava from '../Images/Rava.png';
import Imdb from '../Images/imdb.png';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import MicIcon from '@mui/icons-material/Mic';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';

const CollectionListComponent = styled.div`
   background: #000;
   color: #E9E9E9;
   .collection__list__person{
    display: grid;
    place-items: center;
    margin-bottom: 20px;
   }
     .collection__list{
     display: flex;
     width: 90%;
     gap: 20px;
     align-items: center;
     direction: rtl;
     
     }
     .collection__details__movie{
      display: flex;
      align-items: center;
      gap: 10px;
      button{
       background: #666;
       color: #E9E9E9;
       border: none;
       padding: 8px;
       border-radius: 10px;
      }
     }
      .collection__filter__section{
       display: grid;
       place-items: center;
       .collection__filter{
       width: 90%;
       margin-bottom: 20px;
       display: flex;
       justify-content: space-between;
       align-items: center;
       select{
        background: transparent;
        color: #E9E9E9;
        direction: rtl;
        border: 1px solid #E9E9E9;
        border-radius: 10px;
        width: 90px;
        height: 50px;
        cursor: pointer;
       }
        button{
        background: transparent;
        color: #E9E9E9;
        border: 1px solid #E9E9E9;
        border-radius: 10px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 50px;
        cursor: pointer;         
        }
       }
      }
       .collection__displayed__section{
         display: grid;
         place-items: center;
       }
         .collection__dislayed__div{
          width: 90%;
          padding: 10px;
        //   display: flex;
        //   direction: rtl;
        //   align-items: center;
        //   gap: 10px;
         }
        .collection__card{
          display: flex;
          direction: rtl;
          align-items: center;
          gap: 10px;
          padding: 10px;
        }
          .card__info{
           
          }
          .movie__info{
           display: flex;
           align-items: center;
           gap: 10px;
           flex-wrap: wrap;
           direction: rtl;
           p{
            display: flex;
            align-items: center;
            gap: 10px;
           }
          }
           .movie__type{
            display: flex;
            align-items: center;
            gap: 10px;
            button{
             background: #555;
             color: #E9E9E9;
             padding: 8px;
             border-radius: 10px;
            }
           }
            @media (max-width: 610px) {
             .collection__card{
              flex-direction: column;
              justify-content: flex-start;
             }
      // img{
      //  width: 100px;
      // }
  }
            @media (max-width: 500px) {

      .collection__list{
        flex-direction: column;
      }
}
`
const collectionData = [
  {
    image: Spiderman7,
    title: 'مرد عنکبوتی: به درون دنیای عنکبوتی',
    imdbRating: '۸.۷/۱۰',
    movieType: 'انیمیشن',
    releaseDate: '۲۰۱۸',
    language: 'دوبله',
    likedPercentage: '۸۶٪',
    trailerDuration: '۱:۵۶',
    movieTypes: ['انیمیشن', 'ماجراجویی', 'اکشن'],
    movieBio: 'شامیک مور, هیلی استاینفیلد, اسکار آیزاک, جیک جانسون',
    movieDes: 'مایلز مورالس ناگهان پرت می‌شود به جهان موازی، جایی که با تیمی از افراد عنکبوتی که وظیفه حفاظت از موجودیت آن را دارند، مواجه می‌شود. هنگامی که قهرمانان در مورد چگونگی مقابله با یک تهدید جدید با یک‌دیگر به مشکل می‌خورند، مایلز باید معنای قهرمان بودن را دوباره تعریف کند.'
  },
  {
    image: poster1,
    title: 'مرد عنکبوتی: آن سوی دنیای عنکبوتی ( بخش 1 )',
    imdbRating: '۸.۸/۱۰',
    movieType: 'انیمیشن',
    releaseDate: '۲۰۲۳',
    language: 'دوبله',
    likedPercentage: '۹۱٪',
    trailerDuration: '۲:۰۲',
    movieTypes: ['انیمیشن', 'ماجراجویی', 'اکشن'],
    movieBio: 'شامیک مور, هیلی استاینفیلد, اسکار آیزاک, جیک جانسون',
    movieDes: 'مایلز مورالس پس از به دست آوردن قدرت های فوق العاده از نیش عنکبوت، از شهر به عنوان مرد عنکبوتی محافظت می کند. به زودی، او با نسخه های جایگزین خود آشنا می شود و درگیر نبردی حماسی برای نجات جهان چندگانه می شود.'
  },
  {
    image: Rava,
    title: 'رایا و آخرین اژدها',
    imdbRating: '۷.۳/۱۰',
    movieType: 'انیمیشن',
    releaseDate: '۲۰۲۱',
    language: 'دوبله',
    likedPercentage: '۸۹٪',
    trailerDuration: '۱:۴۷',
    movieTypes: ['انیمیشن', 'خانوادگی', 'ماجراجویی'],
    movieBio: 'اوکوافینا, کلی ماری ترن, جما چان, دانیل دای کیم',
    movieDes: 'رایا، یک جنگجو، تصمیم می گیرد تا سیسو، یک اژدها را که تمام قدرت های خود را به یک جواهر جادویی منتقل کرده است، که اکنون در سراسر پادشاهی کوماندرا پراکنده شده است، و مردم آن را تقسیم می کند.'
  },
  {
    image: TIN,
    title: 'تن تن',
    imdbRating: '۷.۳/۱۰',
    movieType: 'انیمیشن',
    releaseDate: '۲۰۱۱',
    language: 'دوبله',
    likedPercentage: '۹۱٪',
    trailerDuration: '۱:۴۷',
    movieTypes: ['انیمیشن', 'اکشن', 'ماجراجویی'],
    movieBio: 'اندی سرکیس, جیمی بل, سایمون پگ',
    movieDes: 'تن تن، یک خبرنگار جوان، مدل یک کشتی را می خرد و وقتی سگش طوماری را پس از شکستن اسباب بازی به او نشان می دهد، مجذوب او می شود. او به کاپیتان هادوک می گوید و آنها برای یافتن یک کشتی غرق شده وارد ماجراجویی می شوند.'
  }
];

const CollectionListPage = () => {
  return (
    <CollectionListComponent>
      <div className='collection__list__person'>
        <div className='collection__list'>
          <img src={CollectionList} alt='Collection List' />
          <div className='collection_details'>
            <h2>انیمیشن های جذاب (23 تا فیلم)</h2>
            <p>بهترین انیمیشن هایی که توی چند سال اخیر دیدم رو جمع کردم که میتونید با خانواده هم ببینید حال کنید D:</p>
            <div className='collection__details__movie'>
              <button>اکشن</button>
              <button>کمدی</button>
              <button>ماجراجویی</button>
              <button>دوبله فیلیمو</button>
            </div>
          </div>
        </div>
      </div>
      <div className='collection__filter__section'>
        <div className='collection__filter'>
          <select>
            <option>مرتب سازی</option>
          </select>
          <button>فیلتر <FilterAltOutlinedIcon /></button>
        </div>
      </div>
      <div className='collection__displayed__section'>
        <div className='collection__dislayed__div'>
          {collectionData?.map((data, index) => (
            <div className='collection__card' key={index}>
              <img src={data.image} alt={data.title} />
              <div className='card__info'>
                <h2>{data.title}</h2>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img src={Imdb} alt='IMDB' />
                  {data.imdbRating}
                </div>
                <div className='movie__info'>
                  <p><MovieCreationOutlinedIcon /> {data.movieType} </p>
                  <p> <CalendarMonthOutlinedIcon /> {data.releaseDate}</p>
                  <p><MicIcon /> {data.language}</p>
                  <p> <ThumbUpIcon /> {data.likedPercentage}</p>
                  <p><WatchLaterIcon /> {data.trailerDuration}</p>
                </div>
                <div className='movie__type'>
                  {data.movieTypes.map((type, index) => (
                    <button key={index}>{type}</button>
                  ))}
                </div>
                <div className='card__bio'>
                  <p>{data.movieBio}</p>
                  <p>{data.movieDes}</p>

                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </CollectionListComponent>
  )
}
export default CollectionListPage;