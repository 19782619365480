import '../Components/css/style.css';
import { React, useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import Allmovies from '../Components/Allmovies';
import { API_BASE_URL } from '../config';

const AllMoviesPage = () => {
    const prams_id = useParams();
    const [moviesBygenre, setMovieBygenre] = useState([]);
    const [genreList, genreListformovies] = useState([]);
    const [genre_id, genreListId] = useState([]);
    const [loading, setLoading] = useState(false);
    const subUserSelectedAge = parseInt(localStorage.getItem('sub_account_age'));

    const { id } = useParams();
    const [moviesByGenre, setMoviesByGenre] = useState([]);
    const [navGenres, setNavGenres] = useState([]);


    useEffect(() => {
        setLoading(true);
        axios.post(`${API_BASE_URL}api/v1/genres`, {})
            .then(response => {
                setNavGenres(response.data.VIDEO_STREAMING_APP);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error making the request:', error);
                setLoading(false);
            });
    }, [id]);
    const filterGenres = (type) => {
        return navGenres.filter(genre => genre.genre_type && genre.genre_type.includes(type));
    };

    // useEffect(() => {
    //     setLoading(true);
    //     // Movies By Genre 
    //     axios.post(`${API_BASE_URL}api/v1/movies_by_genre`, {
    //         genre_id: prams_id.id,
    //         filter: "new"
    //     }).then((response) => {
    //         let childMode = parseInt(localStorage.getItem('child_mode'));
    //         // console.log(response.data.VIDEO_STREAMING_APP)
    //         setLoading(false);
    //         if(subUserSelectedAge){
    //             let filteredMovies = response.data.VIDEO_STREAMING_APP.filter(movie => {
    //                 if (movie.content_rating === '') {
    //                     return false;
    //                 }
    //                 return parseInt(movie.content_rating) <= subUserSelectedAge;
    //             });
    //             setMovieBygenre(filteredMovies);
    //         }else if(childMode === 1){
    //             let filteredMovies = response.data.VIDEO_STREAMING_APP.filter(movie => {
    //                 if (movie.child_mode === '') {
    //                     return false;
    //                 }
    //                 return parseInt(movie.child_mode) == childMode;
    //             });
    //             setMovieBygenre(filteredMovies);
    //         }else{
    //             const latestSixMovies = response.data.VIDEO_STREAMING_APP.slice(0, 6);
    //             setMovieBygenre(latestSixMovies);
    //         }
    //     }).catch((error) => {
    //         setMovieBygenre([]);
    //         console.error('Error fetching movies:', error);
    //     });

    //     // Genre List
    //     axios.post(`${API_BASE_URL}api/v1/genres`, {}).then((response) => {
    //         let p = response.data.VIDEO_STREAMING_APP;
    //         let genre_name = null;
    //         let genre_id = null;
    //         for (let x = 0; x < p.length; x++) {
    //             if (p[x].genre_id == prams_id.id) {
    //                 genre_name = p[x].genre_name;
    //                 genre_id = p[x].genre_id;
    //             }
    //         }
    //         genreListId(genre_id)
    //         genreListformovies(genre_name)
    //     }).catch(error => {
    //         console.error('Error fetching genres:', error);
    //     });
    // }, [prams_id.id]);

    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef(null);
    
    const handleFilterClick = () => {
        setIsActive(!isActive); // Toggle the active state
    };
    
    // // Modify handleClickOutside to add a delay and prevent immediate closure
    // const handleClickOutside = (event) => {
    //     event.preventDefault();
    //     // Check if the dropdownRef or the toggle button was clicked
    //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    //         setTimeout(() => {
    //             setIsActive(false); // Close the dropdown if click is outside
    //         }, 10); // Allow slight delay
    //     }
    // };
    
    // Add the event listener for click outside
    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);
    
    // Open Advance Filter
    const [isActiveAdvanceFilter, setIsActiveAdvanceFilter] = useState(false);
    const handleAdvanceFilter = () => {
        setIsActiveAdvanceFilter(!isActiveAdvanceFilter); // Toggle the active state
    }
    return (
        <section className="movies__main-section">
            <div className="container-fluid">
                <div className="inner_video_wrapper">
                    <div className="row">
                        <div className="col-6 col-md-6">
                            <div className="filter_videos_main">
                                <ul className='filter_main_box'>
                                    <li className={`filter_li ${isActiveAdvanceFilter ? 'active_li_color' : ''}`} onClick={handleAdvanceFilter}>Filter <i class="fa-solid fa-filter"></i></li>
                                    <li className={`filter_li ${isActive ? 'active_li_color' : ''}`} onClick={handleFilterClick}>
                                        <i className="fa-solid fa-angle-down"></i> sorting
                                        <div className={`filter_dropdown ${isActive ? 'active_filter' : ''}`}>
                                            <ul className='m-0 p-0'>
                                                <li><Link href="#">default</Link></li>
                                                <li><Link href="#">NewFlix news</Link></li>
                                                <li><Link href="#">new (year of manufacture)</Link></li>
                                                <li><Link href="#">old (year of manufacture)</Link></li>
                                                <li><Link href="#">Highest</Link></li>
                                                <li><Link href="#">IMDB rating</Link></li>
                                            </ul>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-6 col-md-6" style={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'end' }}>
                            <div className="Movies__main-title text-right" data-aos="fade-up" data-aos-duration="2000">
                                <h2 className='allmovies_title'>Watch movies and series </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className={`advance__filter ${isActiveAdvanceFilter ? 'filter-visible' : 'filter-hidden'}`}>
                <div className="inner_video_wrapper">
                    <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 column_filter" style={{ display: 'flex', direction: 'rtl' }}>
                            <ul className='advance__filter_ul'>
                                <li className='advance__filter_li'>
                                    HD
                                    <i class="fa-solid fa-angle-down"></i>
                                </li>
                                <li className='advance__filter_li'>
                                    age
                                    <i class="fa-solid fa-angle-down"></i>
                                </li>
                                <li className='advance__filter_li'>
                                    language
                                    <i class="fa-solid fa-angle-down"></i>
                                </li>
                                <li className='advance__filter_li'>
                                    country
                                    <i class="fa-solid fa-angle-down"></i>
                                </li>
                                <li className='advance__filter_li'>
                                    genre
                                    <i class="fa-solid fa-angle-down"></i>
                                </li>
                                <li className='advance__filter_li'>
                                    moive
                                    <i class="fa-solid fa-angle-down"></i>
                                </li>
                            </ul>
                            <div className="filter__row_btn">
                                <button class="js__submit-filterv3" aria-label="Apply filter button" >
                                    Apply filter
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <div className="container-fluid">
                <div className="inner_video_wrapper">
                    <div className="movies__main">
                        {filterGenres('movies').length > 0 ? (
                            filterGenres('movies').map((navLink, index) => (
                                <div className="top_movie_change" key={index}>
                                    <div className="row justify-content-between">
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="movies__view_all text-left">
                                                <Link to={`/view-all/${navLink.genre_id}`}>view all <i class="fa-solid fa-angle-left"></i></Link>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-6 col-md-6">
                                            <div className="movies__subtitle text-right">
                                                <h2>{navLink.genre_name}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Allmovies key={navLink.genre_id} genreId={navLink.genre_id} genreName={navLink.genre_name} />
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : 'No Movies Found'}
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            {
                                loading && <div class="spinner-border spinner_color mt-2 text-center" role="status"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AllMoviesPage;
