import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import myVideo from '../asstes/TheSociety.mp4';
// import audio from '../asstes/audio.mp3';
import subtitle from '../asstes/sample.vtt';
// import subtitle from '../asstes/subtitle.srt';
import { IconButton, Slider, Menu, MenuItem, Button } from '@mui/material';
import { PlayArrow, Pause, VolumeUp, VolumeOff, Fullscreen, Forward10, Replay10, PictureInPictureAlt, Speed, Subtitles } from '@mui/icons-material';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import '../Components/css/style.css';

const ShowTrailerplayer = ({ data, sourceUrl, poster }) => {

    const parseSubtitles = (subtitleText) => {
        // Remove the WEBVTT header if it exists
        const subtitleContent = subtitleText.trim().startsWith('WEBVTT')
            ? subtitleText.trim().substring(6).trim()
            : subtitleText;

        const subtitleArray = subtitleContent.split('\n\n').map((subtitle) => {

            const lines = subtitle.split('\n');

            // Extract the time and text lines
            const timeLine = lines[0];
            const textLines = lines.slice(1);

            // Ensure time format and parse
            if (timeLine && timeLine.includes(' --> ')) {
                const [startTime, endTime] = timeLine.split(' --> ');

                return {
                    startTime: parseTime(startTime),
                    endTime: parseTime(endTime),
                    text: textLines.join(' ')
                };
            } else {
                console.error('Invalid time format:', timeLine);
            }

            return null;
        }).filter(subtitle => subtitle !== null);

        return subtitleArray;
    };



    const parseTime = (time) => {

        const [hours, minutes, secondsWithMs] = time.split(':');
        const [seconds, milliseconds] = secondsWithMs.split('.');

        const totalSeconds =
            parseInt(hours, 10) * 3600 +  // Convert hours to seconds
            parseInt(minutes, 10) * 60 +  // Convert minutes to seconds
            parseInt(seconds, 10) +        // Add seconds
            parseInt(milliseconds, 10) / 1000; // Convert milliseconds to seconds and add

        return totalSeconds;
    };


    const playerRef = useRef(null);
    const audioRef = useRef(null);  // Create a ref for the audio element

    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0);
    const [muted, setMuted] = useState(false);
    const [played, setPlayed] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);
    const [pip, setPip] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [speed, setSpeed] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [subtitlesIndex, setSubtitlesIndex] = useState(null);
    const [anchorElSubtitles, setAnchorElSubtitles] = useState(null);

    const [subtitles, setSubtitles] = useState([]);
    const [currentSubtitle, setCurrentSubtitle] = useState('');

    const [selectedSubtitleUrl, setSelectedSubtitleUrl] = useState(null);

    const [audioTrackIndex, setAudioTrackIndex] = useState(null);
    const [anchorElAudio, setAnchorElAudio] = useState(null);
    const [audioTracks, setAudioTracks] = useState([]);
    const [audioReady, setAudioReady] = useState(false); // Track if audio is ready to play

    const togglePlayPause = () => setPlaying(!playing);
    const playVideo = () => setPlaying(true);
    useEffect(() => {
        // Function to handle mouse move and start playing the video
        const handleMouseMove = () => {
            setPlaying(true);
        };

        // Add event listener for mousemove
        window.addEventListener('mousemove', handleMouseMove);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const toggleMute = () => {
        setMuted(!muted);
        if (audioRef.current) {
            audioRef.current.muted = !audioRef.current.muted;
        }
    };

    const handleVolumeChange = (e, newValue) => {
        const normalizedVolume = newValue / 100; // Convert 0-100 to 0-1
        setVolume(normalizedVolume);
        if (audioRef.current) {
            audioRef.current.volume = normalizedVolume;
        }
    };

    const handleSeekChange = (e, newValue) => {
        const seekTo = newValue / 100;
        setPlayed(seekTo);
        playerRef.current.seekTo(seekTo);
        if (audioRef.current) {
            audioRef.current.currentTime = playerRef.current.getCurrentTime(); // Sync audio with video
        }
    };

    const handleFullscreen = () => {
        const playerElement = playerRef.current.wrapper;
        if (fullscreen) {
            document.exitFullscreen();
        } else {
            playerElement.requestFullscreen();
        }
        setFullscreen(!fullscreen);
    };

    const handleForward = () => {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime + 10, 'seconds');
        if (audioRef.current) {
            audioRef.current.currentTime = playerRef.current.getCurrentTime(); // Sync audio with video
        }
    };

    const handleBackward = () => {
        const currentTime = playerRef.current.getCurrentTime();
        playerRef.current.seekTo(currentTime - 10, 'seconds');
        if (audioRef.current) {
            audioRef.current.currentTime = playerRef.current.getCurrentTime(); // Sync audio with video
        }
    };

    const handlePiP = async () => {
        try {
            if (pip) {
                // Exit Picture-in-Picture
                await document.exitPictureInPicture();
                setPip(false);
            } else {
                // Enter Picture-in-Picture
                const videoElement = playerRef.current.getInternalPlayer();
                if (videoElement) {
                    await videoElement.requestPictureInPicture();
                    setPip(true);
                }
            }
        } catch (error) {
            console.error('Failed to toggle Picture-in-Picture:', error);
        }
    };


    const handleSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
        playerRef.current.getInternalPlayer().playbackRate = newSpeed;
        if (audioRef.current) {
            audioRef.current.playbackRate = playerRef.current.getInternalPlayer().playbackRate; // Sync audio with video
        }
        setAnchorEl(null);
    };

    const handleClickSpeedMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSpeedMenu = () => {
        setAnchorEl(null);
    };

    const handleSubtitlesChange = (index) => {
        if (index === null) {
            setSelectedSubtitleUrl(null); // Turn off subtitles
        } else {
            const urls = [data.subtitle_url1, data.subtitle_url2, data.subtitle_url3];
            setSelectedSubtitleUrl(urls[index]);
        }
        setSubtitlesIndex(index);
        setAnchorElSubtitles(null);
    };

    const handleClickSubtitlesMenu = (event) => {
        setAnchorElSubtitles(event.currentTarget);
    };

    const handleCloseSubtitlesMenu = () => {
        setAnchorElSubtitles(null);
    };

    const handleAudioTrackChange = (index) => {
        setAudioTrackIndex(index);
        setAnchorElAudio(null);
    };

    const handleClickAudioMenu = (event) => {
        setAnchorElAudio(event.currentTarget);
    };

    const handleCloseAudioMenu = () => {
        setAnchorElAudio(null);
    };

    const handleAudioOff = () => {
        setAudioTrackIndex(null); // Mute audio
        setMuted(true);
        if (audioRef.current) {
            audioRef.current.muted = true;
            audioRef.current.src = ''; // Clear the audio source
        }
        setAnchorElAudio(null);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    useEffect(() => {
        const loadSubtitles = async () => {
            if (selectedSubtitleUrl) {
                try {
                    const response = await fetch(selectedSubtitleUrl);
                    const text = await response.text();
                    const subtitlesArray = parseSubtitles(text);
                    setSubtitles(subtitlesArray);
                } catch (error) {
                    console.error('Error loading subtitles:', error);
                    setSubtitles([]);
                }
            }
        };

        if (data.subtitle_on_off) {
            loadSubtitles();
        }
    }, [selectedSubtitleUrl, data.subtitle_on_off]);

    useEffect(() => {
        const subtitle = subtitles.find(sub => currentTime >= sub.startTime && currentTime <= sub.endTime);
        setCurrentSubtitle(subtitle ? subtitle.text : '');
    }, [currentTime, subtitles]);

    // Prepare subtitles menu items
    const subtitleLanguages = [
        data.subtitle_language1,
        data.subtitle_language2,
        data.subtitle_language3
    ].filter(Boolean);

    // Initialize audio tracks and set the first track by default
    useEffect(() => {
        const tracks = [];
        if (data.audio_language1 && data.audio_url1) {
            tracks.push({ label: data.audio_language1, url: data.audio_url1 });
        }
        if (data.audio_language2 && data.audio_url2) {
            tracks.push({ label: data.audio_language2, url: data.audio_url2 });
        }
        if (data.audio_language3 && data.audio_url3) {
            tracks.push({ label: data.audio_language3, url: data.audio_url3 });
        }
        setAudioTracks(tracks);

        // Automatically select the first audio track
        if (tracks.length > 0) {
            setAudioTrackIndex(0);
        }
    }, [data]);

    // Handle audio track change
    useEffect(() => {
        if (audioTrackIndex !== null && audioTracks[audioTrackIndex]) {
            const audioUrl = audioTracks[audioTrackIndex].url;
            const audioElement = audioRef.current;

            if (audioElement) {
                const currentTime = playerRef.current.getCurrentTime();
                audioElement.src = audioUrl;
                audioElement.currentTime = currentTime; // Sync audio to current video time
                setAudioReady(true); // Mark audio as ready to play
            }
        }
    }, [audioTrackIndex, audioTracks]);

    return (
        // <div onClick={playVideo} className="player-wrapper" style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div onClick={playVideo} className="player-wrapper" style={{ position: 'relative' }}>

            <ReactPlayer
                ref={playerRef}
                url={sourceUrl}
                playing={true}
                volume={volume}
                muted={muted}
                loop={true}
                onProgress={({ played, playedSeconds }) => {
                    setPlayed(played * 100);
                    setCurrentTime(playedSeconds);
                }}
                onDuration={setDuration}
                controls={false} // Hide default controls to customize
                // onPlay={() => audioRef.current && audioRef.current.play()}
                // onPause={() => audioRef.current && audioRef.current.pause()}
                onSeek={time => audioRef.current && (audioRef.current.currentTime = time)}
                width="100%"
                height="100%"
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload', // Disable download button
                        },
                    },
                }}
            />

            <audio ref={audioRef} />
            {/* Subtitle Display */}
            <div
                style={{
                    position: 'absolute',
                    bottom: '50px',
                    width: '100%',
                    textAlign: 'center',
                    color: '#fff',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                    fontSize: '20px',
                    padding: '0 20px',
                    boxSizing: 'border-box'
                }}
            >
                {currentSubtitle}
            </div>

            <div className="controls d-none">
                <IconButton onClick={togglePlayPause}>
                    {/* {playing ? <Pause /> : <PlayArrow />} */}
                </IconButton>

                <IconButton onClick={handleBackward}>
                    <Replay10 />
                </IconButton>

                <IconButton onClick={handleForward}>
                    <Forward10 />
                </IconButton>

                <Slider
                    value={played}
                    onChange={handleSeekChange}
                    aria-labelledby="continuous-slider"
                    style={{ width: '50%' }}
                />

                <IconButton onClick={toggleMute}>
                    {muted ? <VolumeOff /> : <VolumeUp />}
                </IconButton>

                <Slider
                    value={volume * 100} // Convert 0-1 volume to 0-100 slider value
                    onChange={handleVolumeChange}
                    aria-labelledby="continuous-slider"
                    style={{ width: '100px' }}
                />
                <div className="time-info">
                    <span>{formatTime(currentTime)}</span> / <span>{formatTime(duration)}</span>
                </div>

                <IconButton
                    aria-controls="speed-menu"
                    aria-haspopup="true"
                    onClick={handleClickSpeedMenu}
                >
                    <Speed />
                </IconButton>
                <Menu
                    id="speed-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseSpeedMenu}
                >
                    {[0.25, 0.5, 1, 1.5, 1.75, 2].map((rate) => (
                        <MenuItem key={rate} onClick={() => handleSpeedChange(rate)}>
                            {rate}x
                        </MenuItem>
                    ))}
                </Menu>

                <IconButton aria-controls="audio-menu" aria-haspopup="true" onClick={(e) => setAnchorElAudio(e.currentTarget)}>
                    <HeadphonesIcon />
                </IconButton>
                <Menu
                    id="audio-menu"
                    anchorEl={anchorElAudio}
                    keepMounted
                    open={Boolean(anchorElAudio)}
                    onClose={handleCloseAudioMenu}
                >
                    {/* <MenuItem onClick={handleAudioOff}>Off</MenuItem> */}
                    {audioTracks.map((track, index) => (
                        <MenuItem key={index} onClick={() => handleAudioTrackChange(index)}>{track.label}</MenuItem>
                    ))}
                </Menu>

                <IconButton aria-controls="subtitles-menu" aria-haspopup="true" onClick={(e) => setAnchorElSubtitles(e.currentTarget)}>
                    <Subtitles />
                </IconButton>
                <Menu id="subtitles-menu" anchorEl={anchorElSubtitles} keepMounted open={Boolean(anchorElSubtitles)} onClose={() => setAnchorElSubtitles(null)}>
                    <MenuItem onClick={() => handleSubtitlesChange(null)}>Off</MenuItem>
                    {subtitleLanguages.map((lang, index) => (
                        <MenuItem key={index} onClick={() => handleSubtitlesChange(index)}>{lang}</MenuItem>
                    ))}
                </Menu>

                <IconButton onClick={handlePiP}>
                    <PictureInPictureAlt />
                </IconButton>

                <IconButton onClick={handleFullscreen}>
                    <Fullscreen />
                </IconButton>
            </div>


            <div className="overlay">
                {/* <img src={poster} alt="Logo" className="logo" style={{ position: 'absolute', top: 10, left: 10 }} /> */}
                {/* <div className="custom-controls" style={{ position: 'absolute', bottom: 10, left: 10 }}>
                    <button onClick={handlePlay}>Play</button>
                    <button onClick={handlePause}>Pause</button>
                </div> */}
            </div>
        </div>
    );
};

// Helper function to parse time format into seconds
const parseTime = (time) => {
    const [hours, minutes, seconds] = time.split(':');
    const [sec, ms] = seconds.split('.');
    return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseFloat(`${sec}.${ms}`);
};

export default ShowTrailerplayer;