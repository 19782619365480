import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logoDark from '../Images/home_images/new-flex-dark.jpg';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { API_BASE_URL } from '../config';
import newflixPng from '../Images/newflix-logo.png';
import just_icon from '../Images/just_icon.png';
import google_icon from '../Images/google-g.png';

const LoginComponent = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;

  img {
    width: 100px;
  }

  h2 {
    text-align: center;
  }

  .continue__button {
    width: 100%;
    margin: 20px;

    a {
      color: #333;
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      font-size: 18px;
    }
  }

  .login__div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .login__form {
    padding: 40px;
    border: 1px solid #999;
    border-radius: 12px;
    width: 100%;

    button {
      background: rgb(0, 172, 88);
      color: rgb(255, 255, 255);
      margin-top: 12px;
      border: none;
      width: 100%;
      height: 40px;
      cursor: pointer;
      border-radius: 12px;
    }
  }

  .input__div {
    display: flex;
    padding: 10px;
    border: 1px solid #999;
    border-radius: 12px;
    justify-content: start;

    input {
      background: transparent;
      border: none;
      outline: none;
      width: 100%;
      font-size: 18px;
    }
  }

  .main__login {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login__response-alert {
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 8px;
    padding: 10px 20px;
    margin-top: 20px;
    text-align: center;
    word-break: break-word;
    color: #151515;
    font-size: 14px;
    letter-spacing: 1px;
    display: none;
  }

  .active_class_login {
    display: block !important;
    
  }
 
`;



const Login = () => {
  // Modify body styles for this page
  useEffect(() => {
    // Apply styles when the component is mounted
    document.body.style.background = '#fff';

    // Cleanup styles when the component is unmounted
    return () => {
      document.body.style.boxShadow = '';
      document.body.style.margin = '';
      document.body.style.maxWidth = '';
      document.body.style.minHeight = '';
      document.body.style.background = '';
    };
  }, []);

  const [phoneNumber, setPhoneNumber] = useState('+964');
  const navigate = useNavigate();
  const [alertResponse, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    localStorage.setItem("phoneNumberVerification", phoneNumber);
    axios.post(`${API_BASE_URL}api/v1/signup`, {
      phone: "+" + phoneNumber
    })
      .then(response => {
        console.log(response.data);
        setLoading(false);
        if (response.data.VIDEO_STREAMING_APP[0]['success'] == 1) {
          setResponse("Success! Redirecting...");
          setTimeout(() => {
            window.location.href = "/verification-process";
            // navigate('/verification-process');
          }, 2000);
        } else {
          setLoading(false);
          setResponse(response.data.VIDEO_STREAMING_APP[0]['msg']);
        }
      })
      .catch(error => {
        setResponse("There was an error submitting the phone number: " + error.message);
        console.error("Error:", error.message);
      });
  };

  return (

    <section className="login__section">
      <div className="container-fluid">
        <div className="column__login">
          <div className="logo_login_web">
            <img src={just_icon} alt="" />
          </div>
          <div className="login_heading text-center">
            <h2>Login or Register at Filimo</h2>
          </div>
          <div className="back_btn">
            <Link to='/'>Back <i class="fa-solid fa-arrow-right"></i></Link>
          </div>
        </div>
        <div className="login__form_main">

          <div className="login__form">
            <div className="login__form_heading">
              <p>To log in or register, enter your credentials: </p>
            </div>
            <div className="form_input">
              <form onSubmit={handleSubmit}>
                <div className="input__div">
                  <PhoneInput className="login_input_filed"
                    country={'iq'}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                  />
                </div>
                <button type="submit" className="login__btn">Continue</button>
              </form>
              <div className={`login__response-alert ${alertResponse ? 'active_class_login' : ''}`}>
                <p className="m-0">{alertResponse}</p>
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  {loading &&
                    <div class="spinner-border spinner_color mt-3" role="status"></div>
                  }
                </div>
              </div>
            </div>
            <div className="break_border"></div>
            <div className="login_accounts">
              <div className="google_login">
                <div className="social_login_img">
                  <img src={google_icon} alt="" />
                </div>
                <div className="social_login_name">
                  <span>Continue with Google</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



    // <LoginComponent>
    //   <div className="main__login">
    //     <div className="container-fluid">
    //       <div className="custom-container">
    //         <div className="row justify-content-center">
    //           <div className="col-12 col-md-8 col-lg-4">
    //             <div className="login__div" data-aos="fade-up" data-aos-duration="2000">
    //               <img src={logoDark} className="logoform" alt="logo" />
    //               <h2>Login or Register at Newflix</h2>
    //               <div className="continue__button">
    //                 <button onClick={() => navigate(-1)}>Back</button>
    //               </div>
    //               <div className="login__form">
    //                 <p>To log in or register, enter your credentials:</p>
    //                 <form onSubmit={handleSubmit}>
    //                   <div className="input__div">
    //                     <PhoneInput
    //                       country={'iq'}
    //                       value={phoneNumber}
    //                       onChange={setPhoneNumber}
    //                     />
    //                   </div>
    //                   <button type="submit">Continue</button>
    //                 </form>
    //                 <div className={`login__response-alert ${alertResponse ? 'active_class_login' : ''}`}>
    //                   <p className="m-0">{alertResponse}</p>
    //                 </div>
    //               <div className="row">
    //                 <div className="col-md-12 text-center">
    //                 {loading &&
    //                   <div class="spinner-border spinner_color mt-3" role="status"></div>
    //                 }
    //                 </div>
    //               </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </LoginComponent>

  );
}

export default Login;
