import React, { useEffect, useState, useRef } from 'react';
import '../Components/css/style.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import just_icon from '../Images/just_icon.png';

const ExitChildMode = () => {

    const [code, setCode] = useState(new Array(6).fill(''));
    const [countdown, setCountdown] = useState(60);
    const [isCountdownActive, setIsCountdownActive] = useState(false);
    const [alert_otp, setOtpalert] = useState('');
    const [loading, setLoading] = useState(false);
    const phoneNumber = localStorage.getItem("phoneNumberVerification");
    const [alertResponse, setResponse] = useState(null);

    const inputRefs = useRef([]);
    let navigate = useNavigate();

    useEffect(() => {
        document.body.style.background = '#fff';
        return () => {
          document.body.style.boxShadow = '';
          document.body.style.margin = '';
          document.body.style.maxWidth = '';
          document.body.style.minHeight = '';
          document.body.style.background = '';
        };
      }, []);

    useEffect(()=>{
        axios.post(`${API_BASE_URL}api/v1/signup`, {
            phone: "+"+phoneNumber
          })
            .then(response => {
              console.log(response.data);
              setLoading(false);
              if (response.data.VIDEO_STREAMING_APP[0]['success'] == 1) {
                // localStorage.setItem('child_mode',0);
                setResponse("Message send!");
                // setTimeout(() => {
                //   window.location.href = "/";
                // }, 2000);
              } else {
                setLoading(false);
                console.log(response.data.VIDEO_STREAMING_APP[0]['msg'])
                setResponse(response.data.VIDEO_STREAMING_APP[0]['msg']);
              }
            })
            .catch(error => {
              setResponse("There was an error submitting the phone number: " + error.message);
              console.error("Error:", error.message);
            });
    },[]);

    useEffect(() => {
        const storedEndTime = localStorage.getItem('countdownEndTime');

        if (storedEndTime) {
            const endTime = parseInt(storedEndTime, 10);
            const timeLeft = Math.ceil((endTime - Date.now()) / 1000);
            if (timeLeft > 0) {
                setCountdown(timeLeft);
                setIsCountdownActive(true);
            } else {
                localStorage.removeItem('countdownEndTime');
                startCountdown();
            }
        } else {
            startCountdown();
        }
    }, []);

    useEffect(() => {
        const storedEndTime = localStorage.getItem('countdownEndTime');
        if (storedEndTime) {
            const endTime = parseInt(storedEndTime, 10);
            const timeLeft = Math.ceil((endTime - Date.now()) / 1000);
            if (timeLeft > 0) {
                setCountdown(timeLeft);
                setIsCountdownActive(true);
            } else {
                localStorage.removeItem('countdownEndTime');
            }
        }
    }, []);

    useEffect(() => {
        let timer;
        if (isCountdownActive && countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setIsCountdownActive(false);
                        localStorage.removeItem('countdownEndTime');
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [isCountdownActive, countdown]);

    const startCountdown = () => {
        const endTime = Date.now() + countdown * 1000;
        localStorage.setItem('countdownEndTime', endTime.toString());
        setCountdown(60);
        setIsCountdownActive(true);
    };

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);


    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);
            if (index < 5 && inputRefs.current[index + 1]) {
                inputRefs.current[index + 1].focus(); // Automatically move to the next input
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            if (code[index] !== '') {
                const newCode = [...code];
                newCode[index] = '';  // Clear the current input
                setCode(newCode);
            } else if (index > 0) {
                inputRefs.current[index - 1].focus();  // Move to the previous input
            }
        }
    };


    const handleFocus = (e) => {
        e.target.select();
    };

    const handleVerifyClick = () => {
        setLoading(true);
        setCode(new Array(6).fill(''));
        if (code.some(digit => digit === '')) {
            setOtpalert('Please enter all numbers');
            return;
        }

        const codeConverted = parseInt(code.join(''), 10);
        axios.post(`${API_BASE_URL}api/v1/verify_code`, {
            phone: `+${phoneNumber}`,
            code: codeConverted
        })
            .then(response => {
                setLoading(false);
                const result = response.data.VIDEO_STREAMING_APP[0];
                setOtpalert(result.msg);
                console.log(response);
                console.log(typeof response.success, response.success);
                if (result.success == 1) {
                    localStorage.setItem('child_mode', 0);
                    localStorage.removeItem("sub_account_name");
                    localStorage.removeItem("sub_account_id");
                    localStorage.removeItem("sub_account_img");
                    localStorage.removeItem("sub_account_age");
                    window.location.href = '/'
                }else{
                  setResponse(response.data.VIDEO_STREAMING_APP[0]['msg']);
                }
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    };

    const handleSendCodeAgain = () => {
        setCountdown(60);
        setLoading(true);
        // startCountdown();
        axios.post(`${API_BASE_URL}api/v1/signup`, {
            phone: `+${phoneNumber}`
        })
            .then(response => {
                setLoading(false);
                if (response.data.VIDEO_STREAMING_APP[0].success == 0) {
                    setOtpalert(response.data.VIDEO_STREAMING_APP[0].msg)
                } else {
                    setOtpalert(response.data.VIDEO_STREAMING_APP[0].msg)
                }
            })
            .catch(error => {
                setLoading(false);
                console.error("There was an error submitting the phone number!", error);
            });
    };

    return (
       
        <section className="verify_otp-section">
        <div className="container-fluid">
            <div className="custom-container">
                <div className="row justify-content-center">
                    <div className="logo_web_process_login">
                            <div className="logo_verification">
                                <img src={just_icon} alt="" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
                            </div>
                        <div className="verify_content_heading">
                            <p>Enter the code sent for ({localStorage.getItem('phoneNumberVerification')})</p>
                        </div>
                        <div className="back__button">
                            <button onClick={() => navigate(-1)}>Back <i class="fa-solid fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center">
                    {/* <div className="col-md-6"> */}
                    <div className="main_verification" data-aos="fade-up" data-aos-duration="2000">
                        <p className={`alert-otp ${alertResponse ? 'alert-otp-active' : ''}`}>
                            {alertResponse || ''}
                        </p>
                        <div id="form">
                            {code.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    size="1"
                                    value={digit}
                                    onChange={(e) => handleInputChange(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    onFocus={handleFocus}
                                    ref={(el) => (inputRefs.current[index] = el)}
                                />
                            ))}
                            {/* <button className="text-white" onClick={handleVerifyClick} disabled={isCountdownActive}> Verify </button> */}
                            <button className="text-white" onClick={handleVerifyClick}> Verify </button>
                        </div>

                        <div className='actions_btn_verify'>
                            {isCountdownActive ? (
                                <p className='alert_verify'>Please wait {countdown}s before requesting a new code.</p>
                            ) : (
                                <div>
                                    <a href="#" className='mt-4 d-inline-block' onClick={handleSendCodeAgain}>Didn't receive the code? Send code again</a><br />
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                {
                                    loading && <div class="spinner-border spinner_color mt-3" role="status"></div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div>
    </section>

        // <section className="verify_otp-section">
        //     <div className="container-fluid">
        //         <div className="custom-container">
        //             <div className="row justify-content-center align-items-center">
        //                 <div className="col-md-6 d-flex justify-content-center">
        //                     <div className="main_verification" data-aos="fade-up" data-aos-duration="2000">
        //                         <h3>Please enter the 6-digit verification code we sent via SMS:</h3>
        //                         <p className={`alert-otp ${alert_otp ? 'alert-otp-active' : ''}`}>
        //                             {alert_otp || ''} 
        //                         </p>

        //                         <p className={`alert-otp ${alertResponse ? 'alert-otp-active' : ''}`}>
        //                             {alertResponse || ''} 
        //                         </p>
        //                         <div id="form">
        //                             {code.map((digit, index) => (
        //                                 <input
        //                                     key={index}
        //                                     type="text"
        //                                     maxLength="1"
        //                                     size="1"
        //                                     value={digit}
        //                                     onChange={(e) => handleInputChange(e, index)}
        //                                     onKeyDown={(e) => handleKeyDown(e, index)}
        //                                     onFocus={handleFocus}
        //                                     ref={(el) => (inputRefs.current[index] = el)}
        //                                 />
        //                             ))}
        //                             {/* <button className="text-white" onClick={handleVerifyClick} disabled={isCountdownActive}> Verify </button> */}
        //                             <button className="text-white" onClick={handleVerifyClick}> Verify </button>
        //                         </div>

        //                         <div className='actions_btn_verify'>
        //                             {isCountdownActive ? (
        //                                 <p className='alert_verify'>Please wait {countdown}s before requesting a new code.</p>
        //                             ) : (
        //                                 <div>
        //                                     <a href="#" className='mt-4 d-inline-block' onClick={handleSendCodeAgain}>Didn't receive the code? Send code again</a><br />
        //                                 </div>
        //                             )}
        //                         </div>
        //                         <div className="row">
        //                             <div className="col-md-12 text-center">
        //                                 {
        //                                     loading && <div class="spinner-border spinner_color mt-3" role="status"></div>
        //                                 }
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    );
}
export default ExitChildMode;