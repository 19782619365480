import React, { useEffect, useState } from "react";
import styled from "styled-components";
import logoDark from '../Images/home_images/new-flex-dark.jpg';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../Components/css/style.css';
import logo from '../Images/newflix-logo.png';
import qrcode from '../Images/qr-code.webp';
import { refresh } from "aos";
import { API_BASE_URL } from '../config';


const PaymentPage = () => {

    // Modify body styles for this page
  useEffect(() => {
    // Apply styles when the component is mounted
    document.body.style.background = '#fff';
    
    // Cleanup styles when the component is unmounted
    return () => {
      document.body.style.boxShadow = '';
      document.body.style.margin = '';
      document.body.style.maxWidth = '';
      document.body.style.minHeight = '';
      document.body.style.background = '';
    };
  }, []);

    const [qrCode, setQrcode] = useState('');
    const [alertMsg, setAlertMsg] = useState('');
    const navigate = useNavigate();
    const coupon_code_percentage = localStorage.getItem('coupon_percentage')
    const coupon_code_name = localStorage.getItem('coupon_code');

    async function PaymentRequest(planName, pricePlan, planDuration, checkToken) {
        let finalPrice = parseInt(pricePlan);
        // If there's a valid coupon percentage, apply the discount
        if (coupon_code_percentage) {
            const discountPercentage = parseFloat(coupon_code_percentage); // Convert to number
            const discountAmount = (finalPrice * discountPercentage) / 100; // Calculate the discount
            finalPrice = finalPrice - discountAmount; // Apply the discount to the price
        }
        let planDescription = `You have selected ${planName}`;
        axios.post(`${API_BASE_URL}api/v1/fib/payments`, {
            amount: Math.round(finalPrice),
            description: planDescription,
            token: checkToken
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                if (response.data.qrCode) {
                    setQrcode(response.data.qrCode);
                    setInterval(() => {
                        axios.get(`${API_BASE_URL}api/v1/fib/payments/${response.data.paymentId}/status?token=${checkToken}`).then(response => {
                            if (response.data.status == 'PAID') {
                                localStorage.setItem('payment_id', response.data.paymentId);
                                window.location.href = '/success-payment';
                            } else if (response.data.status == 'DECLINED') {
                                navigate('/payment-cancelled');
                            } else if (response.data.status == 'UNPAID') {
                                setAlertMsg(response.data.status);
                            } else {
                                window.location.reload();
                            }
                            console.log(response);
                        }).catch(error => {
                            window.location.reload();
                            console.log(error);
                        });

                    }, 3000);
                    console.log("Payment response:",);
                }
            })
            .catch(error => {
                console.log("Payment request error:", error);
            });

    }
    useEffect(() => {
        // Fetch the token
        axios.post(`${API_BASE_URL}api/v1/fib/token`)
            .then(response => {
                const token = response.data.access_token;
                localStorage.setItem("api_token", token);
                const checkToken = localStorage.getItem("api_token");
                const pricePlan = localStorage.getItem("planePrice");
                const planDuration = localStorage.getItem("planDuration");
                const planName = localStorage.getItem("planName");
                if (checkToken) {
                    PaymentRequest(planName, pricePlan, planDuration, checkToken);
                }
            })
            .catch(error => {
                console.log("Token request error:", error);
            });
    }, []);




    return (
        <>
            <section className="payment__page-section">
                <div className="container">
                    <div className="custom_container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 text-center">
                                <div className="payment__main" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="payment__title">
                                        <h2>Make a payment</h2>
                                        <span>Scan the Qr code</span>
                                    </div>
                                    <div className="payment__qr mt-4 text-center">
                                        <img src={qrCode} alt="" draggable="false" />
                                    </div>
                                    <div className="alert__msg">
                                        <p>{alertMsg}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PaymentPage;
