import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";

import HomePage from './Pages/HomePage';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import MovieDetails from './Pages/MovieDetails';
import WatchList from './Pages/WatchList';
import BioGraphy from './Pages/BioGraphy';
import CollectionListPage from './Pages/CollectionList';
// import Search from './Components/Search';
import { useState, useEffect } from 'react';
import ScrollToTop from './Components/ScrollToTop';
import Login from './Pages/Login';
import SubscriptionPage from './Pages/SubscriptionPage';
import Dashboard from './Pages/Dashboard';
import UserHistory from './Components/UserHistory';
import ResponsiveNav from './Components/ResponsiveNav';
// import Home from './Pages/Home';
import ViewMoreGenre from './Pages/Viewmorebygenre';
import MoviesMenuGenre from './Pages/MoviesMenuGenre';
import SignUp from './Pages/sign-up';
import Movies from './Pages/Movies';
import Lenis from 'lenis'
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AOS from 'aos';
import EditProfile from './Pages/Editprofile';
import 'aos/dist/aos.css';
import ProcessLogin from './Pages/ProcessLogin';
import PaymentPage from './Pages/PaymentPage';
import SuccessPayment from './Pages/Successpayment';
import PaymentCancelled from './Pages/paymentCancelled';
import WatchMovie from './Pages/WatchMovie';
import Childrens from './Pages/Childrens';
import Serials from './Pages/Serials';
import Showsdetail from './Pages/Showsdetail';
import Episodesshows from './Pages/Episodesshows';
import WatchEpisode from './Pages/WatchEpisode';
import Livetv from './Pages/Livetv';
import LiveTvdetail from './Pages/LiveTvdetail';
import MultiProfiles from './Pages/MultiProfiles';
import AddnewProfile from './Pages/AddnewProfile';
import SerialsViewAll from './Pages/SerialsViewAll';
import Kids from './Pages/Kids';
import Search from './Pages/Search';
import ExitChildMode from './Pages/ExitChildMode';
import AllMoviesPage from './Pages/AllMoviesPage';
import AllSerialsPage from './Pages/AllSerialsPage'
import AllLiveTvPage from './Pages/AllLiveTvPage';
import ViewAllLiveTv from './Pages/ViewAllLiveTv';
import ContactUs from './Pages/ContactUs';
import ApplicationDownload from './Pages/ApplicationDownload';
import PaymentError from './Pages/PaymentError';

const App = () => {

  useEffect(() => {
    AOS.init();
  }, [])

  const [showSearch, setShowSearch] = useState(false);
  const [isTallScreen, setIsTallScreen] = useState(window.innerWidth > 800);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsTallScreen(window.innerWidth > 800);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);
  // gsap.registerPlugin(useGSAP, ScrollTrigger);
  // // Smooth Scrolling
  // const lenis = new Lenis();
  // const lenisJs = () => {
  //   lenis.on("scroll", (e) => { });

  //   lenis.on("scroll", ScrollTrigger.update);

  //   gsap.ticker.add((time) => {
  //     lenis.raf(time * 300);
  //   });

  //   gsap.ticker.lagSmoothing(0);
  // };
  // lenisJs();

  const location = useLocation();
  const hideNavbarPaths = ['/login', '/subscription', '/exit-child-mode', '/verification-process'];
  const hideElement_conditions = !hideNavbarPaths.some(path => location.pathname.startsWith(path));

  return (
    <div className="App">
      {/* <Router> */}
      {hideElement_conditions && <Navbar />} {/* Conditionally render Navbar */}
      <ScrollToTop />
      <div className="clearfix"> {/* Added clearfix wrapper */}
        <Routes>
          {/* <Route path='/' element={<Home/>}/> */}
          <Route path='/' element={<HomePage />} />
          <Route path='/movies/:id' element={<Movies />} />
          <Route path='/movies' element={<AllMoviesPage />} />
          <Route path='/childrens/:id' element={<Childrens />} />
          <Route path='/serials/:id' element={<Serials />} />
          <Route path='/serials' element={<AllSerialsPage />} />
          <Route path='/show-detail/:id' element={<Showsdetail />} />
          <Route path='/watch-list' element={<WatchList />} />
          <Route path='/collection-list' element={<CollectionListPage />} />
          <Route path='/login' element={localStorage.getItem('user_id') && localStorage.getItem('session_name') ? <Navigate to="/profile/dashboard" /> : <Login />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/subscription' element={<SubscriptionPage />} />
          {/* <Route path="/verification-process" element={localStorage.getItem("phoneNumberVerification") ? <ProcessLogin /> : <Navigate to="/login" />} /> */}

          <Route path="/verification-process" element={ <ProcessLogin /> } />


          <Route path='/payment-process' element={localStorage.getItem('user_id') && localStorage.getItem('plan_id') && localStorage.getItem('session_name') ? <PaymentPage /> : <Navigate to="/login" />} />
          <Route path='/success-payment' element={localStorage.getItem('user_id') && localStorage.getItem('payment_id') && localStorage.getItem('session_name') ? <SuccessPayment /> : <Navigate to="/subscription" />} />
          {/* <Route path='/success-payment' element={<SuccessPayment />} /> */}
          <Route path='/payment-cancelled' element={<PaymentCancelled />} />
          <Route path='/payment-error' element={<PaymentError />} />
          <Route path='/profile/dashboard' element={localStorage.getItem('user_id') && localStorage.getItem('session_name') ? <Dashboard /> : <Navigate to="/login" />} />
          <Route path='/profile/edit' element={<EditProfile />} />
          <Route path='/user-history' element={<UserHistory />} />
          <Route path="/view-all/:id" element={<ViewMoreGenre />}></Route>
          <Route path="/view-all-shows/:id" element={<SerialsViewAll />}></Route>
          <Route path="/movies-genre/:id" element={<MoviesMenuGenre />}></Route>
          <Route path="/movie-page/:id" element={<MovieDetails />}></Route>
          <Route path='/watch-movie/:id' element={<WatchMovie />}></Route>
          <Route path='/episodes/:id' element={<Episodesshows />}></Route>
          <Route path='/watch-episode/:id' element={<WatchEpisode />}></Route>
          <Route path='/live-tv/:id' element={<Livetv />}></Route>
          <Route path='/live-tv' element={<AllLiveTvPage />}></Route>
          <Route path='/view-all-live-tv' element={<ViewAllLiveTv />}></Route>
          <Route path='/live-tv-detail/:id' element={<LiveTvdetail />}></Route>
          <Route path='/multiple-profiles' element={localStorage.getItem('user_id') && localStorage.getItem('session_name') ? <MultiProfiles /> : <Navigate to="/login" />}></Route>
          <Route path='/multiple-profile/add-new' element={<AddnewProfile />}></Route>
          <Route path='/kids' element={<Kids />}></Route>
          <Route path='/search/:search_record' element={<Search />}></Route>
          <Route path='/exit-child-mode' element={<ExitChildMode />}></Route>
          <Route path='/contact-us' element={<ContactUs />}></Route>
          <Route path='/app' element={<ApplicationDownload />}></Route>
        </Routes>
      </div>
      {/* <Search showSearch={showSearch} setShowSearch={setShowSearch} /> */}
      {hideElement_conditions && <Footer />} 
      {/* </Router> */}
    </div>
  );
}

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;