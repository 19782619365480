import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { API_BASE_URL } from '../config';

const ContactUs = () => {

    useEffect(() => {
        document.body.style.background = '#3a3a3a';
        return () => {
            document.body.style.boxShadow = '';
            document.body.style.margin = '';
            document.body.style.maxWidth = '';
            document.body.style.minHeight = '';
            document.body.style.background = '';
        };
    }, []);
    return (
        <>
            <section className="contact__us-section">
                <div className="container-fluid">
                    <div className="custom-container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center">
                                <div className="contact-us__head">
                                    <h2>Didn't find the answer to your question?</h2>
                                    <h2 style={{ color: '#d0d0d0' }}>Chat with us or send us an email.</h2>
                                </div>
                                <div className="contact-us__head_two">
                                    <h2 className="contact_us_head">Contact Us</h2>
                                    <h2 style={{ color: '#d0d0d0' }}>You can contact Filmo by sending an email to the desired section.</h2>
                                </div>
                            </div>
                        </div>
                        {/* Contact Info */}
                        <div className="contact_info">
                            <div className="etc_wrapper">
                                <Link to="#" className="contact-telegram etc_telegram_box etc_box-style">
                                    <div className="contact_item_background">
                                        <i class="fa-brands fa-telegram"></i>
                                    </div>
                                    <div className="contact_content_numbers">
                                        newFlix@
                                    </div>
                                    <div className="contact-text">
                                        Also, through Telegram, from 8 a.m. to 2 a.m., we will answer your questions.
                                    </div>
                                </Link>
                                <Link to="#" className="contact_phone etc_box-style">
                                    <div className="contact_item_background">
                                        <i class="fa-solid fa-phone"></i>
                                    </div>
                                    <div className="contact_content_numbers">
                                        913322097456
                                    </div>
                                    <div className="contact-text">
                                        Also, through Telegram, from 8 a.m. to 2 a.m., we will answer your questions.
                                    </div>
                                </Link>
                                <div className="contact_right-side etc_box-style">
                                    <div className="contact_item_background">
                                        <i class="fa-regular fa-envelope"></i>
                                    </div>
                                    <p className="contact-text">
                                        You can get in touch with Filimo by sending an email to your desired department.
                                    </p>
                                    <div className="email__support">
                                        <div className="email_support_child">
                                            <p>info [at] Newflix [dot] com</p>
                                            <span>Suggestions and criticisms</span>
                                        </div>
                                        <div className="email_support_child">
                                            <p>info [at] Newflix [dot] com</p>
                                            <span>Site support</span>
                                        </div>
                                        <div className="email_support_child">
                                            <p>info [at] Newflix [dot] com</p>
                                            <span>Tracking payments</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                        <div className="col-sm-6 col-md-6 col-lg-5">
                                            <div className="contact_card-main">
                                                <div className="contact__info__card">
                                                    <div className="social__card-icon">
                                                        <i class="fa-brands fa-telegram"></i>
                                                    </div>
                                                    <div className="social_contact">
                                                        <Link to="#">
                                                            03322097456
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="contact__info__card">
                                                    <div className="social__card-icon">
                                                        <i class="fa-brands fa-twitter"></i>
                                                    </div>
                                                    <div className="social_contact">
                                                        <Link to="#">
                                                            @NewFlix
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="contact__info__card">
                                                    <div className="social__card-icon">
                                                        <i class="fa-brands fa-instagram"></i>
                                                    </div>
                                                    <div className="social_contact">
                                                        <Link to="#">
                                                            @NewFlix42
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="contact__us__detail_info">
                                                    <p>
                                                        We also answer your questions via Telegram, Intagram, Twitter from 8 am to 2 am.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-7">
                                            <div className="contact_card-main custom__card-top">
                                                <div className="email__info">
                                                    <div className="social__card-icon">
                                                      <i class="fa-regular fa-envelope"></i>
                                                    </div>
                                                    <div className="email_timing">
                                                        <p>You can contact Filmo by sending an email to the desired section.</p>
                                                    </div>
                                                </div>
                                                <div className="email__support">
                                                    <div className="email_support_child">
                                                        <p>info [at] Newflix [dot] com</p>
                                                        <span>Suggestions and criticisms</span>
                                                    </div>
                                                    <div className="email_support_child">
                                                        <p>info [at] Newflix [dot] com</p>
                                                        <span>Site support</span>
                                                    </div>
                                                    <div className="email_support_child">
                                                        <p>info [at] Newflix [dot] com</p>
                                                        <span>Tracking payments</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ContactUs;