import successimg from '../Images/success.png';
import cancelPayment from '../Images/cancelled-payment.png';
const paymentCancelled = () => {
    return (
        <section className='cancel_payment-section'>
            <div className="container-fluid">
                <div className="custom-container">
                    <div className="row justify-content-center">
                        <div className="col-md-6">
                            <div className="cancel_image text-center">
                                <img src={cancelPayment} alt="" />
                            </div>
                            <div className="cancel_msg">
                                <p>Your payment has been Cancelled.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default paymentCancelled;