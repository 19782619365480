import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import '../Components/css/style.css';
import andriodImage from '../Images/andriod.webp';
import { Link } from "react-router-dom";

const leftReviews = [
    {
        username: "Muhamad Ahsan",
        comment: "برنامه خیلی عالی است ✅️فیلم های متنوع ✅️کیفیت های خوب",
        rating: 5,
    },
    {
        username: "Muhamad Zain",
        comment: "برنامه خیلی عالی است ✅️فیلم های متنوع ✅️کیفیت های خوب",
        rating: 5,
    },
];

const rightReviews = [
    {
        username: "Muhamad Wasif",
        comment: "برنامه خیلی عالی است ✅️فیلم های متنوع ✅️کیفیت های خوب",
        rating: 5,
    },
    {
        username: "Muhamad Bilal",
        comment: "برنامه خیلی عالی است ✅️فیلم های متنوع ✅️کیفیت های خوب",
        rating: 5,
    },
];

const renderStars = (rating) => (
    <ul className="list-unstyled d-flex">
        {[...Array(rating)].map((_, index) => (
            <li key={index}><i className="fa-solid fa-star"></i></li>
        ))}
    </ul>
);

const ApplicationDownload = () => {

    // useEffect(() => {
    //     document.body.style.background = '#f5f5f5';
    //     return () => {
    //       document.body.style.boxShadow = '';
    //       document.body.style.margin = '';
    //       document.body.style.maxWidth = '';
    //       document.body.style.minHeight = '';
    //       document.body.style.background = '';
    //     };
    //   }, []);
    return (
        <section className="application__download-section">
            <div className="container-fluid">
                <div className="custom-container">
                    <div className="row justify-content-around">
                        <div className="col-md-6 col-lg-6">
                            <div className="newflixApp_image">
                                <ul className="user-comments rightside">
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        pagination={false}
                                        autoplay={{ delay: 5000 }}
                                        modules={[Autoplay]}
                                        className="userComments"
                                    >
                                        {rightReviews.map((review, index) => (
                                            <SwiperSlide key={index}>
                                                <li>
                                                    <h1 className="username_comment m-0 p-0">{review.username}</h1>
                                                    <div className="rate_star">
                                                        {renderStars(review.rating)}
                                                    </div>
                                                    <div className="comment">
                                                        <p>{review.comment}</p>
                                                    </div>
                                                </li>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </ul>
                                <ul className="user-comments leftside">
                                    <Swiper
                                        slidesPerView={1}
                                        spaceBetween={30}
                                        pagination={false}
                                        autoplay={{ delay: 5000 }}
                                        modules={[Autoplay]}
                                        className="userComments"
                                    >
                                        {leftReviews.map((review, index) => (
                                            <SwiperSlide key={index}>
                                                <li>
                                                    <h1 className="username_comment m-0 p-0">{review.username}</h1>
                                                    <div className="rate_star">
                                                        {renderStars(review.rating)}
                                                    </div>
                                                    <div className="comment">
                                                        <p>{review.comment}</p>
                                                    </div>
                                                </li>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12 order-first order-md-first order-lg-last col-lg-6">
                            <div className="app__content">
                                <div className="andriod_img">
                                    <img src={andriodImage} alt="Android App" />
                                </div>
                                <div className="andriod_heading">
                                    <h2>Android mobile and tablet application</h2>
                                    <p>You can download Filmo Android application through the button below.</p>
                                    <Link to="#">Download the latest version</Link>
                                </div>
                                <hr className="border_line" />
                            </div>
                            <div className="app_infos">
                                <ul className="d-flex list-unstyled">
                                    <li>
                                        <h2>2 <i className="fa-solid fa-star"></i></h2>
                                        <p>From 165+ thousand user comments</p>
                                    </li>
                                    <li>
                                        <h2>+95 thousand</h2>
                                        <p>Movies and series</p>
                                    </li>
                                    <li>
                                        <h2>+14 million</h2>
                                        <p>Active user</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ApplicationDownload;
