import styled from "styled-components";

const UserDetailsComponent = styled.div`
//   margin-top: 20px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2F2F2F;
  .table-wrapper {
    max-height: 70vh;
    max-width: 80%;
    background: #000;
    border-radius: 6px;
    overflow: auto;  // Allow vertical scrolling
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }

  table {
    width: 100%;
    background: #000;
    color: #E9E9E9;
    border-radius: 6px;
    border-collapse: collapse;
    min-width: 600px; // Ensure table has a minimum width

    thead {
      background: linear-gradient(135deg, #333, #444);
      color: #E9E9E9;
    }

    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #444;
    }

    th {
      font-weight: bold;
      text-transform: uppercase;
    }

    tbody tr:nth-child(even) {
      background: #222;
    }

    tbody tr:nth-child(odd) {
      background: #1a1a1a;
    }

    tr:hover {
      background: #555;
    }
  }
`;

const UserHistory = () => {
  return (
    <UserDetailsComponent>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Plan</th>
              <th>Amount</th>
              <th>Payment Gateway</th>
              <th>Payment ID</th>
              <th>Payment Date</th>
            </tr>
          </thead>
          <tbody>
            {/* Example data rows */}
            <tr>
              <td>Basic Plan</td>
              <td>$10.00</td>
              <td>PayPal</td>
              <td>1234567890</td>
              <td>2024-07-30</td>
            </tr>
            {/* More rows... */}
            <tr>
              <td>Premium Plan</td>
              <td>$30.00</td>
              <td>Stripe</td>
              <td>0987654321</td>
              <td>2024-07-29</td>
            </tr>
            <tr>
              <td>Premium Plan</td>
              <td>$30.00</td>
              <td>Stripe</td>
              <td>0987654321</td>
              <td>2024-07-29</td>
            </tr>
            <tr>
              <td>Premium Plan</td>
              <td>$30.00</td>
              <td>Stripe</td>
              <td>0987654321</td>
              <td>2024-07-29</td>
            </tr>
            <tr>
              <td>Premium Plan</td>
              <td>$30.00</td>
              <td>Stripe</td>
              <td>0987654321</td>
              <td>2024-07-29</td>
            </tr><tr>
              <td>Premium Plan</td>
              <td>$30.00</td>
              <td>Stripe</td>
              <td>0987654321</td>
              <td>2024-07-29</td>
            </tr>
            <tr>
              <td>Premium Plan</td>
              <td>$30.00</td>
              <td>Stripe</td>
              <td>0987654321</td>
              <td>2024-07-29</td>
            </tr>
            <tr>
              <td>Premium Plan</td>
              <td>$30.00</td>
              <td>Stripe</td>
              <td>0987654321</td>
              <td>2024-07-29</td>
            </tr>
            <tr>
              <td>Premium Plan</td>
              <td>$30.00</td>
              <td>Stripe</td>
              <td>0987654321</td>
              <td>2024-07-29</td>
            </tr>
            {/* More rows... */}
          </tbody>
        </table>
      </div>
    </UserDetailsComponent>
  );
};

export default UserHistory;
