import React, { useRef } from 'react';
import ReactPlayer from 'react-player';

const MovieTrailerPlayer2 = ({  sourceUrl,poster }) =>{

    const playerRef = useRef(null);

    // const handlePlay = () => {
    //     if (playerRef.current) {
    //         playerRef.current.getInternalPlayer().play();
    //     }
    // };

    // const handlePause = () => {
    //     if (playerRef.current) {
    //         playerRef.current.getInternalPlayer().pause();
    //     }
    // };

    return (
        <div className="player-wrapper" style={{ position: 'relative', width: '100%', height: '100%' }}>
            <ReactPlayer
                ref={playerRef}
                url={sourceUrl}
                playing={true}
                controls={true}
                width="100%"
                height="400px"
                config={{
                    file: {
                        attributes: {
                            autoPlay: true,
                            poster: poster,
                            playsInline: true, 
                        },
                    },
                }}
            />
            <div className="overlay">
                {/* <img src={poster} alt="Logo" className="logo" style={{ position: 'absolute', top: 10, left: 10 }} /> */}
                {/* <div className="custom-controls" style={{ position: 'absolute', bottom: 10, left: 10 }}>
                    <button onClick={handlePlay}>Play</button>
                    <button onClick={handlePause}>Pause</button>
                </div> */}
            </div>
        </div>
    );
};

export default MovieTrailerPlayer2;